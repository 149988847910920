import { Box, Heading, Container, Text, Button, Stack, Flex, Link } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { PopupButton } from 'react-calendly';

export default function HeroSection() {
    const MotionHeading = motion(Heading);
    const MotionText = motion(Text);
    const MotionButton = motion(Button);
    const MotionFlex = motion(Flex);
    const MotionBox = motion(Box);

    // const rpcRef = useRef<null | HTMLSpanElement>(null);
    // const apiRef = useRef<null | HTMLSpanElement>(null);
    // const callbackRef = useRef<null | HTMLSpanElement>(null);

    // useEffect(() => {
    //     const handleScroll = () => {
    //         const totalDistanceToScroll = window.innerHeight;
    //         const currentPosOfScroll = window.scrollY;
    //         const percentageScroll = Math.min(currentPosOfScroll / totalDistanceToScroll);

    //         if (rpcRef.current) {
    //             if (percentageScroll > 0.05 && percentageScroll <= 0.2) {
    //                 rpcRef.current.style.color = '#E33535';
    //                 rpcRef.current.style.textShadow = '0px 0px 8px #E33535';
    //             } else {
    //                 rpcRef.current.style.color = '#fff';
    //                 rpcRef.current.style.textShadow = '0px 0px 0px #FF0000';
    //             }
    //         }

    //         if (apiRef.current) {
    //             if (percentageScroll > 0.2 && percentageScroll <= 0.31) {
    //                 apiRef.current.style.color = '#E33535';
    //                 apiRef.current.style.textShadow = '0px 0px 8px #E33535';
    //             } else {
    //                 apiRef.current.style.color = '#fff';
    //                 apiRef.current.style.textShadow = '0px 0px 0px #FF0000';
    //             }
    //         }

    //         if (callbackRef.current) {
    //             if (percentageScroll > 0.31 && percentageScroll < 0.5) {
    //                 callbackRef.current.style.color = '#E33535';
    //                 callbackRef.current.style.textShadow = 'text-shadow: 0px 0px 0px #FF0000;';
    //             } else {
    //                 callbackRef.current.style.color = '#fff';
    //                 callbackRef.current.style.textShadow = '0px 0px 0px #FF0000';
    //             }
    //         }
    //     };
    //     //requestAnimationFrame(handleScroll)
    //     window.addEventListener('scroll', handleScroll);

    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);

    return (
        <>
            <Stack
                as={'div'}
                position={'relative'}
                height={'100vh'}
                minH={{ base: '100vh', md: '800px' }}
                bgImage={'/assets/smokey-background.png'}
                backgroundRepeat={'no-repeat'}
                backgroundSize={'cover'}
            >
                <Stack position={'relative'} w="full">
                    <Container maxW={'3xl'} position={'relative'} margin={'0 auto'}>
                        <Stack
                            as={Box}
                            textAlign={'center'}
                            spacing={{ base: 8, md: 14 }}
                            pt={{ base: 20, md: 24, xl: '180px' }}
                            // pb={{ base: 40, md: 72 }}
                            position={'relative'}
                            zIndex={'1'}
                        >
                            <MotionFlex
                                direction={'row'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ delay: 0.3, duration: 0.2 }}
                            >
                                {/* <Box pe={{base:"4px", md: "8px"}}>
                                    <Image src="/assets/shyft-only-logo.png" alt='Shyft Logo' width={{base: "42px", md: "70px"}} marginTop={"4px"} />
                                </Box> */}
                                <MotionHeading
                                    as={'h1'}
                                    fontWeight={700}
                                    fontSize={{ base: '5xl', md: '8xl' }}
                                    lineHeight={'110%'}
                                    mb={0}
                                    color={'#fff'}
                                    letterSpacing={{ base: 1, md: 2 }}
                                >
                                    <span className='red_text_1'>
                                        Streaming.
                                    </span>{' '}
                                    <span className='red_text_2'>
                                        Indexers.
                                    </span>{' '}
                                    <span className='red_text_3'>
                                        RPCs.
                                    </span>
                                </MotionHeading>
                            </MotionFlex>

                            {/* <MotionHeading
                                as={'h2'}
                                fontWeight={700}
                                fontSize={{ base: 'xl', md: '4xl' }}
                                lineHeight={'110%'}
                                color={'#fff'}
                                fontFamily={'body'}
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ delay: 0.5, duration: 0.2 }}
                            >
                                A Complete Solana Development Experience
                            </MotionHeading> */}
                            <MotionText
                                as="div"
                                color={'brand.grey'}
                                fontSize={{ base: 'xs', md: 'lg' }}
                                initial={{ opacity: 0, }}
                                animate={{ opacity: 1, }}
                                transition={{ delay: 0.5, duration: 0.2 }}
                            >
                                From lightning-fast{' '}
                                <Link href={'#solana-rpc-details'} _hover={{textDecoration: "none"}}>
                                    <Text
                                        as={'span'}
                                        color={'brand.yellow'}
                                        _hover={{
                                            color: 'brand.red',
                                        }}
                                    >
                                        RPCs
                                    </Text>
                                </Link>{' '}
                                to{' '}
                                <Link href={'https://docs.shyft.to/solana-indexers/graphql-apis'} target='_blank' _hover={{textDecoration: "none"}}>
                                    <Text
                                        as={'span'}
                                        color={'brand.yellow'}
                                        _hover={{
                                            color: 'brand.red',
                                        }}
                                    >
                                        SuperIndexers
                                    </Text>
                                </Link>{' '}
                                and{' '}
                                <Link href={"#solana-grpc-streaming-service"} _hover={{textDecoration: "none"}}>
                                    <Text
                                        as={'span'}
                                        color={'brand.yellow'}
                                        _hover={{
                                            color: 'brand.red',
                                        }}
                                    >
                                        gRPC
                                    </Text>
                                </Link> {' '}streams, we enable the best{' '}
                                <Text as="span">
                                    DevEx
                                </Text>
                                {' '}for you.<br /> Get started with our detailed documentation and{' '}
                                <Text as="span">
                                    24/7
                                </Text>{' '}
                                support.
                            </MotionText>
                            <Stack
                                direction={{ base: 'column', md: 'row' }}
                                spacing={5}
                                align={'center'}
                                alignSelf={'center'}
                                position={'relative'}
                            >
                                <Link as={"a"} href="/get-api-key" _hover={{textDecoration: "none"}}>
                                    <MotionBox
                                        width={{ base: '160px', md: '210px' }}
                                        bg={'brand.red'}
                                        color={'brand.white'}
                                        rounded={'full'}
                                        height={{ base: '40px', md: '50px' }}
                                        py={{ base: '8px', md: '12px' }}
                                        _hover={{
                                            bg: 'brand.white',
                                            color: 'brand.red',
                                        }}
                                        style={{
                                            transition: 'all 0.2s ease',
                                        }}
                                        fontFamily={'heading'}
                                        fontWeight={500}
                                        fontSize={{ base: 'sm', md: 'md' }}
                                        textAlign={'center'}
                                        initial={{ opacity: 0, scale: 0.9 }}
                                        animate={{ opacity: 1, scale: 1 }}
                                        transition={{ delay: 0.7, duration: 0.1 }}
                                    >
                                        Start for Free
                                    </MotionBox>
                                </Link>
                                <MotionButton
                                    colorScheme={'red'}
                                    variant="outline"
                                    rounded={'full'}
                                    color={'brand.white'}
                                    borderColor={'brand.red'}
                                    px={6}
                                    py={{ base: '12px', md: 3 }}
                                    _hover={{
                                        bg: 'brand.red',
                                        color: "brand.white"
                                    }}
                                    height={{ base: '40px', md: '50px' }}
                                    width={{ base: '160px', md: '210px' }}
                                    fontFamily={'heading'}
                                    fontWeight={500}
                                    fontSize={{ base: 'sm', md: 'md' }}
                                    lineHeight={{ base: '0', md: '0' }}
                                    initial={{ opacity: 0, scale: 0.9 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    transition={{ delay: 0.8, duration: 0.1 }}
                                    as={'a'}
                                    href={'https://docs.shyft.to/'}
                                    target="_blank"
                                >
                                    Dev docs
                                </MotionButton>
                            </Stack>
                            <MotionText
                                color={'brand.white'}
                                fontSize={{ base: 'xs', md: 'lg' }}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ delay: 0.9, duration: 0.2 }}
                            >
                                Returning User?{' '}
                                <Link href={'/dashboard/overview'} _hover={{textDecoration: "none"}}>
                                    <Text
                                        as={'span'}
                                        color={'brand.yellow'}
                                        _hover={{
                                            color: 'brand.red',
                                        }}
                                    >
                                        click here
                                    </Text>
                                </Link>{' '}
                                to continue
                            </MotionText>
                        </Stack>
                    </Container>
                </Stack>
            </Stack>
            <PopupButton
                url="https://calendly.com/shyft-to"
                rootElement={document.getElementById("root")}
                text=""
                className={"calendly_button_float"}
            />
        </>
    );
}
