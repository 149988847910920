import { Box, Heading, Container, Text, Stack,Link } from '@chakra-ui/react';
// import { Link } from 'react-router-dom';
import RollingLine from '../../common/RollingLine';

export default function Ready2Shyft() {
    return (
        <Stack bg={'#111'}>
            <RollingLine />
            <Stack bg={'brand.red'} marginBottom={2}>
                <Container maxW={'3xl'}>
                    <Stack
                        as={Box}
                        textAlign={'center'}
                        spacing={{ base: 8, md: 8 }}
                        pt={{ base: 20, md: 12 }}
                        pb={{ base: 20, md: 16 }}
                    >
                        <Text color={'white'} fontSize={{ base: 'sm', md: 'md' }}>
                            Accelerate Your Development
                        </Text>
                        <Heading
                            fontWeight={600}
                            fontSize={{ base: '4xl', md: '6xl' }}
                            lineHeight={'110%'}
                            color={'#fff'}
                        >
                            Ready to SHYFT?
                        </Heading>
                        <Text color={'white'} fontSize={{ base: 'xs', md: 'md' }}>
                            Go from idea to reality in a weekend.
                        </Text>
                        <Text color={'white'} fontSize={{ base: 'xs', md: 'md' }}>
                            From our blazing-fast RPCs, extensive APIs and a real-time infrastructure layer, along with
                            the legendary support, documentation, and code samples we will take your project from zero
                            to one smoothly.
                        </Text>
                        <Text color={'white'} fontSize={{ base: 'xs', md: 'md' }}>
                            You would wish you had found us sooner.
                        </Text>
                        <Stack
                            direction={'column'}
                            spacing={3}
                            align={'center'}
                            alignSelf={'center'}
                            position={'relative'}
                        >
                            <Link
                                href={'/get-api-key'}
                                _hover={{
                                    textDecoration: "none"
                                }}
                            >
                                <Box
                                    colorScheme={'black'}
                                    bg={'black'}
                                    rounded={'full'}
                                    px={8}
                                    py={'8px'}
                                    fontWeight={500}
                                    color={'brand.white'}
                                    _hover={{
                                        bg: 'white',
                                        color: 'black',
                                    }}
                                    fontSize={{ base: 'sm', md: 'md' }}
                                >
                                    Start for Free
                                </Box>
                            </Link>
                        </Stack>
                    </Stack>
                </Container>
            </Stack>
            <RollingLine />
        </Stack>
    );
}
