import { useMemo } from "react";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import {
  UnsafeBurnerWalletAdapter,
  GlowWalletAdapter,
  LedgerWalletAdapter,
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  SolletExtensionWalletAdapter,
  SolletWalletAdapter,
  TorusWalletAdapter,
  BackpackWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { clusterApiUrl } from "@solana/web3.js";

import '@fontsource/kanit';
import '@fontsource/kanit/700.css';
import '@fontsource/kanit/600.css';
import '@fontsource/kanit/500.css';
import '@fontsource/kanit/400.css';
import "@fontsource/jost";
import "@fontsource/jost/700.css";
import "@fontsource/jost/600.css";
import "@fontsource/jost/500.css";
import "@fontsource/jost/400.css";
import "@fontsource/jost/300.css";
import "@fontsource/jost/200.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ChakraProvider,extendTheme  } from "@chakra-ui/react";
import Four04 from "./components/404/Four04";
// import GetAPIKey from "./components/ApiKey/GetAPIKey";
import Login from "./components/Auth/Login";
import UserDetails from "./components/Auth/UserDetails";
import Validator from "./components/Auth/Validator";
import Footer from "./components/common/Footer/Footer";
// import FooterDash from "./components/common/Footer/FooterDash";
import Header from "./components/common/Header/Header";
import Sidebar from "./components/common/Sidebar/Sidebar";
// import Dashboard from "./components/Dashboard/Dashboard";
// import Dashboard2 from "./components/Dashboard/Dashboard2";
// import PaymentPage from "./components/Dashboard/PaymentPage";
import ExperienceShyft from "./components/ExperienceShyft/Experience";
import About from "./components/LandingPage/About";
import LandingPage from "./components/LandingPage/LandingPage";
import PricingDetails from "./components/LandingPage/PricingDetails";
// import MarketplacePage from "./components/Marketplace/Marketplace";
// import NftsPage from "./components/Nfts/NftsPage";
import Teams from "./components/Team/Teams";
import Cancel from "./components/Terms/Cancellation";
import Privacy from "./components/Terms/Privacy";
import Support from "./components/Terms/Support";
import Terms from "./components/Terms/Terms";

import "@solana/wallet-adapter-react-ui/styles.css";

const fonts = {
    initialColorMode: 'dark',
    useSystemColorMode: false,
    heading: `'kanit', sans-serif`,
    body: `'jost', sans-serif`,
    // customTextPoppins: `'Poppins', sans-serif`
  };
const colors = {
    brand: {
        red: "#E33535",
        black: "#111",
        faintGrey: "rgba(255, 255, 255, 0.15)",
        grey: "rgba(255, 255, 255, 0.75)",
        midGrey: "rgba(255, 255, 255, 0.60)",
        lightGrey:"#373737",
        yellow: "#FBB901",
        white: "#F4F4F4",
        lighterWhite: "#fffc"
    },
    switchYellow: {
      50: "#ECC94B",
      100: "#FBB901",
      200: "#FBB901",
      300: "#FBB901",
      400: "#FBB901",
      500: "#FBB901",
      600: "#FBB901",
      700: "#FBB901",
      800: "#FBB901",
      900: "#FBB901",
    },
};
const theme = extendTheme({
    fonts,colors
});

const ParentComponent = () => {
  const path = window.location.pathname;

  const network = WalletAdapterNetwork.Devnet;

  // You can also provide a custom RPC endpoint.
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  const wallets = useMemo(
    () => [
      /**
       * Wallets that implement either of these standards will be available automatically.
       *
       *   - Solana Mobile Stack Mobile Wallet Adapter Protocol
       *     (https://github.com/solana-mobile/mobile-wallet-adapter)
       *   - Solana Wallet Standard
       *     (https://github.com/solana-labs/wallet-standard)
       *
       * If you wish to support a wallet that supports neither of those standards,
       * instantiate its legacy wallet adapter here. Common legacy adapters can be found
       * in the npm package `@solana/wallet-adapter-wallets`.
       */
      new PhantomWalletAdapter(),
      new GlowWalletAdapter(),
      new SlopeWalletAdapter(),
      new SolflareWalletAdapter({ network }),
      new TorusWalletAdapter(),
      new LedgerWalletAdapter(),
      new SolletExtensionWalletAdapter(),
      new SolletWalletAdapter(),
      new UnsafeBurnerWalletAdapter(),
      new BackpackWalletAdapter()
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [network]
  );
  return (
    <ChakraProvider theme={theme}>
      <Router>
        {path === "/get-api-key" ||
        path.substring(0, 10) === "/dashboard" ||
        path.substring(0, 6) === "/dummy" ||
        path.substring(0, 5) === "/auth" ? (
          ""
        ) : (
          <Header />
        )}
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route exact path="/team" element={<Teams />} />
          <Route
            exact
            path="/community-projects"
            element={<ExperienceShyft />}
          />
          <Route exact path="/about" element={<About />} />
          {/* <Route exact path="/dashboard" element={<Dashboard />}/> */}
          {/* <Route exact path="/get-api-key" element={<GetAPIKey />}/> */}
          <Route exact path="/get-api-key" element={<Login />} />
          <Route exact path="/auth/:token" element={<Validator />} />
          <Route exact path="/terms" element={<Terms />} />
          {/* <Route exact path="/marketplace" element={<MarketplacePage />} /> */}
          {/* <Route exact path="/nfts" element={<NftsPage />} /> */}
          <Route exact path="/support" element={<Support />} />
          <Route exact path="/privacy-policy" element={<Privacy />} />
          <Route exact path="/cancellation" element={<Cancel />} />
          <Route exact path="/pricing" element={<PricingDetails />} />
          <Route exact path="/user-details" element={<UserDetails />} />

          <Route
            exact
            path="/dashboard/:module"
            element={
              <ConnectionProvider endpoint={endpoint}>
                <WalletProvider wallets={wallets} autoConnect>
                  <WalletModalProvider>
                    <Sidebar />
                  </WalletModalProvider>
                </WalletProvider>
              </ConnectionProvider>
            }
          />
          {/* <Route exact path="/paynow" element={<PaymentPage />} />  */}

          <Route path="*" element={<Four04 />} />
        </Routes>
        {/* <Footer /> */}
        {path === "/get-api-key" ||
        path.substring(0, 10) === "/dashboard" ||
        path.substring(0, 6) === "/dummy" ||
        path.substring(0, 5) === "/auth" ? (
          <></>
          // <FooterDash />
        ) : (
          <Footer />
        )}
      </Router>
    </ChakraProvider>
  );
};

export default ParentComponent;
