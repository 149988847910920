import FourCss from "./four04.module.css";
import SpaceError from '../../resources/images/error-page.gif';
const Four04 = () => {
    return ( 
        <div className={FourCss.main_container}>
            <div className="container-lg">
                <div className="text-center pt-5">
                    <img src={SpaceError} alt="Not Found" className="img-fluid mx-auto" />
                </div>
                <div className="text-center">
                    <h1 className={FourCss.text}>Oops! Page Not Found!</h1>
                    <a href="https://shyft.to/" className={FourCss.btn_solid_grad}>Genesis Block</a>
                </div>
            </div>
        </div>
     );
}
 
export default Four04;