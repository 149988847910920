import { useEffect } from "react";
import ReactGA from "react-ga4";

import HeroSection from "./LandingPageComponents/HeroSection";
import UtilityRollingSection from "./LandingPageComponents/UtilityRollingSection";
import FeatureSection from "./LandingPageComponents/FeaturesSection";
import TestimonialSection from "./LandingPageComponents/TestimonialsSection";

import PricingStatic from "./LandingPageComponents/PricingStatic";
import GrpcHome from "./LandingPageComponents/GrpcHome";
import ClientRollingSection from "./LandingPageComponents/ClientRollingSection";
import SampleProjects from "./LandingPageComponents/SampleProjects";
import BackedBy from "./LandingPageComponents/BackedBySection";
import Ready2Shyft from "./LandingPageComponents/Ready2Shyft";


// import LpCSS from './landingPage.modules.css';
const LandingPage = () => {
  // const [currTab, setCurrTab] = useState(0);
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/" });
    //ReactGA.pageview(window.location.pathname);
  }, []);
  
  
  return (
    <div>
        <HeroSection />
        <UtilityRollingSection />
        <FeatureSection />
        <GrpcHome />
        <ClientRollingSection />
        <PricingStatic />
        <TestimonialSection />
        <SampleProjects />
        <BackedBy />
        <Ready2Shyft />
    </div>
  );
};

export default LandingPage;
