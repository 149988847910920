import {
    Box,
    Flex,
    Text,    
    useColorModeValue,
    useBreakpointValue,
    Container,
    Image,
    Link
  } from '@chakra-ui/react';


const NavBarUser = () => {
    return ( 
        <>
            <Box bg={"black"} w={"full"} position={"absolute"} zIndex={"2"} top={0} left={0}>
                <Container maxW={"7xl"} py={"4"}>
                    <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }} w={"full"}>
                        <Text
                            as="a"
                            href='/'
                            textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
                            fontFamily={'heading'}
                            color={useColorModeValue('gray.800', 'white')}>
                            <Image src='/shyft_logo.svg' alt='shyft logo' maxW={"100px"}/>
                        </Text>
                    </Flex>
                </Container>
            </Box>
        </>
     );
}
 
export default NavBarUser;