import { Stack, Container } from '@chakra-ui/react';

import CombinedHeading from '../../common/CombinedHeading';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export default function TestimonialSection() {
    return (
        <>
            <Stack as="div" bg={'#111'}>
                <Container maxW={'7xl'} pt={{ base: 12, md: 16 }} pb={{ base: 4, md: 18 }}>
                    <CombinedHeading topHeading="What is the World saying?" mainHeading="User Feedback" isCenter={true}/>
                    <Stack py={20}>
                        <OwlCarousel
                            className="owl-theme"
                            margin={40}
                            nav={false}
                            dotClass="grad_dot"
                            responsive={{
                                0: {
                                    items: 1,
                                },
                                768: {
                                    items: 2,
                                },
                                1100: {
                                    items: 3,
                                },
                            }}
                            dots
                            loop
                            autoplay
                        >
                            <div style={{padding:"10px 0px"}}>
                                <div className="each_testimonial">
                                    <a href='https://twitter.com/vibes8760/status/1579518653443502080' target='_blank' rel="noreferrer">
                                        <div className="test_content">
                                            <div className={`identity_details`} style={{ display: 'flex' }}>
                                                <div className="photo">
                                                    <img src="/assets/CommentImages/Vibes.png" alt="Vibes" />
                                                </div>
                                                <div>
                                                    <div className="d-flex">
                                                        <div className="name">Vibes</div>
                                                        <div className="verified">
                                                            {/* <img src={verifiedIcon} alt="Twitter User" /> */}
                                                        </div>
                                                    </div>
                                                    <div className="handle">@vibes8760</div>
                                                </div>
                                            </div>
                                            <p className="comment">
                                                @Shyft_to has some pretty cool articles on getting you started building on
                                                @solana also good reads if you wanna get a better grasp of sol fundamentals!
                                            </p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div style={{padding:"10px 0px"}}>
                                <div className="each_testimonial">
                                    <a href='https://twitter.com/SerjKorj/status/1605974440059080713' target='_blank' rel="noreferrer">
                                        <div className="test_content">
                                            <div className={`identity_details`} style={{ display: 'flex' }}>
                                                <div className="photo">
                                                    <img src="/assets/CommentImages/SerjKorj.png" alt="Vibes" />
                                                </div>
                                                <div>
                                                    <div className="d-flex">
                                                        <div className="name">Serj Korj</div>
                                                        <div className="verified">
                                                            {/* <img src={verifiedIcon} alt="Twitter User" /> */}
                                                        </div>
                                                    </div>
                                                    <div className="handle">@serjKorg</div>
                                                </div>
                                            </div>
                                            <p className="comment">
                                                I would add @Shyft_to that provides great tools and APIs to simplify @solana Web3 development especially for guys with Web2 past I vouch for them.
                                            </p>
                                        </div>
                                    </a> 
                                </div>
                            </div>
                            <div style={{padding:"10px 0px"}}>
                                <div className="each_testimonial">
                                    <a href='https://twitter.com/leongaban/status/1579480178950279168' target='_blank' rel="noreferrer">
                                        <div className="test_content">
                                            <div className={`identity_details`} style={{ display: 'flex' }}>
                                                <div className="photo">
                                                    <img src="/assets/CommentImages/LeonNew.png" alt="Vibes" />
                                                </div>
                                                <div>
                                                    <div className="d-flex">
                                                        <div className="name">LE◎N</div>
                                                        <div className="verified">
                                                            {/* <img src={verifiedIcon} alt="Twitter User" /> */}
                                                        </div>
                                                    </div>
                                                    <div className="handle">@leongaban</div>
                                                </div>
                                            </div>
                                            <p className="comment">
                                            Ok, if you&apos;re building a #web3 app on #Solana defs check these guys out @Shyft_to, just had a call that saved me a ton of dev costs, their APIs will help us pull in wallet data easily and fast.
                                            </p>
                                        </div>
                                    </a> 
                                </div>
                            </div>
                            <div style={{padding:"10px 0px"}}>
                                <div className="each_testimonial">
                                    <a href='https://twitter.com/mintflickApp/status/1588180828106149889' target='_blank' rel="noreferrer">
                                        <div className="test_content">
                                            <div className={`identity_details`} style={{ display: 'flex' }}>
                                                <div className="photo">
                                                    <img src="/assets/CommentImages/mintflick.png" alt="Vibes" />
                                                </div>
                                                <div>
                                                    <div className="d-flex">
                                                        <div className="name">MintFlick</div>
                                                        <div className="verified">
                                                            {/* <img src={verifiedIcon} alt="Twitter User" /> */}
                                                        </div>
                                                    </div>
                                                    <div className="handle">@mintflickApp</div>
                                                </div>
                                            </div>
                                            <p className="comment">
                                                Gm Creators, Glad to announce our partnership with @Shyft_to.<br />
                                                What&apos;s exciting:<br />
                                                1. NFTs can be minted on @solana.<br />
                                                2. Gasless minting.<br />
                                                3. Creator&apos;s own SPL20 Tokens.<br />
                                                4. Royalties on secondary sales. LFG.

                                            </p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div style={{padding:"10px 0px"}}>
                                <div className="each_testimonial">
                                    <a href='https://twitter.com/TheRoundies/status/1601229261636894721' target='_blank' rel="noreferrer">
                                        <div className="test_content">
                                            <div className={`identity_details`} style={{ display: 'flex' }}>
                                                <div className="photo">
                                                    <img src="/assets/CommentImages/TheRoundies.png" alt="Vibes" />
                                                </div>
                                                <div>
                                                    <div className="d-flex">
                                                        <div className="name">The R◎undies</div>
                                                        <div className="verified">
                                                            {/* <img src={verifiedIcon} alt="Twitter User" /> */}
                                                        </div>
                                                    </div>
                                                    <div className="handle">@TheRoundies</div>
                                                </div>
                                            </div>
                                            <p className="comment">
                                                We&apos;re stoked to announce the Roundie Town Market v2! It&apos;s an on-chain Metaplex Auction House market, with our SPL token $ROUNDIE as the currency. We built this in a week with the incredible APIs written by the @shyft_to team, who saved us weeks of work, w/ top-notch support!
                                            </p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div style={{padding:"10px 0px"}}>
                                <div className="each_testimonial">
                                    <a href='https://twitter.com/solventprotocol/status/1583441146231271424?s=20&t=0VvLOw4keSZHE8l4aZR6bg' target='_blank' rel="noreferrer">   
                                        <div className="test_content">
                                            <div className={`identity_details`} style={{ display: 'flex' }}>
                                                <div className="photo">
                                                    <img src="/assets/CommentImages/Solvent.png" alt="Vibes" />
                                                </div>
                                                <div>
                                                    <div className="d-flex">
                                                        <div className="name">Solvent</div>
                                                        <div className="verified">
                                                            {/* <img src={verifiedIcon} alt="Twitter User" /> */}
                                                        </div>
                                                    </div>
                                                    <div className="handle">@solventprotocol</div>
                                                </div>
                                            </div>
                                            <p className="comment">
                                                We’re working with Shyft for faster metadata loads, NFT image caching, and all that is needed for a perfect NFT trading experience ⚡️<br />
                                                They’re a strong technical team pushing the standards of dev-tooling on Solana higher. 🙌🏻
                                            </p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </OwlCarousel>
                    </Stack>
                </Container>
            </Stack>
        </>
    );
}
