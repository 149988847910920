import { Heading, Stack, Text } from '@chakra-ui/react';

export default function CombinedHeading(props) {
    return (
        <Stack>
            {props.topHeading && (
                <Text as="div" color={'brand.yellow'} textAlign={props.isCenter ? 'center' : 'left'}>
                    {props.topHeading}
                </Text>
            )}
            <Heading
                as={'h2'}
                lineHeight={{ base: 1.2, md: 0.8 }}
                fontWeight={600}
                fontSize={{ base: '3xl', sm: '4xl', lg: '5xl' }}
                color={'brand.white'}
                textAlign={props.isCenter ? 'center' : 'left'}
            >
                {props.mainHeading}
            </Heading>
            {props.subHeading && (
                <Text
                    as="div"
                    color={'brand.grey'}
                    fontSize={{ base: 'sm', md: 'md' }}
                    lineHeight={{ base: 1.4, md: 2.6 }}
                    textAlign={props.isCenter ? 'center' : 'left'}
                >
                    {props.subHeading}
                </Text>
            )}
        </Stack>
    );
}
