import MainStyles from "../../resources/css/css.module.css";

const About = () => {
  return (
    <div className={`${MainStyles.dark_backdrop_gradient}`}>
      <div className={`${MainStyles.about_styles}`}>
        <div className="container-lg text-center pb-1">
          <h1 className={MainStyles.main_headings}>
            Our mission is to provide a scalable, secure, and intuitive
            ecosystem for developing Dapps to speed up mass adoption of web3
          </h1>
        </div>
        <div className="container-lg pt-4">
          <p className={MainStyles.p_para_1}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. A purus
            amet, dolor posuere scelerisque risus gravida etiam. Adipiscing quam
            non vulputate lacus quam enim. Sapien fermentum egestas vitae tempor
            habitant nec, ultrices hendrerit vulputate. Fusce imperdiet in
            gravida venenatis. Pellentesque neque ut placerat venenatis et
            sagittis. Non risus, ullamcorper fusce at magna odio suspendisse
            malesuada sollicitudin. Tincidunt venenatis eros mauris ut nullam
            ornare habitasse ornare quis. Sit ac lectus lorem maecenas gravida.
            Fames ullamcorper mauris in volutpat. Facilisis volutpat aliquam id
            adipiscing elementum maecenas adipiscing ut elementum. 
          </p>
          <p className={MainStyles.p_para_1}>
            Et eget cras metus, quis. Vel neque faucibus malesuada placerat.
            Tellus viverra luctus faucibus viverra. Lectus egestas pellentesque
            auctor nec id sed volutpat dui. Sit morbi hac quis at. Vulputate
            non, maecenas nibh laoreet purus, in. In consectetur fermentum
            nullam consectetur arcu tellus. Vitae ornare at molestie eget
            egestas viverra fermentum. Tincidunt diam senectus cursus a, eget.
            Et neque fermentum, placerat vitae dignissim semper vulputate.
            Imperdiet id etiam placerat facilisi laoreet amet pulvinar. Egestas
            facilisis gravida mus non est. Odio habitasse ac tincidunt
            tincidunt.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
