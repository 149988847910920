import { useEffect, useState } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import * as bs58 from "bs58";
import axios from 'axios';
import { ReactSession } from "react-client-session";

import styles from "./DashboardStyles/nftClaimOffer.module.css";

import Cross from "../../resources/images/circle-close.svg";
import LoadingData from "../../resources/images/landing-small-icons/loading-data-2.gif";
import smallSuccess from "../../resources/images/dashboard/success.gif";
import smallFailed from "../../resources/images/dashboard/failed.gif";

import left_image from "../../resources/images/dashboard/left_section.svg";
import right_image from "../../resources/images/dashboard/right_section.svg";
import full_image from "../../resources/images/dashboard/art_work.svg";
import { formatCreditUsage } from "../../utils/CreditFormat";

const NftClaimOffer = ({ planDetails, setShowPlanCard, setCurrentOffer, setClaimedOffer }) => {
    const userWallet = useWallet();
    const [claimProgress, setClaimProgess] = useState("unclaimed");
    const [addrBeingClaimed, setBeingClaimed] = useState(0);
    const claimOffer = async (offerid, collectionId) => {

        const message = `Claiming Shyft credits with collection id '${collectionId}' at ${new Date()}. Reward id: ${offerid}`;
        const encodedMessage = new TextEncoder().encode(message);

        const signedMessageFromWallet = await userWallet.signMessage(encodedMessage);
        if (signedMessageFromWallet) {
            setBeingClaimed(offerid);
            const encodedSignature = bs58.encode(signedMessageFromWallet);
            // console.log(signedMessageFromWallet);
            // console.log(bs58.encode(signedMessageFromWallet));
            const token = ReactSession.get("xTkn") ?? "";
            setClaimProgess("loading");
            const endPoint = process.env.REACT_APP_AUTH_EP;
            let rqUrl = `${endPoint}dashboard/reward/claim`;
            axios({
                // Endpoint to get NFTs
                url: rqUrl,
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${token}`,
                    "ngrok-skip-browser-warning": "69420"
                },
                data: {
                    address: userWallet.publicKey?.toBase58(),
                    signature: encodedSignature,
                    message: message,
                    reward_id: offerid
                }
            })
                // Handle the response from backend here
                .then((res) => {
                    setClaimProgess("claimed");
                    //console.log(res.data);

                    if (res.data.success === true) {
                        setCurrentOffer(res.data.result.reward ?? null);
                        setClaimedOffer(true);
                        setTimeout(() => {
                            setClaimedOffer(false);
                            setClaimProgess("unclaimed");
                            setShowPlanCard(false);
                        }, 5000)
                    }

                })
                // Catch errors if any
                .catch((err) => {
                    console.warn(err);
                    setClaimProgess("error");
                    setTimeout(() => {
                        setClaimProgess("unclaimed");
                    }, 2000)
                    // ReactSession.set("xTkn", "");
                    // navigate('/get-api-key');
                });
        }
    }

    return (

        <div className="modal" style={{ display: "block", backdropFilter: "blur(8px)" }}>
            <div className="modal-dialog modal-xl modal-dialog-scrollable modal-dialog-centered" style={{zIndex: "101"}}>
                <div className="modal-content" style={{ backgroundColor: "#202020", borderRadius: "16px" }}>
                    <div className="modal-body">
                        <div className={styles.dark_modal_style}>
                            <div className={styles.cross_container}>
                                <button onClick={() => setShowPlanCard(false)}>
                                    <img src={Cross} alt="close" />
                                </button>
                            </div>
                            <div className={`pt-3 ${styles.claim_offer_modal_body}`}>
                                <div className={styles.offer_popup_heading}>
                                    Eligible Offer(s)
                                </div>
                                <div className={styles.offer_popup_subheading}>
                                    Redeem credits on the NFTs you hold and BUIDL!
                                </div>
                                <div className={styles.offer_list}>
                                    {planDetails.length > 0 && planDetails.map((plan) => <div className={styles.each_offer}>
                                        <div className={styles.each_offer_inner}>
                                            <div className={styles.offer_text_details}>
                                                <div className={styles.each_offer_name}>
                                                    {plan.name ?? "--"}
                                                    <span>
                                                        <a href={`https://translator.shyft.to/address/${plan.collection_meta.address}`} target="_blank" rel="norefferer">{plan.collection_meta?.name ?? "--"}</a>
                                                    </span>
                                                </div>
                                                <div className={styles.each_offer_details}>
                                                    <div className={styles.offer_text}>Free {formatCreditUsage(plan.credits ?? 0)} credits/mo for the next {plan.duration_in_month ?? "--"} months</div>
                                                    <div className={styles.offer_text}>{plan.rate_limit ?? "--"} requests/sec</div>
                                                </div>
                                                {claimProgress === "unclaimed" && <div className={styles.offer_claim_button}>
                                                    <button onClick={() => claimOffer(plan.id, plan.collection_meta.address)}>Claim Offer</button>
                                                </div>}
                                                {claimProgress === "loading" && <div className={styles.offer_claim_button_disabled}>
                                                    <button>Claim Offer</button>{(addrBeingClaimed === plan.id) && <img src={LoadingData} />}
                                                </div>}
                                                {claimProgress === "error" && <div className={styles.offer_claim_button_disabled}>
                                                    <button>Claim Offer</button>{(addrBeingClaimed === plan.id) && <img src={smallFailed} style={{ width: "40px" }} />}
                                                </div>}
                                            </div>
                                            <div className={styles.offer_image}>
                                                {/* <div className={styles.offer_image_outer}>
                                                <div className={styles.offer_image_inner_1}>
                                                    <img src={left_image} style={{width:"70px",height:"180px"}} alt="left_image"/>
                                                </div>
                                                <div className={styles.offer_image_inner_2}>
                                                    <div className={styles.nft_image_outer}>
                                                        <div className={styles.nft_image_inner}>
                                                            <img src={plan.collection_meta?.image_uri ?? "--"} alt="center_image"/>
                                                        </div>
                                                        <div className={styles.nft_text_inner}>
                                                            {plan.collection_meta?.name?.substr(0,15) ?? "--"}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={styles.offer_image_inner_3}>
                                                <img src={right_image} style={{width:"70px",height:"180px"}} alt="right_image"/>
                                                </div>
                                            </div> */}

                                                {/* {(claimProgress === "claimed") ? 
                                                <div className={styles.offer_has_been_claimed}>
                                                        <img src={offer_claimed} alt="offer claimed"/>
                                                    </div>:<div className={styles.offer_image_outer_2}>
                                                <div className={styles.background_image}>
                                                    <img src={full_image} alt="background"/>
                                                </div>
                                                <div className={styles.actual_image_area}>
                                                    <div className={styles.actual_nft_image}>
                                                        <img src={plan.collection_meta?.image_uri ?? "--"} alt="center_image"/>
                                                    </div>
                                                    
                                                </div>
                                            </div>} */}
                                                <div className={styles.offer_image_outer_2}>
                                                    {/* <div className={styles.background_image}>
                                                        <img src={full_image} alt="background" />
                                                    </div> */}
                                                    <div className={styles.actual_image_area}>
                                                        <div className={styles.actual_nft_image}>
                                                            <img src={plan.collection_meta?.image_uri ?? "--"} alt="center_image" />
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default NftClaimOffer;