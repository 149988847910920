import { Box, Stack, Container,Flex,Heading,Image } from "@chakra-ui/react";
export default function BackedBy() {
    return (
        <Stack as="div" bg={"#000"}>
            <Container maxW={'7xl'} pt={{ base: 10, lg: 16 }} pb={{ base: 10, lg: 14 }}>
                <Flex justifyContent={"space-between"} flexWrap={"wrap"}>
                    <Box as="div">
                        <Heading as="h2" fontSize={{base:"3xl",lg:"5xl"}} pt={2} color={"brand.white"}>Backed by</Heading>
                    </Box>
                    <Box as="div">
                        <Image src="/assets/100x.svg" alt="we are backed by 100x" maxW={{base:"140px",lg:"full"}}/>
                    </Box>
                </Flex>
            </Container>
        </Stack>
    )
}