import { useEffect } from "react";


import { Box,Text,Flex, useColorModeValue, ListItem, UnorderedList, Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon, Stack, Heading,Link, Container } from "@chakra-ui/react";

import { ExternalLinkIcon } from '@chakra-ui/icons';

import LinkDarkCards from "./LinkDarkCards";

const BlogList = [
    {
        title: "Read All NFTs from wallet",
        link: "https://docs.shyft.to/tutorials/build-your-first-nft-dapp?source=shyft-website",
        isLast: false
    },
    {
        title: "How to get token balances",
        link: "https://docs.shyft.to/tutorials/how-to-get-token-balances?source=shyft-website",
        isLast: false
    },
    {
        title: "Read All NFTs from a wallet",
        link: "https://docs.shyft.to/tutorials/read-all-nfts-from-a-wallet?source=shyft-website",
        isLast: false
    },
    {
        title: "Mint and reward users with edition NFTs",
        link: "https://docs.shyft.to/tutorials/mint-and-reward-users-with-edition-nfts?source=shyft-website",
        isLast: false
    },
    {
        title: "How to create a marketplace on Solana",
        link: "https://medium.com/shyft-to/build-your-first-nft-marketplace-2e52f24ae7ec?source=shyft-website",
        isLast: false
    },
    {
        title: "how to sign transactions on Solana",
        link: "https://docs.shyft.to/dev-guides/solana/transactions/how-to-sign-transactions-using-multiple-signers-on-solana?source=shyft-website",
        isLast: false
    },
    {
        title: "Working with Compressed NFTs on Solana",
        link: "https://blogs.shyft.to/working-with-compressed-nfts-on-solana-afb4bcf20bc2?source=shyft-website",
        isLast: false
    },
    {
        title: "Build a Discord Bot for Solana",
        link: "https://blogs.shyft.to/building-a-discord-bot-with-shyft-api-stay-updated-on-compressed-nfts-7e9c48a1b15e",
        isLast: false
    },
    {
        title: "Mint a compressed NFT collection with Shyft API",
        link: "https://blogs.shyft.to/build-a-collection-compressed-nft-with-shyft-api-d901f77d3581",
        isLast: true
    },
];

const devGuides = [
    {
        id: 1,
        blogName: 'How to build an eCommerce loyalty reward system with utility tokens on Solana',
        blogLink:
            'https://blogs.shyft.to/how-to-build-an-ecommerce-loyalty-reward-system-with-utility-tokens-on-solana-part-1-28a398b71ec2?source=shyft-website',
        isNew: false,
    },
    {
        id: 2,
        blogName: 'Build your first Solana NFT marketplace',
        blogLink: 'https://blogs.shyft.to/build-your-first-nft-marketplace-2e52f24ae7ec?source=shyft-website',
        isNew: false,
    },
    {
        id: 3,
        blogName: 'Building NFT based membership systems on Solana',
        blogLink:
            'https://blogs.shyft.to/part-1-membership-service-using-nfts-on-solana-c8ea0459ff52?source=shyft-website',
        isNew: false,
    },
    {
        id: 4,
        blogName: 'How to create a Web3 game with utility tokens on Solana',
        blogLink:
            'https://blogs.shyft.to/how-to-create-a-web3-game-with-utility-tokens-on-solana-part-1-2c8308203662?source=shyft-website',
        isNew: false,
    },
    {
        id: 5,
        blogName: 'How to create a Token airdrop campaign on Solana',
        blogLink:
            'https://blogs.shyft.to/how-to-create-a-token-airdrop-campaign-on-solana-part-1-577f8c5aba14?source=shyft-website',
        isNew: false,
    },
    {
        id: 6,
        blogName: 'How to create an NFT launchpad on Solana',
        blogLink:
            'https://blogs.shyft.to/how-to-create-an-nft-launchpad-on-solana-part-1-cccc017c4bf?source=shyft-website',
        isNew: true,
    },
    {
        id: 7,
        blogName: 'How to create a live activity feed on Solana',
        blogLink:
            'https://blogs.shyft.to/how-to-create-an-nft-launchpad-on-solana-part-1-cccc017c4bf?source=shyft-website',
        isNew: true,
    },
];
const NftBlogs = [
    {
        id: 1,
        blogName: "How to mint an NFT using it's metadata URI on Solana",
        blogLink:
            'https://blogs.shyft.to/how-to-mint-an-nft-using-its-metadata-uri-on-solana-ed938833ceeb?source=shyft-website',
        isNew: false,
    },
    {
        id: 2,
        blogName: 'How to get all NFTs from a Solana Wallet',
        blogLink: 'https://blogs.shyft.to/read-all-nfts-from-a-wallet-444025c9d7ed?source=shyft-website',
        isNew: false,
    },
    {
        id: 3,
        blogName: 'Using NFTs as access tokens in Solana',
        blogLink: 'https://blogs.shyft.to/build-nft-gated-dapp-397ee39dc033?source=shyft-website',
        isNew: false,
    },
    {
        id: 4,
        blogName: 'Mint and airdrop Solana NFTs',
        blogLink:
            'https://blogs.shyft.to/mint-and-reward-users-with-edition-nfts-e7d655a20d44?source=shyft-website',
        isNew: false,
    },
    {
        id: 5,
        blogName: 'How to get NFT owners on Solana',
        blogLink: 'https://blogs.shyft.to/how-to-get-nft-owners-on-solana-c90992b15007?source=shyft-website',
        isNew: false,
    },
    {
        id: 6,
        blogName: 'Exploring the ultimate NFT Reading Solution on Solana',
        blogLink:
            'https://blogs.shyft.to/exploring-the-ultimate-nft-reading-solution-on-solana-72be3531d3b0?source=shyft-website',
        isNew: false,
    },
    {
        id: 7,
        blogName: 'How to update metadata URI for an NFT on Solana',
        blogLink:
            'https://blogs.shyft.to/how-to-update-metadata-uri-for-an-nft-on-solana-4e058edfead9?source=shyft-website',
        isNew: false,
    },
    {
        id: 8,
        blogName: 'Build your first Solana NFT dApp',
        blogLink: 'https://blogs.shyft.to/build-your-first-nft-dapp-cd499445ffa6?source=shyft-website',
        isNew: false,
    },
];
const cNFTBlogs = [
    {
        id: 1,
        blogName: 'How to mint a Compressed NFT on Solana',
        blogLink: 'https://blogs.shyft.to/how-to-mint-a-compressed-nft-on-solana-6e7c0098e51e?source=shyft-website',
        isNew: false,
    },
    {
        id: 2,
        blogName: 'Working with compressed NFTs on Solana',
        blogLink: 'https://blogs.shyft.to/working-with-compressed-nfts-on-solana-afb4bcf20bc2?source=shyft-website',
        isNew: true,
    },
    {
        id: 3,
        blogName: 'Building a compressed collection NFT on Solana',
        blogLink:
            'https://blogs.shyft.to/build-a-collection-compressed-nft-with-shyft-api-d901f77d3581?source=shyft-website',
        isNew: true,
    },
];
const tokenBlogs = [
    {
        id: 1,
        blogName: 'How to get token balances in Solana',
        blogLink: 'https://blogs.shyft.to/how-to-get-token-balances-5b041c859d44?source=shyft-website',
        isNew: false,
    },
    {
        id: 2,
        blogName: 'How to airdrop SPL-20 tokens to hundreds of wallets on Solana',
        blogLink:
            'https://blogs.shyft.to/how-to-airdrop-spl-20-tokens-to-hundreds-of-wallets-on-solana-6aa88253a5e5?source=shyft-website',
        isNew: false,
    },
    {
        id: 3,
        blogName: 'How to create, mint and airdrop a custom SPL token on Solana',
        blogLink:
            'https://blogs.shyft.to/how-to-create-mint-and-airdrop-a-custom-spl-token-on-solana-7f3d3dd5f44?source=shyft-website',
        isNew: false,
    },
];
const TxnBlogs = [
    {
        id: 1,
        blogName: 'How to get decoded Solana Transactions',
        blogLink: 'https://blogs.shyft.to/how-to-get-decoded-solana-transactions-d73d57ef5b66?source=shyft-website',
        isNew: false,
    },
    {
        id: 2,
        blogName: 'How to create a Web3 marketplace activity feed',
        blogLink:
            'https://blogs.shyft.to/how-to-create-a-web3-marketplace-activity-feed-ff7f14d1519c?source=shyft-website',
        isNew: false,
    },
    {
        id: 3,
        blogName: 'Building a Discord Bot with Shyft APIs',
        blogLink:
            'https://blogs.shyft.to/building-a-discord-bot-with-shyft-api-stay-updated-on-compressed-nfts-7e9c48a1b15e?source=shyft-website',
        isNew: false,
    },
    {
        id: 4,
        blogName: 'How to parse raw transactions in Solana',
        blogLink: 'https://blogs.shyft.to/how-to-parse-raw-transaction-in-solana-ed392e95e5dd?source=shyft-website',
        isNew: false,
    },
    {
        id: 5,
        blogName: 'Track cNFT marketplace events in real-time',
        blogLink:
            'https://blogs.shyft.to/track-cnft-marketplace-events-in-real-time-8626e537f77d?source=shyft-website',
        isNew: false,
    },
];
const solanaBrainfood = [
    {
        id: 4,
        blogName: 'Tracking NFTs sold on Tensor over a period of time',
        blogLink:
            'https://blogs.shyft.to/tracking-sold-nfts-on-tensor-3f12670430a3?source=shyft-website',
        isNew: true,
    },
    {
        id: 3,
        blogName: 'Tracking loan events on Famous Fox Citrus',
        blogLink:
            'https://blogs.shyft.to/tracking-loan-events-on-famous-fox-citrus-f294ba29fdf5?source=shyft-website',
        isNew: true,
    },
    {
        id: 2,
        blogName: 'How to track votes for realms proposal on Solana',
        blogLink:
            'https://blogs.shyft.to/how-to-track-votes-for-realms-proposal-on-solana-807210b6e6c6?source=shyft-website',
        isNew: false,
    },
    {
        id: 1,
        blogName: 'How to track ticket purchased for a Famous fox raffle on Solana',
        blogLink:
            'https://blogs.shyft.to/how-to-track-ticket-purchased-for-a-famous-fox-raffle-on-solana-9db91a36acd?source=shyft-website',
        isNew: false,
    },
];

function LongLinkCards({title, link, isLast=false}) {
    return (
        <Flex as={"a"} href={link} target="_blank" justifyContent={"space-between"} pb={3} pt={4} borderBottom={isLast?"none":"2px solid"} borderColor={"brand.lightGrey"}>
            <Text color={"brand.white"} fontSize={{base: "14px", md: "17px"}}>{title}</Text>
            <ExternalLinkIcon boxSize={{base: 4, lg: 5}} color={"brand.white"}/>
        </Flex>
    )
}

const Resources = (props) => {
    useEffect(() => {
        setTimeout(() => {
          props.setLoading(false);
        },800)
      }, []);
    return (
        <Container maxW={"8xl"}>
            <Box
                as="div"
                // px={{ base: '16px', md: '24px', xl: '32px' }}
                pb={{ base: '16px', md: '16px' }}
            >
                <Box>
                    <Text
                        as="span"
                        fontFamily={'heading'}
                        color={useColorModeValue('brand.white', 'brand.white')}
                        fontSize={'2xl'}
                        lineHeight={'3xl'}
                        fontWeight={'600'}
                    >
                        Tutorials
                    </Text>
                </Box>
                
            </Box>
            <Box
                as="div"
                // px={{ base: '16px', md: '24px', xl: '32px' }}
                // py={{ base: '16px', md: '24px' }}
            >
                <Flex
                        w={'full'}
                        flexDirection={{ base: 'column', lg: 'row' }}
                        justifyContent={'space-between'}
                        flexWrap={{ base: 'wrap' }}
                        // pb={{ base: 12, md: '72px' }}
                        display={{base:"none", lg:"flex"}}
                    >
                        <Box w={{ base: 'full', md: '30%' }}>
                            <Stack mt={{ base: '8px', md: '12px' }} pb={'8px'}>
                                <Box
                                    bgColor={'brand.faintGrey'}
                                    borderRadius={'4px'}
                                    py={{ base: '6px' }}
                                    textAlign={'center'}
                                >
                                    <Heading
                                         as="span"
                                        fontSize={{ base: 'md', lg: 'lg' }}
                                        fontWeight={'200'}
                                        fontFamily={'heading'}
                                        color={'brand.white'}
                                    >
                                        Dev Guides
                                    </Heading>
                                </Box>
                                <Box py={3}>
                                    <UnorderedList styleType="none" color={'brand.white'} spacing={'4'} ms={"-3"}>
                                        {devGuides.map((blog) => (
                                            <ListItem key={blog.id}>
                                                <Link
                                                    href={blog.blogLink}
                                                    target="_blank"
                                                    _hover={{
                                                        textDecoration: 'none',
                                                        color: 'brand.red',
                                                    }}
                                                >
                                                    <Text lineHeight={'19px'} fontSize={'16px'}>
                                                        {blog.blogName}&nbsp;{blog.isNew && '🔥'}
                                                    </Text>
                                                </Link>
                                            </ListItem>
                                        ))}
                                    </UnorderedList>
                                </Box>
                            </Stack>
                            <Stack mt={{ base: '8px', md: '12px' }}>
                                <Box
                                    as="span"
                                    bgColor={'brand.faintGrey'}
                                    borderRadius={'4px'}
                                    py={{ base: '6px' }}
                                    textAlign={'center'}
                                >
                                    <Heading
                                        fontSize={{ base: 'md', md: 'lg' }}
                                        fontWeight={'200'}
                                        fontFamily={'heading'}
                                        color={'brand.white'}
                                    >
                                        Tokens
                                    </Heading>
                                </Box>
                                <Box py={3}>
                                    <UnorderedList styleType="none" color={'brand.white'} spacing={'4'} ms={"-3"}>
                                        {tokenBlogs.map((blog) => (
                                            <ListItem key={blog.id}>
                                                <Link
                                                    href={blog.blogLink}
                                                    target="_blank"
                                                    _hover={{
                                                        textDecoration: 'none',
                                                        color: 'brand.red',
                                                    }}
                                                >
                                                    <Text as="span" lineHeight={'19px'} fontSize={'16px'}>
                                                        {blog.blogName}&nbsp;{blog.isNew && '🔥'}
                                                    </Text>
                                                </Link>
                                            </ListItem>
                                        ))}
                                    </UnorderedList>
                                </Box>
                            </Stack>
                        </Box>
                        <Box w={{ base: 'full', md: '30%' }}>
                            <Stack mt={{ base: '8px', md: '12px' }} pb={'8px'}>
                                <Box
                                    bgColor={'brand.faintGrey'}
                                    borderRadius={'4px'}
                                    py={{ base: '6px' }}
                                    textAlign={'center'}
                                >
                                    <Heading
                                        fontSize={{ base: 'md', md: 'lg' }}
                                        fontWeight={'200'}
                                        fontFamily={'heading'}
                                        color={'brand.white'}
                                    >
                                        Compressed NFTs
                                    </Heading>
                                </Box>
                                <Box py={3}>
                                    <UnorderedList styleType="none" color={'brand.white'} spacing={'4'} ms={"-3"}>
                                        {cNFTBlogs.map((blog) => (
                                            <ListItem key={blog.id}>
                                                <Link
                                                    href={blog.blogLink}
                                                    target="_blank"
                                                    _hover={{
                                                        textDecoration: 'none',
                                                        color: 'brand.red',
                                                    }}
                                                >
                                                    <Text as="span" lineHeight={'19px'} fontSize={'16px'}>
                                                        {blog.blogName}&nbsp;{blog.isNew && '🔥'}
                                                    </Text>
                                                </Link>
                                            </ListItem>
                                        ))}
                                    </UnorderedList>
                                </Box>
                            </Stack>
                            <Stack mt={{ base: '8px', md: '12px' }}>
                                <Box
                                    bgColor={'brand.faintGrey'}
                                    borderRadius={'4px'}
                                    py={{ base: '6px' }}
                                    textAlign={'center'}
                                >
                                    <Heading
                                        fontSize={{ base: 'md', md: 'lg' }}
                                        fontWeight={'200'}
                                        fontFamily={'heading'}
                                        color={'brand.white'}
                                    >
                                        NFTs
                                    </Heading>
                                </Box>
                                <Box py={3}>
                                    <UnorderedList styleType="none" color={'brand.white'} spacing={'4'} ms={"-3"}>
                                        {NftBlogs.map((blog) => (
                                            <ListItem key={blog.id}>
                                                <Link
                                                    href={blog.blogLink}
                                                    target="_blank"
                                                    _hover={{
                                                        textDecoration: 'none',
                                                        color: 'brand.red',
                                                    }}
                                                >
                                                    <Text as="span" lineHeight={'19px'} fontSize={'16px'}>
                                                        {blog.blogName}&nbsp;{blog.isNew && '🔥'}
                                                    </Text>
                                                </Link>
                                            </ListItem>
                                        ))}
                                    </UnorderedList>
                                </Box>
                            </Stack>
                        </Box>
                        <Box w={{ base: 'full', md: '30%' }}>
                            <Stack mt={{ base: '8px', md: '12px' }} pb={'8px'}>
                                <Box
                                    bgColor={'brand.faintGrey'}
                                    borderRadius={'4px'}
                                    py={{ base: '6px' }}
                                    textAlign={'center'}
                                >
                                    <Heading
                                        fontSize={{ base: 'md', md: 'lg' }}
                                        fontWeight={'200'}
                                        fontFamily={'heading'}
                                        color={'brand.white'}
                                    >
                                        Transactions & Callbacks
                                    </Heading>
                                </Box>
                                <Box py={3}>
                                    <UnorderedList styleType="none" color={'brand.white'} spacing={'4'} ms={"-3"}>
                                        {TxnBlogs.map((blog) => (
                                            <ListItem key={blog.id}>
                                                <Link
                                                    href={blog.blogLink}
                                                    target="_blank"
                                                    _hover={{
                                                        textDecoration: 'none',
                                                        color: 'brand.red',
                                                    }}
                                                >
                                                    <Text as="span" lineHeight={'19px'} fontSize={'16px'}>
                                                        {blog.blogName}&nbsp;{blog.isNew && '🔥'}
                                                    </Text>
                                                </Link>
                                            </ListItem>
                                        ))}
                                    </UnorderedList>
                                </Box>
                            </Stack>
                            <Stack mt={{ base: '8px', md: '12px' }} pb={'8px'}>
                                <Box
                                    bgColor={'brand.faintGrey'}
                                    borderRadius={'4px'}
                                    py={{ base: '6px' }}
                                    textAlign={'center'}
                                >
                                    <Heading
                                        fontSize={{ base: 'md', md: 'lg' }}
                                        fontWeight={'200'}
                                        fontFamily={'heading'}
                                        color={'brand.white'}
                                    >
                                        Solana Brainfood
                                    </Heading>
                                </Box>
                                <Box py={3}>
                                    <UnorderedList styleType="none" color={'brand.white'} spacing={'4'} ms={"-3"}>
                                        {solanaBrainfood.map((blog) => (
                                            <ListItem key={blog.id}>
                                                <Link
                                                    href={blog.blogLink}
                                                    target="_blank"
                                                    _hover={{
                                                        textDecoration: 'none',
                                                        color: 'brand.red',
                                                    }}
                                                >
                                                    <Text as="span" lineHeight={'19px'} fontSize={'16px'}>
                                                        {blog.blogName}&nbsp;{blog.isNew && '🔥'}
                                                    </Text>
                                                </Link>
                                            </ListItem>
                                        ))}
                                    </UnorderedList>
                                </Box>
                            </Stack>
                        </Box>
                    </Flex>
                    <Accordion allowToggle display={{base:"initial", lg:"none"}} mb={12}>
                        <AccordionItem border={"none"} pt={2}>
                            <h2>
                            <AccordionButton color={"brand.white"} bg={"brand.lightGrey"} borderRadius={"8px"} _expanded={{ bg: 'brand.red' }}>
                                <Box as="span" flex='1' textAlign='left'>
                                    Dev Guides
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <Box py={1}>
                                    <UnorderedList styleType="none" color={'brand.white'} spacing={'4'} ms={"-3"}>
                                        {devGuides.map((blog) => (
                                            <ListItem key={blog.id}>
                                                <Link
                                                    href={blog.blogLink}
                                                    target="_blank"
                                                    _hover={{
                                                        textDecoration: 'none',
                                                        color: 'brand.red',
                                                    }}
                                                >
                                                    <Text as="span" lineHeight={'19px'} fontSize={'16px'}>
                                                        {blog.blogName}&nbsp;{blog.isNew && '🔥'}
                                                    </Text>
                                                </Link>
                                            </ListItem>
                                        ))}
                                    </UnorderedList>
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem border={"none"} pt={2}>
                            <h2>
                                <AccordionButton color={"brand.white"} bg={"brand.lightGrey"} borderRadius={"8px"} _expanded={{ bg: 'brand.red' }}>
                                    <Box as="span" flex='1' textAlign='left'>
                                        Tokens & Crypto
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <Box py={3}>
                                    <UnorderedList styleType="none" color={'brand.white'} spacing={'4'} ms={"-3"}>
                                        {tokenBlogs.map((blog) => (
                                            <ListItem key={blog.id}>
                                                <Link
                                                    href={blog.blogLink}
                                                    target="_blank"
                                                    _hover={{
                                                        textDecoration: 'none',
                                                        color: 'brand.red',
                                                    }}
                                                >
                                                    <Text as="span" lineHeight={'19px'} fontSize={'16px'}>
                                                        {blog.blogName}&nbsp;{blog.isNew && '🔥'}
                                                    </Text>
                                                </Link>
                                            </ListItem>
                                        ))}
                                    </UnorderedList>
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem border={"none"} pt={2}>
                            <h2>
                                <AccordionButton color={"brand.white"} bg={"brand.lightGrey"} borderRadius={"8px"} _expanded={{ bg: 'brand.red' }}>
                                    <Box as="span" flex='1' textAlign='left'>
                                        Compressed NFTs
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <Box py={3}>
                                    <UnorderedList styleType="none" color={'brand.white'} spacing={'4'} ms={"-3"}>
                                        {cNFTBlogs.map((blog) => (
                                            <ListItem key={blog.id}>
                                                <Link
                                                    href={blog.blogLink}
                                                    target="_blank"
                                                    _hover={{
                                                        textDecoration: 'none',
                                                        color: 'brand.red',
                                                    }}
                                                >
                                                    <Text lineHeight={'19px'} fontSize={'16px'}>
                                                        {blog.blogName}&nbsp;{blog.isNew && '🔥'}
                                                    </Text>
                                                </Link>
                                            </ListItem>
                                        ))}
                                    </UnorderedList>
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem border={"none"} pt={2}>
                            <h2>
                                <AccordionButton color={"brand.white"} bg={"brand.lightGrey"} borderRadius={"8px"} _expanded={{ bg: 'brand.red' }}>
                                    <Box as="span" flex='1' textAlign='left'>
                                        NFTs, powered by SHYFT
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <Box py={3}>
                                    <UnorderedList styleType="none" color={'brand.white'} spacing={'4'} ms={"-3"}>
                                        {NftBlogs.map((blog) => (
                                            <ListItem key={blog.id}>
                                                <Link
                                                    href={blog.blogLink}
                                                    target="_blank"
                                                    _hover={{
                                                        textDecoration: 'none',
                                                        color: 'brand.red',
                                                    }}
                                                >
                                                    <Text lineHeight={'19px'} fontSize={'16px'}>
                                                        {blog.blogName}&nbsp;{blog.isNew && '🔥'}
                                                    </Text>
                                                </Link>
                                            </ListItem>
                                        ))}
                                    </UnorderedList>
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem border={"none"} pt={2}>
                            <h2>
                                <AccordionButton color={"brand.white"} bg={"brand.lightGrey"} borderRadius={"8px"} _expanded={{ bg: 'brand.red' }}>
                                    <Box as="span" flex='1' textAlign='left'>
                                        Transactions & Callbacks
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <Box py={3}>
                                    <UnorderedList styleType="none" color={'brand.white'} spacing={'4'} ms={"-3"}>
                                        {TxnBlogs.map((blog) => (
                                            <ListItem key={blog.id}>
                                                <Link
                                                    href={blog.blogLink}
                                                    target="_blank"
                                                    _hover={{
                                                        textDecoration: 'none',
                                                        color: 'brand.red',
                                                    }}
                                                >
                                                    <Text lineHeight={'19px'} fontSize={'16px'}>
                                                        {blog.blogName}&nbsp;{blog.isNew && '🔥'}
                                                    </Text>
                                                </Link>
                                            </ListItem>
                                        ))}
                                    </UnorderedList>
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem border={"none"} pt={2}>
                            <h2>
                                <AccordionButton color={"brand.white"} bg={"brand.lightGrey"} borderRadius={"8px"} _expanded={{ bg: 'brand.red' }}>
                                    <Box as="span" flex='1' textAlign='left'>
                                        Solana Brainfood
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <Box py={3}>
                                    <UnorderedList styleType="none" color={'brand.white'} spacing={'4'} ms={"-3"}>
                                        {solanaBrainfood.map((blog) => (
                                            <ListItem key={blog.id}>
                                                <Link
                                                    href={blog.blogLink}
                                                    target="_blank"
                                                    _hover={{
                                                        textDecoration: 'none',
                                                        color: 'brand.red',
                                                    }}
                                                >
                                                    <Text lineHeight={'19px'} fontSize={'16px'}>
                                                        {blog.blogName}&nbsp;{blog.isNew && '🔥'}
                                                    </Text>
                                                </Link>
                                            </ListItem>
                                        ))}
                                    </UnorderedList>
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
            </Box>
            <Box mt={12}>
                <Flex justifyContent={'space-between'} alignItems={'center'} px={1}>
                    <Text
                        fontSize={{ base: '2xl', md: '2xl' }}
                        fontFamily={'heading'}
                        fontWeight={'600'}
                        letterSpacing={'0.03em'}
                        color={'brand.lighterWhite'}
                    >
                        APIs & Docs
                    </Text>
                </Flex>
                <Flex
                    direction={{ base: 'column', md: 'row' }}
                    justifyContent={{ base: 'center', md: 'space-between' }}
                    alignItems={'center'}
                >
                    <Box w={'full'} maxW={{ base: '100%', lg: '49%' }}>
                        <LinkDarkCards title='Documentation' link='https://docs.shyft.to/' />
                    </Box>
                    <Box w={'full'} maxW={{ base: '100%', lg: '49%' }}>
                        <LinkDarkCards title='SHYFT SDK' link='https://www.npmjs.com/package/@shyft-to/js' />
                    </Box>
                </Flex>
                <Flex
                    direction={{ base: 'column', md: 'row' }}
                    justifyContent={{ base: 'center', md: 'space-between' }}
                    alignItems={'center'}
                >
                    <Box w={'full'} maxW={{ base: '100%', lg: '49%' }}>
                        <LinkDarkCards title='Tryout APIs in Swagger UI' link='https://api.shyft.to/sol/api/explore/' />
                    </Box>
                    <Box w={'full'} maxW={{ base: '100%', lg: '49%' }}>
                        <LinkDarkCards title='Postman Docs' link='https://documenter.getpostman.com/view/18419720/UzQvt5Kf' />
                    </Box>
                </Flex>
            </Box>
            <Box mt={12}>
                <Flex justifyContent={'space-between'} alignItems={'center'} px={1}>
                    <Text
                        fontSize={{ base: '2xl', md: '2xl' }}
                        fontFamily={'heading'}
                        fontWeight={'600'}
                        letterSpacing={'0.03em'}
                        color={'brand.lighterWhite'}
                    >
                        Community Projects
                    </Text>
                </Flex>
                <Flex
                    direction={{ base: 'column', md: 'row' }}
                    justifyContent={{ base: 'center', md: 'space-between' }}
                    alignItems={'center'}
                >
                    <Box w={'full'} maxW={{ base: '100%', lg: '49%' }}>
                        <LinkDarkCards title='Solana Translator' link='https://translator.shyft.to/' />
                    </Box>
                    <Box w={'full'} maxW={{ base: '100%', lg: '49%' }}>
                        <LinkDarkCards title='Fungible Tokens' link='https://tokens.shyft.to/' />
                    </Box>
                </Flex>
                <Flex
                    direction={{ base: 'column', md: 'row' }}
                    justifyContent={{ base: 'center', md: 'space-between' }}
                    alignItems={'center'}
                >
                    <Box w={'full'} maxW={{ base: '100%', lg: '49%' }}>
                        <LinkDarkCards title='NFTs and Marketplace' link='https://nfts.shyft.to/' />
                    </Box>
                    <Box w={'full'} maxW={{ base: '100%', lg: '49%' }}>
                        <LinkDarkCards title='cNFT Tools' link='https://cnft-tools.vercel.app/' />
                    </Box>
                </Flex>
            </Box>
        </Container>
    );
}

export default Resources;