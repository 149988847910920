import { useState, useEffect } from "react";
import { ReactSession } from "react-client-session";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { getMonthFirstAndLastDate, getOrdinals } from "../../utils/Calendar";

import {
  Box,
  Flex,
  Select,
  Stack,
  Text,
  Container,
  Circle,
  HStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarController,
  BarElement,
  defaults,
} from "chart.js";
//import Chart from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarController,
  BarElement
);
const APIUsage = (props) => {
  const navigate = useNavigate();
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const monthsFull = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const date = new Date();
  const currMonth = date.getUTCMonth() + 1;
  const currYear = date.getFullYear();
  const prevMonth = date.getUTCMonth() + 1 === 1 ? 12 : date.getUTCMonth();
  const prevYear =
    date.getUTCMonth() + 1 === 1 ? date.getFullYear() - 1 : date.getFullYear();
  const [monthParams, setMonthParams] = useState(
    `?month=${currMonth}&year=${currYear}`
  );
  const [errOcc, setErrOcc] = useState(false);
  const [data, setData] = useState(null);

  const [prevCycle, setPrevCycle] = useState(0);

  //api calls for api usage data
  useEffect(() => {
    props.setLoading(true);
    const token = ReactSession.get("xTkn") ?? "";

    if (token !== "") {
      const endPoint = process.env.REACT_APP_AUTH_EP;
      const reqParam = {
        last_cycle_from_current: prevCycle,
      };
      axios({
        url: `${endPoint}dashboard/api_usage`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "ngrok-skip-browser-warning": "69420",
        },
        params: reqParam,
      })
        .then((res) => {
          if (res.data.success === true) {
            setData(res.data);
          } else {
            setErrOcc(true);
          }
        })
        // Catch errors if any
        .catch((err) => {
          console.warn(err);
          setErrOcc(true);
          props.setLoading(false);
        });
    } else {
      navigate("/get-api-key");
    }
  }, [prevCycle]);

  useEffect(() => {
    if (data !== null || errOcc === true) props.setLoading(false);
  }, [data, errOcc]);

  //chart Components
  const chartOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  const currMonthAndYear = getMonthFirstAndLastDate(date);
  const [graphStartDate, setGraphStartDate] = useState(
    currMonthAndYear.startTime
  );
  const currentTime = new Date();
  const time5SecsAgo = new Date(currentTime);
  time5SecsAgo.setSeconds(currentTime.getSeconds() - 5);
  const [graphEndDate, setGraphEndDate] = useState(time5SecsAgo);
  const [graphReqParams, setgraphReqParams] = useState(
    `?start_date=${graphStartDate.toISOString()}&end_date=${graphEndDate.toISOString()}`
  );
  const [isChartLoaded, setChartLoaded] = useState(false);
  const [graphData, setGraphData] = useState([]);

  const [currentLegendShowing, setCurrentLegendShowing] =
    useState("Credits used");
  defaults.font.family = "Poppins";
  defaults.color = "#fff";
  const [chartData, setChartData] = useState({
    labels: graphData.map((user) => `${new Date(user.day).getUTCDate()}`),
    datasets: [
      {
        label: "Credits Used",
        data: graphData.map((user) => user.credit_used),
        backgroundColor: ["#E33535"],
        //  borderColor: "white",
        //  borderWidth: 1
      },
    ],
  });

  useEffect(() => {
    setgraphReqParams(
      `?start_date=${graphStartDate.toISOString()}&end_date=${graphEndDate.toISOString()}`
    );
  }, [graphStartDate, graphEndDate]);

  useEffect(() => {
    if (graphData.length !== 0) {
      setChartData({
        labels: graphData.map((user) => `${new Date(user.day).getUTCDate()}`),
        datasets: [
          {
            label: "Credits Used",
            data: graphData.map((user) => user.credit_used),
            backgroundColor: ["#E33535"],
            //  borderColor: "white",
            //  borderWidth: 1
          },
        ],
      });
      setChartLoaded(true);
    }
  }, [graphData]);

  useEffect(() => {
    setChartLoaded(false);
    setCurrentLegendShowing("Credits used");
    const token = ReactSession.get("xTkn") ?? "";

    if (token !== "") {
      const endPoint = process.env.REACT_APP_AUTH_EP;
      axios({
        url: `${endPoint}dashboard/api_usage_by_range${graphReqParams}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "ngrok-skip-browser-warning": "69420",
        },
      })
        .then((res) => {
          if (res.data.success === true) {
            //setGraphData(res.data.result.interval_consumptions);
            const monthEndDate = new Date(
              getMonthFirstAndLastDate(
                new Date(res.data.result.cycle_start)
              ).endTime
            ).getUTCDate();

            var intervaConsumptionsFromAPI = [];
            for (var counter = 0; counter < monthEndDate; counter++) {
              const dateValueWhenEmpty =
                counter + 1 < 10 ? "0" + (counter + 1) : counter + 1;
              const eachConsumption = res.data.result.interval_consumptions[
                counter
              ] ?? {
                day: `2023-01-${dateValueWhenEmpty}`,
                total_api_calls: 0,
                credit_used: 0,
                total_callbacks_triggered: 0,
              };
              intervaConsumptionsFromAPI.push(eachConsumption);
            }
            setGraphData(intervaConsumptionsFromAPI);
          } else {
            setChartLoaded(false);
          }
        })
        // Catch errors if any
        .catch((err) => {
          console.warn(err);
          // setErrOcc(true);
          setChartLoaded(false);
        });
    }
  }, [graphReqParams]);

  return (
    <div>
      {!props.isLoading && errOcc === true && (
        <Box
          as="div"
          bg="black"
          px={{ base: "16px", md: "24px", xl: "32px" }}
          py={{ base: "16px", md: "24px" }}
          borderRadius={"14px"}
        >
          <Box textAlign={"center"} color={"brand.grey"}>
            <Text fontSize={"xl"}>
              Some Error Occured while Loading Data. Please Try Again Later.
            </Text>
          </Box>
        </Box>
      )}
      {!props.isLoading && data !== null && (
        <div className="container-fluid">
          <Box as="div">
            <Container maxW={"8xl"}>
              <Flex
                direction={{ base: "column", lg: "row" }}
                justifyContent={{ base: "center", lg: "space-between" }}
                alignItems={"center"}
              >
                <Text as={"div"} color={"brand.white"}>
                  Note: You can evaluate your previous API usage by selecting
                  any of the previous months
                </Text>
                <Box
                  width={{ base: "full", lg: "150px" }}
                  mt={{ base: "16px", lg: "0px" }}
                >
                  <Select
                    bg={"black"}
                    fontSize={{ base: "12px", lg: "14px" }}
                    color={"brand.white"}
                    w={{ base: "full" }}
                    value={prevCycle}
                    onChange={(e) => {
                      setPrevCycle(Number(e.target.value));
                    }}
                  >
                    <option
                      value={0}
                      style={{ backgroundColor: "black", color: "white" }}
                    >
                      This Cycle
                    </option>
                    <option
                      value={1}
                      style={{ backgroundColor: "black", color: "white" }}
                    >
                      Previous Cycle
                    </option>
                  </Select>
                </Box>
              </Flex>
              <Box mt={12}>
                <Flex
                  as={"div"}
                  direction={{ base: "column", lg: "row" }}
                  justifyContent={{ base: "center", lg: "space-between" }}
                >
                  <Box width={"full"} maxW={{ base: "full", lg: "49%" }}>
                    <Flex
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      pb={2}
                      px={1}
                    >
                      <Text
                        as={"div"}
                        fontSize={{ base: "16px", lg: "20px" }}
                        fontFamily={"heading"}
                        letterSpacing={"0.02em"}
                        color={"brand.lighterWhite"}
                      >
                        Credits Used
                      </Text>
                    </Flex>
                    <Box
                      as="div"
                      bg="black"
                      px={{ base: "16px", md: "20px", xl: "32px" }}
                      py={{ base: "16px", md: "18px" }}
                      borderRadius={"14px"}
                    >
                      <Flex
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Text
                          as={"span"}
                          fontSize={{ base: "14px", lg: "16px" }}
                          fontWeight={"400"}
                          letterSpacing={"0.03em"}
                          color={"brand.white"}
                        >
                          {data.result.credit_used ?? 0}
                        </Text>
                        <Text
                          as={"span"}
                          fontSize={{ base: "13px", lg: "16px" }}
                          fontWeight={"400"}
                          letterSpacing={"0.03em"}
                          color={"brand.white"}
                        >
                          {new Date(data.result.cycle_start).getUTCDate()}{" "}
                          {months[
                            new Date(data.result.cycle_start).getUTCMonth()
                          ] ?? "Jan"}{" "}
                          - {new Date(data.result.cycle_end).getUTCDate()}{" "}
                          {months[
                            new Date(data.result.cycle_end).getUTCMonth()
                          ] ?? "Jan"}
                        </Text>
                      </Flex>
                    </Box>
                  </Box>
                  <Box
                    width={"full"}
                    maxW={{ base: "full", lg: "25%" }}
                    mt={{ base: "16px", lg: "0px" }}
                  >
                    <Flex
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      pb={2}
                      px={1}
                    >
                      <Text
                        as={"div"}
                        fontSize={{ base: "16px", lg: "20px" }}
                        fontFamily={"heading"}
                        letterSpacing={"0.02em"}
                        color={"brand.lighterWhite"}
                      >
                        Total API Calls
                      </Text>
                    </Flex>
                    <Box
                      as="div"
                      bg="black"
                      px={{ base: "16px", md: "20px", xl: "32px" }}
                      py={{ base: "16px", md: "18px" }}
                      borderRadius={"14px"}
                    >
                      <Flex justifyContent={"start"} alignItems={"center"}>
                        <Text
                          as={"div"}
                          fontSize={{ base: "14px", lg: "16px" }}
                          fontWeight={"400"}
                          letterSpacing={"0.03em"}
                          color={"brand.white"}
                        >
                          {data.result.total_api_calls ?? 0}
                        </Text>
                      </Flex>
                    </Box>
                  </Box>
                  <Box
                    width={"full"}
                    maxW={{ base: "full", lg: "24%" }}
                    mt={{ base: "16px", lg: "0px" }}
                  >
                    <Flex
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      pb={2}
                      px={1}
                    >
                      <Text
                        as={"div"}
                        fontSize={{ base: "16px", lg: "20px" }}
                        fontFamily={"heading"}
                        letterSpacing={"0.02em"}
                        color={"brand.lighterWhite"}
                      >
                        Callbacks Triggered
                      </Text>
                    </Flex>
                    <Box
                      as="div"
                      bg="black"
                      px={{ base: "16px", md: "20px", xl: "32px" }}
                      py={{ base: "16px", md: "18px" }}
                      borderRadius={"14px"}
                    >
                      <Flex
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Text
                          as={"span"}
                          fontSize={{ base: "14px", lg: "16px" }}
                          fontWeight={"400"}
                          letterSpacing={"0.03em"}
                          color={"brand.white"}
                        >
                          {data.result.total_callbacks_triggered ?? 0}
                        </Text>
                      </Flex>
                    </Box>
                  </Box>
                </Flex>
              </Box>
              <Box mt={12}>
                <Flex
                  as={"div"}
                  direction={{ base: "column", lg: "row" }}
                  justifyContent={{ base: "center", lg: "space-between" }}
                >
                  <Box width={"full"} maxW={{ base: "full", lg: "40%" }}>
                    <Text
                      as={"div"}
                      fontSize={{ base: "16px", lg: "20px" }}
                      fontFamily={"heading"}
                      letterSpacing={"0.02em"}
                      color={"brand.lighterWhite"}
                      pt={1}
                    >
                      Daily Breakdown
                    </Text>
                  </Box>
                  <Box width={"full"} maxW={{ base: "full", lg: "59%" }}>
                    <Flex
                      direction={{ base: "column", lg: "row" }}
                      justifyContent={{ base: "center", lg: "flex-end" }}
                    >
                      <Box me={{ base: 0, lg: 2 }} pt={{ base: 4, lg: 0 }}>
                        <Select
                          width={"full"}
                          maxW={{ base: "full", lg: "200px" }}
                          bg={"black"}
                          color={"brand.white"}
                          fontSize={{ base: "12px", lg: "14px" }}
                          value={monthParams}
                          onChange={(e) => {
                            setMonthParams(e.target.value);
                            if (
                              e.target.value ===
                              `?month=${currMonth}&year=${currYear}`
                            ) {
                              setGraphStartDate(currMonthAndYear.startTime);
                              const currentTime = new Date();
                              const time5SecsAgo = new Date(currentTime);
                              time5SecsAgo.setSeconds(
                                currentTime.getSeconds() - 5
                              );
                              setGraphEndDate(time5SecsAgo);
                            } else {
                              setGraphStartDate(
                                getMonthFirstAndLastDate(
                                  new Date(prevYear, prevMonth)
                                ).startTime
                              );
                              setGraphEndDate(
                                getMonthFirstAndLastDate(
                                  new Date(prevYear, prevMonth)
                                ).endTime
                              );
                            }
                          }}
                        >
                          <option
                            value={`?month=${currMonth}&year=${currYear}`}
                            style={{ backgroundColor: "black", color: "white" }}
                          >
                            This Month
                          </option>
                          <option
                            value={`?month=${prevMonth}&year=${prevYear}`}
                            style={{ backgroundColor: "black", color: "white" }}
                          >
                            Prev Month
                          </option>
                        </Select>
                      </Box>
                      <Box pt={{ base: 3, lg: 0 }}>
                        <Select
                          width={"full"}
                          maxW={{ base: "full", lg: "300px" }}
                          bg={"black"}
                          color={"brand.white"}
                          fontSize={{ base: "12px", lg: "14px" }}
                          onChange={(e) => {
                            if (e.target.value === "Credits") {
                              setCurrentLegendShowing("Credits used");
                              setChartData({
                                ...chartData,
                                datasets: [
                                  {
                                    label: "Credits Used",
                                    data: graphData.map(
                                      (user) => user.credit_used
                                    ),
                                    backgroundColor: ["#E33535"],
                                  },
                                ],
                              });
                            } else if (e.target.value === "ApiCalls") {
                              setCurrentLegendShowing("Api calls");
                              setChartData({
                                ...chartData,
                                datasets: [
                                  {
                                    label: "API calls",
                                    data: graphData.map(
                                      (user) => user.total_api_calls
                                    ),
                                    backgroundColor: ["rgba(251, 185, 1, 0.9)"],
                                  },
                                ],
                              });
                            } else {
                              setCurrentLegendShowing("Callbacks Trig");
                              setChartData({
                                ...chartData,
                                datasets: [
                                  {
                                    label: "Callbacks Trig",
                                    data: graphData.map(
                                      (user) => user.total_callbacks_triggered
                                    ),
                                    backgroundColor: [
                                      "rgba(244, 244, 244, 0.9)",
                                    ],
                                  },
                                ],
                              });
                            }
                          }}
                        >
                          <option
                            value="Credits"
                            style={{ backgroundColor: "black", color: "white" }}
                          >
                            Credits Consumed
                          </option>
                          <option
                            value="ApiCalls"
                            style={{ backgroundColor: "black", color: "white" }}
                          >
                            API Calls
                          </option>
                          <option
                            value="Callbacks"
                            style={{ backgroundColor: "black", color: "white" }}
                          >
                            Callbacks Triggered
                          </option>
                        </Select>
                      </Box>
                    </Flex>
                  </Box>
                </Flex>
                <Box
                  bg={"black"}
                  mt={5}
                  px={{ base: "8px", lg: "32px" }}
                  py={{ base: "12px", lg: "40px" }}
                  borderRadius={"2xl"}
                >
                  <Flex
                    justifyContent={"flex-end"}
                    width={"full"}
                    maxW={"1300px"}
                  >
                    <Box textAlign={"end"}>
                      {currentLegendShowing === "Credits used" && (
                        <HStack>
                          <Circle size="12px" bg={"brand.red"} color="white" />
                          <Text
                            as={"span"}
                            color={"brand.white"}
                            fontSize={"xs"}
                          >
                            Credits Used
                          </Text>
                        </HStack>
                      )}
                      {currentLegendShowing === "Api calls" && (
                        <HStack>
                          <Circle
                            size="12px"
                            bg={"brand.yellow"}
                            color="white"
                          />
                          <Text
                            as={"span"}
                            color={"brand.white"}
                            fontSize={"xs"}
                          >
                            API Calls
                          </Text>
                        </HStack>
                      )}
                      {currentLegendShowing === "Callbacks Trig" && (
                        <HStack>
                          <Circle
                            size="12px"
                            bg={"brand.white"}
                            color="white"
                          />
                          <Text
                            as={"span"}
                            color={"brand.white"}
                            fontSize={"xs"}
                          >
                            Callbacks
                          </Text>
                        </HStack>
                      )}
                      <Text
                        as={"div"}
                        color={"brand.white"}
                        fontSize={"xs"}
                        mt={1}
                      >
                        {monthsFull[graphStartDate.getUTCMonth()]},{" "}
                        {graphStartDate.getUTCFullYear()}
                      </Text>
                    </Box>
                  </Flex>
                  <Box width={"full"} maxW={"1200px"} margin={"0 auto"}>
                    <Bar data={chartData} options={chartOptions} />
                  </Box>
                </Box>
              </Box>
              <Box mt={12}>
                <Box width={"full"} maxW={{ base: "full", lg: "49%" }}>
                  <Flex
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    pb={2}
                    px={1}
                  >
                    <Text
                      fontSize={{ base: "16px", lg: "20px" }}
                      fontFamily={"heading"}
                      letterSpacing={"0.02em"}
                      color={"brand.lighterWhite"}
                    >
                      API Usage
                    </Text>
                  </Flex>
                </Box>
                <Stack
                  direction={{ base: "row" }}
                  bg={"black"}
                  px={{ base: "16px", md: "20px", xl: "32px" }}
                  py={{ base: "16px", md: "18px" }}
                  borderRadius={"14px"}
                  color={"brand.white"}
                  fontFamily={"heading"}
                  fontSize={{ base: "14px", lg: "16px" }}
                  mt={2}
                >
                  <Box width={{ base: "60%", lg: "45%" }}>Endpoint</Box>
                  <Box width={{ base: "20%", lg: "15%" }} textAlign={"center"}>
                    Credits
                  </Box>
                  <Box
                    width={{ base: "20%", lg: "15%" }}
                    textAlign={{ base: "end", lg: "center" }}
                  >
                    API Calls
                  </Box>
                  <Box
                    width={{ base: "0%", lg: "25%" }}
                    textAlign={"end"}
                    display={{ base: "none", lg: "block" }}
                  >
                    Credits Used
                  </Box>
                </Stack>
                {data.result.api_call_history.length > 0 ||
                data.result.total_callbacks_triggered > 0 ? (
                  <>
                    {data.result.api_call_history.length > 0
                      ? data.result.api_call_history.map((record, index) => (
                          <CreditValue
                            endpoint={record.endpoint}
                            credit={record.endpoint_credits}
                            calls={record.count}
                            creditsConsumed={record.total_credit}
                            key={index}
                          />
                        ))
                      : ""}
                    {data.result.total_callbacks_triggered > 0 ? (
                      <CreditValue
                        endpoint="Callbacks Triggered"
                        credit={data.result.credits_per_callback ?? 0}
                        calls={data.result.total_callbacks_triggered ?? 0}
                        creditsConsumed={
                          (data.result.total_callbacks_triggered ?? 0) *
                          (data.result.credits_per_callback ?? 0)
                        }
                      />
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <></>
                )}
              </Box>
            </Container>
          </Box>
        </div>
      )}
    </div>
  );
};

function CreditValue({ endpoint, credit, calls, creditsConsumed }) {
  return (
    <Box>
      <Stack
        direction={{ base: "row" }}
        px={{ base: "16px", md: "20px", xl: "32px" }}
        py={{ base: "16px", md: "18px" }}
        borderRadius={"14px"}
        color={useColorModeValue("brand.lighterWhite", "brand.lighterWhite")}
        fontWeight={"400"}
        fontSize={{ base: "14px", lg: "16px" }}
        mt={2}
      >
        <Box width={{ base: "60%", lg: "45%" }}>{endpoint ?? "--"}</Box>
        <Box width={{ base: "20%", lg: "15%" }} textAlign={"center"}>
          {credit ?? "--"}
        </Box>
        <Box
          width={{ base: "20%", lg: "15%" }}
          textAlign={{ base: "end", lg: "center" }}
        >
          x{calls ?? "--"}
        </Box>
        <Box
          width={{ base: "0%", lg: "25%" }}
          textAlign={"end"}
          display={{ base: "none", lg: "block" }}
        >
          {creditsConsumed ?? "--"}
        </Box>
      </Stack>
      <Flex
        width={{ base: "100%" }}
        display={{ base: "flex", lg: "none" }}
        direction={"row"}
        justifyContent={"space-between"}
        px={{ base: "16px", md: "20px", xl: "32px" }}
        pb={{ base: "16px", md: "18px" }}
        color={useColorModeValue("brand.lighterWhite", "brand.lighterWhite")}
        fontSize={{ base: "14px", lg: "16px" }}
      >
        <Text as={"span"}>Credits Used</Text>
        <Text as={"span"} textAlign={"end"}>
          {creditsConsumed ?? "--"}
        </Text>
      </Flex>
    </Box>
  );
}

export default APIUsage;