import {useEffect } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    Flex,
    useDisclosure
  } from '@chakra-ui/react'
import SyncLoader from "react-spinners/SyncLoader";

const Loading = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  useEffect(() => {
    const open = isOpen;
    // console.log("Getting Data",isOpen)
    // console.log("isOpe2n from Modal:",props.isLoadingData)
    
  }, [])
  
    // console.log(isOpen);
    return (
        <>  
          <Modal closeOnOverlayClick={false} isOpen={props.isLoadingData} onClose={() => {}} isCentered>
            <ModalOverlay backdropFilter={"blur(4px)"}/>
            <ModalContent w={"120px"} h={"110px"} bg={"brand.black"} border="2px solid" borderColor={"brand.red"} borderRadius={"2xl"}>
              <ModalBody pb={6} textAlign={"center"}>
                  <Flex justifyContent={"center"} alignItems={"center"} h={"85px"}>
                      <SyncLoader color='#f4f4f4'/>
                  </Flex> 
                  
              </ModalBody>
            </ModalContent>
          </Modal>
        </>
      );
}
 
export default Loading;