// var geocoder = require('google-geocoder');
// var geo = geocoder({
//     key: ''
// });
export function formatCreditUsage(credits=0)
{
    var formattedValue = '';
    if(credits>=1000000000)
    {
        formattedValue = (credits/1000000000)+'B';
    }
    else if(credits<1000000000 && credits>=1000000)
    {
        formattedValue = (credits/1000000)+'M';
    }
    else if(credits<1000000 && credits>=1000)
    {
        formattedValue = (credits/1000)+'K';
    }
    else
    {
        formattedValue = credits.toString();
    }
    return formattedValue;
}
// export async function getStateCityFromCountryZip(country,pincode)
// {
//     var response = {
//         status:"error",
//         location_details: null
//     }
//     geo.find(`${pincode}`, function(err, res){
 
//         console.log(res);
        
//         const getCountry = res[0].country ?? country;
//         const getState = res[0].administrative_area_level_1 ?? country;
//         const getCity = res[0].locality ?? country;

//         response = {
//             status:"success",
//             location_details: {
//                 country: getCountry,
//                 state: getState,
//                 city: getCity
//             }
//         }
//         console.log(response);
        
//         return response;
//         // console.log(res[0].location);
//         // const lat = res[0].location.lat;
//         // const lng = res[0].location.lng;
//         // geo.reverseFind(lat,lng,function(err,res) {
//         //     console.log("city get");
//         //     console.log(res);
//         // });
        
//     });
//     //console.log(details);
    
//     //return {state:"WB",city:"Kolkata"}
// }