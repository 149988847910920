
import claimed from "../../resources/images/dashboard/claimed-offer.gif";

const SuccessOfferClaim = () => {
    
    return ( 
        <div className="modal" style={{ display: "block", backdropFilter: "blur(4px)" }}>
            <div className="modal-dialog modal-sm modal-dialog-centered" style={{zIndex: "110"}}>
                <div className="modal-content" style={{ backgroundColor: "#000", borderRadius: "16px", border: "1px solid #f8f8f8" }}>
                    <div className="modal-body">
                        <div className="offer_claimed_gif" style={{width:"250px",margin: "0 auto", display: "block"}}>
                            <img src={claimed} alt="nft offer claimed" style={{display:"block",margin: "0 auto", width:"250px"}}/>
                        </div>
                        <center className="py-2" style={{font: "600 16px 'Poppins',sans-serif", color: "white"}}>Congratulations!!<br /> Offer Claimed</center>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default SuccessOfferClaim;