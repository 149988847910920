export function getMonthFirstAndLastDate(date) {
    if (!date) {
        date = new Date();
    }

    const year = date.getUTCFullYear();
    const month = date.getUTCMonth();
    const startTime = new Date(new Date(Date.UTC(year, month, 1)).setUTCHours(0, 0, 0, 0));
    const endTime = new Date(new Date(Date.UTC(year, month + 1, 0)).setUTCHours(23, 59, 59, 999));
    const res = {
        startTime: startTime,
        endTime: endTime,
    };
    return res;
}

export function getOrdinals(day){
    let ord = 'th';
    if (day % 10 === 1 && day % 100 !== 11)
    {
        ord = 'st';
    }
    else if (day % 10 === 2 && day % 100 !== 12)
    {
        ord = 'nd';
    }
    else if (day % 10 === 3 && day % 100 !== 13)
    {
        ord = 'rd';
    }
    return `${day}${ord}`;
}

export function getDaysInDates(date1ISO,date2ISO)
{
    console.log("befre",date1ISO,date2ISO);
    const date = new Date(date1ISO);
    const date2 = new Date(date2ISO);
    console.log("after",date,date2);
    
    const dates = [];

    while (date <= date2) {
        dates.push(new Date(date));
        date.setDate(date.getDate() + 1);
    }

    return dates;
}