import MainStyles from "../../resources/css/css.module.css";

import linkedIn from '../../resources/images/social-icons/linkedin.svg';
import twitter from '../../resources/images/social-icons/twitter.svg';
import github from '../../resources/images/social-icons/github.svg';
import behance from '../../resources/images/social-icons/behance.svg';
import reddit from '../../resources/images/social-icons/reddit.svg';


const TeamMember = (props) => {
    return ( 
        <div>
            <div className={MainStyles.team_member_container}>
                <div className={MainStyles.img_container_team}>
                    <img src={props.img} alt="Team Member" className="img-fluid" />
                </div>
                <div className={MainStyles.text_container}>
                    <h4>{props.names}</h4>
                    <h6>{props.post}</h6>
                    <p>{props.desc}<br />{props.desc2}</p>
                    <div className="d-flex justify-content-center">
                        {(props.linkedIn)?
                        (<div className="px-2">
                            <a href={props.linkedIn} target="_blank" rel="noreferrer">
                                <img src={linkedIn} alt="linkedin" />
                            </a>
                        </div>):""}
                        {(props.twitter)?
                        (<div className="px-2">
                            <a href={props.twitter} target="_blank" rel="noreferrer">
                                <img src={twitter} alt="Twitter" />
                            </a>
                        </div>):""}
                        {(props.github)?
                        (<div className="px-2">
                            <a href={props.github} target="_blank" rel="noreferrer">
                                <img src={github} alt="Git" />
                            </a>
                        </div>):""}
                        {(props.behance)?
                        (<div className="px-2">
                            <a href={props.behance} target="_blank" rel="noreferrer">
                                <img src={behance} alt="behance" />
                            </a>
                        </div>):""}
                        {(props.reddit)?
                        (<div className="px-2">
                            <a href={props.reddit} target="_blank" rel="noreferrer">
                                <img src={reddit} alt="reddit" />
                            </a>
                        </div>):""}
                        
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default TeamMember;