import { useEffect, useState } from "react";
import {
  Box,
  Text,
  Flex,
  VisuallyHidden,
  chakra,
  Container,
  Button,
  IconButton
} from "@chakra-ui/react";
import { FaCopy, FaCheckSquare } from "react-icons/fa";
import axios from "axios";
import { ReactSession } from "react-client-session";
// import { PopupButton } from "react-calendly";
import GrpcMap from "./GrpcMap";
// import { ExternalLinkIcon } from '@chakra-ui/icons';

import LinkDarkCards from "./LinkDarkCards";


const SocialButton = ({ children, label, href }) => {
  return (
    <chakra.button
      bg={"brand.red"}
      color={"brand.white"}
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      href={href}
      target="_blank"
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: "brand.white",
        color: "brand.red",
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

const Grpcs = (props) => {

  const [data,setData] = useState(null);
  const [copied, setCopied] = useState(false);
  const [errOcc, setErrOcc] = useState(false);

  useEffect(() => {
    const token = ReactSession.get("xTkn") ?? "";

    if (token !== "") {
      const endPoint = process.env.REACT_APP_AUTH_EP;
      axios({
        url: `${endPoint}dashboard/get_grpc_details`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "ngrok-skip-browser-warning": "69420",
        },
      })
        .then((res) => {
          if (res.data.success === true) {
            setData(res.data.result);
          } else {
            setErrOcc(true);
          }
        })
        // Catch errors if any
        .catch((err) => {
          console.warn(err);
          setErrOcc(true);
          props.setLoading(false);
        });
    } else {
      //navigate("/get-api-key");
    }
  }, []);

  

  useEffect(() => {
      // setTimeout(() => {
      //   props.setLoading(false);
      // },800)
    if (data !== null || errOcc === true) props.setLoading(false);
  }, [data, errOcc]);

  const copyGrpcToken = () => {
    navigator.clipboard.writeText(data?.token ?? "--");
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const selectPlanAndPay = () => {
    props.setPlan("665d7fda4c2f3b7a8d629933");
    props.setPlanName("gRPC");
    props.setShowCard(true);
  };

  return (
    <Container maxW={"8xl"}>
      <Box
        as="div"
        // px={{ base: '16px', md: '24px', xl: '32px' }}
        // py={{ base: '16px', md: '24px' }}
        borderRadius={"14px"}
      >
        <Box>
          <Text
            as={"div"}
            fontFamily={"heading"}
            color={"brand.white"}
            fontSize={"2xl"}
            lineHeight={"3xl"}
            fontWeight={"600"}
            position={"relative"}
          >
            Shyft gRPC Nodes

          </Text>

          <Text
            as={"div"}
            fontSize={{ base: "14px", lg: "md" }}
            fontWeight={"400"}
            color={"brand.lighterWhite"}
            pt={{ base: "4px", md: "6px" }}
          >
            Supercharge your Solana streaming experience
          </Text>
        </Box>
      </Box>
      <Box pt={8}>
        <Text as={"div"} color={"brand.white"} fontSize={{ base: "14px", lg: "md" }}>
        Introducing Shyft gRPC service - low-latency and fault tolerant network of gRPC nodes spread across the planet. Choose the one closest to you and start streaming, without any credits or metering.
        </Text>
      </Box>
      {(data === null) ? <Box pt={8} pb={8}>
        <Box
          border={"2px solid"}
          borderColor={"brand.lightGray"}
          borderRadius={"14px"}
          _hover={{
            borderColor: "brand.red"
          }}
          backgroundColor={"black"}
          p={4}
          pb={4}
          ps={6}
        >
          <Flex flexDirection={{ base: "column", md: "row" }} justifyContent={{ base: "center", md: "space-between" }} alignItems={{base: "initial", md: "center", xl: "start"}}>
            <Box flex='1'>
              <Text
                as={"div"}
                fontFamily={"heading"}
                color={"brand.white"}
                fontSize={"xl"}
                lineHeight={"2xl"}
                fontWeight={"600"}
                position={"relative"}
                pb={"10px"}
              >
                Flat $199 / month / IP
              </Text>
              <Text as={"div"} color={"brand.lighterWhite"} fontSize={{ base: "14px", lg: "md" }}>
                No credits, no metering. Each region is powered by multiple nodes for fault tolerance.
              </Text>
              <Text as={"div"} color={"brand.lighterWhite"} pt={"10px"} fontWeight={500} fontSize={{ base: "14px", lg: "md" }}>
                Reach out to us on <Text as={"a"} href="https://discord.gg/8JyZCjRPmr" target="_blank" color={"brand.yellow"} _hover={{ color: "brand.red" }}>discord</Text> for queries.
              </Text>
            </Box>
            <Box width={{ base: "20px", md: "48px" }} h={{ base: "20px", md: "40px" }}></Box>
            <Box ps={{base: '0px',md: '18px'}} pe={{base: '10px',md: '18px'}} textAlign={"center"}>
              <Flex flexDirection={{base: "row", md: "column"}} justifyContent={{base: "space-between", md: "flex-start"}}>
                <Box>
                  <Text
                    as={"span"}
                    fontSize={"4xl"}
                    lineHeight={{base: "45px", md: "34px"}}
                    fontWeight={"700"}
                    color={"brand.white"}
                    fontFamily={"heading"}
                  >
                    $199
                  </Text>
                  <Box>
                    <Text
                      as={"div"}
                      fontSize={"13px"}
                      fontWeight={"300"}
                      color={"brand.lighterWhite"}
                      fontFamily={"heading"}
                      mt={"-4px"}
                      pb={2}
                    >
                      PER MONTH
                    </Text>
                  </Box>
                </Box>
                <Box>
                  <Button
                    // as={"a"}
                    // href="https://discord.gg/8JyZCjRPmr"
                    // target="_blank"
                    py={"8px"}
                    display={"block"}
                    size={"xs"}
                    width={"28"}
                    height={"32px"}
                    margin={"0 auto"}
                    bgColor={'brand.red'}
                    color={'brand.white'}
                    borderRadius={"full"}
                    textColor={"#fff"}
                    fontFamily={"heading"}
                    fontWeight={"400"}
                    _hover={{
                      bgColor: 'brand.white',
                      color: 'brand.red',
                      textDecoration: 'none',
                    }}
                    mt={{base: 3, md: 1, lg: 0.5}}
                    onClick={selectPlanAndPay}
                  >
                    Buy gRPC
                  </Button>
                </Box>
              </Flex>


            </Box>
          </Flex>
        </Box>
      </Box>:
      <Box pt={8} pb={8}>
        <Flex
          justifyContent={"space-between"}
          alignItems={"center"}
          pb={2}
          px={1}
        >
          <Text
            fontSize={{ base: 'lg', md: 'xl' }}
            fontFamily={'heading'}
            fontWeight={'600'}
            color={'brand.white'}
          >
            Your gRPC Token
          </Text>
          <Text
            as={'span'}
            fontSize={{ base: "12px", lg: "14px" }}
            fontWeight={"400"}
            color={"brand.lighterWhite"}
          >
           IPs Allowed: 
           <Text
            as={'span'} color={"brand.yellow"} fontWeight={"bold"}>{" "}{data.ip_allowed ?? 1} </Text>
          </Text>
        </Flex>
        
        <Box
          as="div"
          bg="black"
          px={{ base: "16px", md: "24px", xl: "32px" }}
          py={{ base: "16px", md: "18px" }}
          borderRadius={"14px"}
          border={"2px solid"}
          borderColor={"brand.red"}
        >
          <Flex
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Text
              as={"span"}
              fontSize={{ base: "14px", lg: "16px" }}
              letterSpacing={"0.05em"}
              color={"brand.lighterWhite"}
            >
                {data.token}
            </Text>
            {copied ? (
              <IconButton
                size={"sm"}
                aria-label="Copy to clipboard"
                icon={<FaCheckSquare size={"18"} />}
                bg={"transparent"}
                color={"green.300"}
                _hover={{ bg: "transparent" }}
              />
            ) : (
              <IconButton
                size={"sm"}
                aria-label="Copy to clipboard"
                icon={<FaCopy size={"18"} />}
                onClick={copyGrpcToken}
                bg={"transparent"}
                color={"brand.yellow"}
                _hover={{ bg: "transparent" }}
              />
            )}
          </Flex>
        </Box>
      </Box>}

      <Box>
        <Text
          fontSize={{ base: 'lg', md: 'xl' }}
          fontFamily={'heading'}
          fontWeight={'600'}
          color={'brand.white'}
        >
          Your gRPC URL
        </Text>
      </Box>
      <Box as="div">
        <GrpcMap />
      </Box>


      <Box mt={12} pb={10}>
        <Flex justifyContent={'space-between'} alignItems={'center'} px={1}>
          <Text
            fontSize={{ base: 'lg', md: 'xl' }}
            fontFamily={'heading'}
            fontWeight={'600'}
            color={'brand.white'}
          >
            Support
          </Text>
        </Flex>
        <Flex
          direction={{ base: 'column', md: 'row' }}
          justifyContent={{ base: 'center', md: 'space-between' }}
          alignItems={'center'}
        >
          <Box w={'full'} maxW={{ base: '100%', lg: '49%' }}>
            <LinkDarkCards title='Telegram' link='https://t.me/+elnozTFe3OY0NDA1' />
          </Box>
          <Box w={'full'} maxW={{ base: '100%', lg: '49%' }}>
            <LinkDarkCards title='Discord' link='https://discord.gg/8JyZCjRPmr' />
          </Box>
        </Flex>
      </Box>
    </Container>
  );
};

export default Grpcs;
