import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Flex, Text } from '@chakra-ui/react';

const LinkDarkCards = ({ title, link, newTab = true }) => {
    return (
        <Flex
            as={'a'}
            href={link}
            target={newTab ? '_blank' : '_self'}
            justifyContent={'space-between'}
            alignItems={'center'}
            bg="black"
            px={{ base: '16px', md: '24px', xl: '32px' }}
            py={{ base: '16px', md: '18px' }}
            mt={4}
            borderRadius={'14px'}
        >
            <Text as="span" fontSize={{base: "14px", md: "18px"}} color={"brand.white"}>{title}</Text>
            <ExternalLinkIcon boxSize={{base: 4, lg: 5}} color={"brand.white"}/>
        </Flex>
    );
};

export default LinkDarkCards;