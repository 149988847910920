import { Button } from "@chakra-ui/react";
import { useGoogleLogin } from "@react-oauth/google";
import { useState, useEffect } from "react";
import axios from "axios";
import { FaGoogle } from "react-icons/fa";
import { ReactSession } from "react-client-session";

export default function GoogleLoginButton({
  setPartyLoginMessage,
  navigate,
  setLoading,
}) {
  const [currentGoogleUser, setCurrentGoogleUser] = useState(null);

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      //console.log(codeResponse);
      setCurrentGoogleUser(codeResponse);
    },
    onError: (error) => console.log("Login Failed: ", error),
  });
  useEffect(() => {
    if (currentGoogleUser) {
      setLoading(true);
      axios({
        url: `${process.env.REACT_APP_AUTH_EP}auth/authorize-google-login`,
        method: "POST",
        headers: {
          //   Authorization: `Bearer ${currentGoogleUser.access_token}`,
          "Content-Type": "application/json",
        },
        data: {
          access_token: currentGoogleUser.access_token,
        },
      })
        .then((res) => {
          console.log("Logging in with Google");
          //console.log(res.data);
          setLoading(false);
          if (res.data.success === true) {
            // setMsg('Please Wait');
            // header set : res.data.result.access_token
            ReactSession.set("xTkn", res.data.result.access_token);
            navigate('/dashboard/overview')
            // if (res.data.result.is_new_user === true) navigate("/user-details");
            // else navigate("/dashboard/overview");
          } else {
            setPartyLoginMessage("Error! Please try again later");
            ReactSession.set("xTkn", "");
            //remove Token
          }
        })
        .catch((err) => {
          setLoading(false);
          setPartyLoginMessage("Error! Please try again later");
          ReactSession.set("xTkn", "");
          console.log(err);
        });
    }
  }, [currentGoogleUser]);

  return (
    <Button 
        leftIcon={<FaGoogle size={20} />}
        py={{base:2, md: 6}}
        borderRadius={"full"}
        border={"2px solid"}
        borderColor={"brand.red"}
        fontFamily="heading"
        fontSize={{ base: "14px", md: "16px" }}
        fontWeight={500}
        bg={"transparend"}
        color={"brand.white"}
        _hover={{ bg: "brand.red", color: "brand.white" }}
        onClick={() => googleLogin()}
    >
      Continue with Google
    </Button>
  );
}
