import { useState, useEffect } from "react";
import { ReactSession } from "react-client-session";
import { useNavigate, Link } from "react-router-dom";
import {
  WalletMultiButton,
  WalletDisconnectButton,
} from "@solana/wallet-adapter-react-ui";
import { useWallet } from "@solana/wallet-adapter-react";

import axios from "axios";
import ReactGA from "react-ga4";
import { Share } from "react-twitter-widgets";

import { formatCreditUsage } from "../../utils/CreditFormat";
import {
  Box,
  Button,
  Flex,
  Text,
  IconButton,
  HStack,
  Stack,
  Image,
  Switch,
  FormControl,
  FormLabel,
  Container,
  RadioGroup,
  Radio
} from "@chakra-ui/react";
import { FaCopy, FaCheckSquare, FaCheckCircle } from "react-icons/fa";
import { MdCancel } from "react-icons/md";

import LinkDarkCards from "./LinkDarkCards";

const Overview = (props) => {
  const navigate = useNavigate();
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const rewardSysActive = process.env.REACT_APP_REWARD_SYSTEM ?? "inactive";
  const [errOcc, setErrOcc] = useState(false);
  const [data, setData] = useState(null);
  const [copied, setCopied] = useState(false);
  const [apiKeyString, setApiKeyString] = useState("");

  const [copiedRPC, setCopiedRPC] = useState(false);
  const [showDevnet, setDevnet] = useState(false);

  const [selectedUrl,setSelectedUrl] = useState("1");

  const [activeUrl,setActiveUrl] = useState(`https://rpc.shyft.to?api_key=${apiKeyString}`);

  const [gRPCStatus,setGRPCStatus] = useState(false);

  useEffect(() => {
    if(showDevnet){
      if(selectedUrl === "1")
        setActiveUrl(`https://devnet-rpc.shyft.to?api_key=${apiKeyString}`);
      else if(selectedUrl === "2")
        setActiveUrl(`wss://devnet-rpc.shyft.to?api_key=${apiKeyString}`);
      else if(selectedUrl === "3")
        setActiveUrl(`https://programs.shyft.to/v0/graphql/?api_key=${apiKeyString}&network=devnet`);
    }
    else {
      if(selectedUrl === "1")
        setActiveUrl(`https://rpc.shyft.to?api_key=${apiKeyString}`);
      else if(selectedUrl === "2")
        setActiveUrl(`wss://rpc.shyft.to?api_key=${apiKeyString}`);
      else if(selectedUrl === "3")
        setActiveUrl(`https://programs.shyft.to/v0/graphql/?api_key=${apiKeyString}&network=mainnet-beta`);
    }
  }, [selectedUrl,showDevnet]);

  const { publicKey } = useWallet();

  useEffect(() => {
    document.title = "User Dashboard - SHYFT";
    ReactGA.send({ hitType: "pageview", page: "/dashboard/overview" });
  }, []);
  useEffect(() => {
    const token = ReactSession.get("xTkn") ?? "";

    if (token !== "") {
      const endPoint = process.env.REACT_APP_AUTH_EP;
      axios({
        url: `${endPoint}dashboard/billing`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "ngrok-skip-browser-warning": "69420",
        },
      })
        .then((res) => {
          if (res.data.success === true) {
            setData(res.data);
            setApiKeyString(res.data.result.api_key);
            setActiveUrl(`https://rpc.shyft.to?api_key=${res.data.result.api_key ?? "--"}`)
            props.setCurrentPlan(res.data.result.current_plan ?? "FREE");
            if (res.data.result.current_plan === "FREE")
              ReactSession.set("ifr", "FREE");
            else ReactSession.set("ifr", "Paid");
          } else {
            setErrOcc(true);
          }
        })
        // Catch errors if any
        .catch((err) => {
          console.warn(err);
          setErrOcc(true);
          props.setLoading(false);
        });
    } else {
      navigate("/get-api-key");
    }
  }, []);

  useEffect(() => {
    if (data !== null || errOcc === true) props.setLoading(false);
  }, [data, errOcc]);

  useEffect(() => {
    const token = ReactSession.get("xTkn") ?? "";

    if (token !== "" && rewardSysActive === "active") {
      const endPoint = process.env.REACT_APP_AUTH_EP;
      axios({
        url: `${endPoint}dashboard/reward/active`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "ngrok-skip-browser-warning": "69420",
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (res.data.success === true) {
            //console.log("Im here",res.data.result);
            props.setCurrentOffer(res.data.result.reward);
          }
          props.setOfferLoad("loaded");
        })
        // Catch errors if any
        .catch((err) => {
          console.warn(err);
          if (err.response.status === 404) {
            props.setOfferLoad("loaded");
          } else {
            props.setOfferLoad("error");
          }
        });
    }
  }, []);

  useEffect(() => {
    const token = ReactSession.get("xTkn") ?? "";

    if (token !== "" && rewardSysActive === "active") {
      const endPoint = process.env.REACT_APP_AUTH_EP;
      axios({
        url: `${endPoint}dashboard/get_active_plans`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "ngrok-skip-browser-warning": "69420",
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (res.data.success === true) {
            const gRPCPlanPresent = res.data.result?.find(
              (plan) => plan.plan_name === "GRPC"
            );
            console.log("here")
            console.log(gRPCPlanPresent);
            if (gRPCPlanPresent) setGRPCStatus(true);
          }
        })
        // Catch errors if any
        .catch((err) => {
          console.warn(err);
          
        });
    }
  }, []);

  const copyCon = () => {
    navigator.clipboard.writeText(data.result?.api_key ?? "--");
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };
  const copyConRPC = () => {
    navigator.clipboard.writeText(activeUrl);
    setCopiedRPC(true);
    setTimeout(() => {
      setCopiedRPC(false);
    }, 1200);
  };

  return (
    <>
      {!props.isLoading && errOcc === true && (
        <Box
          as="div"
          bg="black"
          px={{ base: "16px", md: "24px", xl: "32px" }}
          py={{ base: "16px", md: "24px" }}
          borderRadius={"14px"}
        >
          <Box textAlign={"center"} color={"brand.grey"}>
            <Text fontSize={"xl"}>
              Some Error Occured while Loading Data. Please Try Again Later.
            </Text>
          </Box>
        </Box>
      )}
      {!props.isLoading && data !== null && (
        <Box>
          <Container maxW={"8xl"}>
            {!gRPCStatus && <Box 
              as="div"
              bg="black"
              px={{ base: "16px", md: "24px", xl: "32px" }}
              py={{ base: "16px", md: "8px", xl: "12px" }}
              mb={{base: "16px", md: "24px", xl: "36px"}}
              borderRadius={"14px"}
              border={"2px solid"}
              borderColor={"brand.lightGrey"}
              height={"100%"}
            >
              <Flex flexDirection={{base: "column", md: "row"}} justifyContent={{base: "center", md: "space-between"}} alignItems={"center"}>
                <Box>
                  <Text as="span" className="flashy_text" fontSize={{ base: "14px", lg: "16px" }}>New!</Text>🔥 <Text as="span" fontSize={{ base: "14px", lg: "16px" }} color={"brand.lighterWhite"} fontWeight={500}>Introducing Shyft gRPC network for low-latency streaming, only $199/mo/ip.</Text>
                </Box>
                <Box pt={{base: "12px", md: "0px"}} minW={"90px"}>
                  <Link to={"/dashboard/grpc"}>
                    <Box
                      size={"xs"}
                      fontSize={"xs"}
                      fontWeight={"400"}
                      fontFamily={"heading"}
                      borderRadius={"full"}
                      px={{ base: "12px", xl: "24px" }}
                      py={{ base: "6px", lg: "6px" }}
                      bg={"brand.red"}
                      // border="2px solid"
                      // borderColor="brand.red"
                      color={"brand.white"}
                      textAlign={"center"}
                      _hover={{
                        bg: "brand.white",
                        color: "brand.red",
                      }}
                    >
                      Get Access
                    </Box>
                  </Link>
                </Box>
              </Flex>
            </Box>}
            

            <Box mt={6}>
              <Flex
                direction={{ base: "column", lg: "row" }}
                justifyContent={{ base: "center", lg: "space-between" }}
                alignItems={"center"}
              >
                <Box w={"full"} maxW={{ base: "100%", lg: "49%" }}>
                  <Flex
                    justifyContent={{
                      base: "space-between",
                      lg: "space-between",
                    }}
                    alignItems={"center"}
                    pb={2}
                    px={1}
                  >
                    <Text
                        as="span"
                      fontSize={{ base: "16px", lg: "20px" }}
                      fontFamily={"heading"}
                      letterSpacing={"0.03em"}
                      color={"brand.lighterWhite"}
                    >
                      Your API Key
                    </Text>
                  </Flex>
                  <Box
                    as="div"
                    bg="black"
                    px={{ base: "16px", md: "24px", xl: "32px" }}
                    py={{ base: "16px", md: "18px" }}
                    borderRadius={"14px"}
                  >
                    <Flex
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Text
                      as={'span'}
                        fontSize={{ base: "14px", lg: "16px" }}
                        letterSpacing={"0.03em"}
                        color={"brand.lighterWhite"}
                      >
                        {data.result.api_key ?? "--"}
                      </Text>
                      {copied ? (
                        <IconButton
                          size={"sm"}
                          aria-label="Copy to clipboard"
                          icon={<FaCheckSquare size={"18"} />}
                          bg={"transparent"}
                          color={"green.300"}
                          _hover={{ bg: "transparent" }}
                        />
                      ) : (
                        <IconButton
                          size={"sm"}
                          aria-label="Copy to clipboard"
                          icon={<FaCopy size={"18"} />}
                          onClick={copyCon}
                          bg={"transparent"}
                          color={"brand.yellow"}
                          _hover={{ bg: "transparent" }}
                        />
                      )}
                    </Flex>
                  </Box>
                </Box>
                <Box
                  w={"full"}
                  maxW={{ base: "100%", lg: "49%" }}
                  mt={{ base: "16px", lg: "0px" }}
                >
                  <Flex
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    pb={2}
                    px={1}
                  >
                    <Text
                     as={'span'}
                      fontSize={{ base: "16px", lg: "20px" }}
                      fontFamily={"heading"}
                      letterSpacing={"0.03em"}
                      color={"brand.lighterWhite"}
                    >
                      Current Plan
                    </Text>
                    {data.result.cycle_start ? (
                      <Text
                      as={'span'}
                        fontSize={{ base: "12px", lg: "14px" }}
                        fontWeight={"300"}
                        color={"brand.lighterWhite"}
                      >
                        {new Date(data.result.cycle_start).getUTCDate()}{" "}
                        {
                          months[
                            new Date(data.result.cycle_start).getUTCMonth()
                          ]
                        }{" "}
                        {new Date(data.result.cycle_start).getUTCFullYear()} -{" "}
                        {new Date(data.result.cycle_end).getUTCDate()}{" "}
                        {months[new Date(data.result.cycle_end).getUTCMonth()]}{" "}
                        {new Date(data.result.cycle_end).getUTCFullYear()}
                      </Text>
                    ) : (
                      <Text
                        as={'span'}
                        fontSize={{ base: "14px", md: "16px" }}
                        fontWeight={"300"}
                        color={"brand.lighterWhite"}
                      >
                        {" "}
                        --{" "}
                      </Text>
                    )}
                  </Flex>
                  <Box
                    as="div"
                    bg="black"
                    px={{ base: "16px", md: "24px", xl: "32px" }}
                    py={{ base: "15px", md: "12px",xl: "22px" }}
                    borderRadius={"14px"}
                  >
                    <Flex
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Box>
                        <Flex flexDir={{base: "row", lg:"column", xl: "row"}} alignItems={"center"} flexWrap={"wrap"}>
                          <Box>
                            <Text
                              as={'span'}
                              fontSize={{ base: "13px", xl: "16px" }}
                              letterSpacing={"0.01em"}
                              color={"brand.lighterWhite"}
                            >
                              API/RPC plan: <Text as="span" color={"brand.yellow"} fontWeight={{base:"400", md:"400"}}>{data.result?.current_plan}</Text> +
                            </Text>
                          </Box>
                          <Box ps={{base:0, md: 1}}>
                            <Flex alignItems={"center"} justifyContent={"flex-start"}>
                              <Text as={"div"} color={"brand.lighterWhite"} fontSize={{ base: "13px", xl: "16px" }}>gRPC Access: </Text>
                              {gRPCStatus?<Box ps={{base: 0,md: 1}}><FaCheckCircle color="#03C04A" size={17}/></Box>:<Box ps={1}><MdCancel color="#E33535" size={18}/></Box>}
                            </Flex>
                          </Box>
                        </Flex>
                        
                      </Box>
                      {data.result.current_plan === "FREE" ? (
                        <Link to="/dashboard/pricing">
                          <Box
                            size={"xs"}
                            fontSize={"xs"}
                            fontWeight={"400"}
                            fontFamily={"heading"}
                            borderRadius={"full"}
                            px={{ base: "12px", xl: "24px" }}
                            py={{ base: "6px", lg: "6px" }}
                            bg={"transparent"}
                            border="2px solid"
                            borderColor="brand.red"
                            color={"brand.white"}
                            my={"-2"}
                            _hover={{
                              bg: "brand.red",
                            }}
                            textAlign={"center"}
                          >
                            Upgrade Plan
                          </Box>
                        </Link>
                      ) : (
                        <Text
                        as={'span'}
                        fontSize={{ base: "14px", lg: "16px" }}
                          fontFamily={"body"}
                          fontWeight={"400"}
                          letterSpacing={"0.03em"}
                          color={"brand.lighterWhite"}
                        >
                          {data.result?.currency === "inr" ? "₹ " : "$ "}
                          {data.result?.amount_payable ?? "--"}
                        </Text>
                      )}
                      {/* <Box>
                        <Flex alignItems={"center"}>
                          <Text as={"div"} color={"brand.lighterWhite"}>gRPC Access: </Text>
                          {gRPCStatus?<Box ps={1}><FaCheckCircle color="#03C04A" size={20}/></Box>:<Box ps={1}><MdCancel color="#E33535" size={20}/></Box>}
                        </Flex>
                      </Box> */}
                    </Flex>
                  </Box>
                </Box>
              </Flex>
            </Box>
            <Box mt={12}>
              <Flex
                direction={{ base: "column", lg: "row" }}
                justifyContent={{ base: "center", lg: "space-between" }}
                alignItems={"center"}
              >
                <Box w={"full"} maxW={{ base: "100%", lg: "full" }}>
                  <Flex
                    justifyContent={{
                      base: "space-between",
                      lg: "space-between",
                    }}
                    alignItems={"center"}
                    pb={2}
                    px={1}
                  >
                    <Box w={"full"} maxW={{ base: "100%", lg: "410px" }}>
                      <RadioGroup onChange={setSelectedUrl} value={selectedUrl} defaultValue="1">
                        <Stack direction={{base:'column', md: "row"}} spacing={{base: 2, md: 4, lg: 8}} color={"brand.lighterWhite"}>
                          <Radio value={"1"} colorScheme='switchYellow' color={"brand.lighterWhite"}>RPC</Radio>
                          <Radio value={"2"} colorScheme='switchYellow' color={"brand.lighterWhite"}>Web Socket</Radio>
                          <Radio value={"3"} colorScheme='switchYellow' color={"brand.lighterWhite"}>GraphQL</Radio>
                        </Stack>
                      </RadioGroup>
                    </Box>
                    <Box>
                      <FormControl display="flex" alignItems="center" pb={{base: 2, lg: 0}}>
                        <FormLabel
                          htmlFor="network-select"
                          mb="0"
                          color={showDevnet ? "brand.midGrey" : "brand.white"}
                          fontWeight={"300"}
                          fontSize={{ base: "14px", lg: "16px" }}
                          transition={"all 0.2s ease"}
                        >
                          Mainnet
                        </FormLabel>
                        <Switch
                          id="network-select"
                          onChange={(e) => setDevnet(!showDevnet)}
                          colorScheme="gray"
                        />
                        <FormLabel
                          htmlFor="network-select"
                          mb="0"
                          color={showDevnet ? "brand.white" : "brand.midGrey"}
                          fontWeight={"300"}
                          ps={"3"}
                          fontSize={{ base: "14px", lg: "16px" }}
                          transition={"all 0.2s ease"}
                        >
                          Devnet
                        </FormLabel>
                      </FormControl>
                    </Box>
                  </Flex>
                  <Box
                    as="div"
                    bg="black"
                    px={{ base: "16px", md: "24px", xl: "32px" }}
                    py={{ base: "16px", md: "18px" }}
                    borderRadius={"14px"}
                  >
                    <Flex
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Text
                        as={"span"}
                        fontSize={{ base: "14px", lg: "16px" }}
                        letterSpacing={"0.03em"}
                        color={"brand.lighterWhite"}
                      >
                          {activeUrl}
                      </Text>
                      {copiedRPC ? (
                        <IconButton
                          size={"sm"}
                          aria-label="Copy to clipboard"
                          icon={<FaCheckSquare size={"18"} />}
                          bg={"transparent"}
                          color={"green.300"}
                          _hover={{ bg: "transparent" }}
                        />
                      ) : (
                        <IconButton
                          size={"sm"}
                          aria-label="Copy to clipboard"
                          icon={<FaCopy size={"18"} />}
                          onClick={copyConRPC}
                          bg={"transparent"}
                          color={"brand.yellow"}
                          _hover={{ bg: "transparent" }}
                        />
                      )}
                    </Flex>
                  </Box>
                </Box>
              </Flex>
            </Box>
            <Box mt={12}>
              <Flex
                justifyContent={"space-between"}
                alignItems={"center"}
                pb={3}
                px={1}
              >
                <Text
                    as={"div"}
                  fontSize={{ base: "16px", lg: "20px" }}
                  fontFamily={"heading"}
                  letterSpacing={"0.03em"}
                  color={"brand.lighterWhite"}
                >
                  Credits & API Calls
                </Text>
                {data.result.cycle_start ? (
                  <Text
                    fontSize={{ base: "12px", md: "14px" }}
                    fontWeight={"300"}
                    color={"brand.lighterWhite"}
                  >
                    {new Date(data.result.cycle_start).getUTCDate()}{" "}
                    {months[new Date(data.result.cycle_start).getUTCMonth()]}{" "}
                    {new Date(data.result.cycle_start).getUTCFullYear()} -{" "}
                    {new Date(data.result.cycle_end).getUTCDate()}{" "}
                    {months[new Date(data.result.cycle_end).getUTCMonth()]}{" "}
                    {new Date(data.result.cycle_end).getUTCFullYear()}
                  </Text>
                ) : (
                  <Text
                    fontSize={{ base: "12px", md: "16px" }}
                    fontWeight={"300"}
                    color={"brand.lighterWhite"}
                  >
                    --
                  </Text>
                )}
              </Flex>
              <Box
                as="div"
                bg="black"
                px={{ base: "16px", md: "24px", xl: "32px" }}
                py={{ base: "16px", md: "24px", xl: "32px" }}
                borderRadius={"14px"}
                border={"2px solid"}
                borderColor={"brand.red"}
              >
                <Flex
                  direction={{ base: "column", lg: "row" }}
                  justifyContent={{ base: "center", lg: "space-around" }}
                  alignItems={"center"}
                >
                  <Box
                    mt={{ base: "8px", lg: "0px" }}
                    w={"full"}
                    // maxW={"300px"}
                  >
                    <Text
                    as={"div"}
                      fontSize={{ base: "28px", lg: "40px" }}
                      lineHeight={{ base: "32px", lg: "40px" }}
                      fontWeight={"700"}
                      color={"brand.yellow"}
                      textAlign={"center"}
                    >
                      {data.result.credit_used.toLocaleString() ?? 0}
                      <Text as={"span"} fontSize={{ base: "14px", lg: "16px" }}>
                      /{formatCreditUsage(data.result.total_credits ?? 0)}
                      </Text>
                    </Text>
                    <Text
                        as={"div"}
                      fontSize={{ base: "14px", lg: "18px" }}
                      letterSpacing={"0.03em"}
                      color={"brand.lighterWhite"}
                      textAlign={"center"}
                      pt={1}
                    >
                      Credits Used
                    </Text>
                  </Box>

                  <Box
                    mt={{ base: "18px", lg: "0px" }}
                    w={"full"}
                    // maxW={"200px"}
                  >
                    <Text
                        as={"div"}
                      fontSize={{ base: "28px", lg: "40px" }}
                      lineHeight={{ base: "32px", lg: "40px" }}
                      fontWeight={"700"}
                      color={"brand.yellow"}
                      textAlign={"center"}
                    >
                      {data.result.total_api_calls ?? 0}
                      <Text
                        as={"span"}
                        fontSize={{ base: "14px", lg: "20px" }}
                      ></Text>
                    </Text>
                    <Text
                        as={"div"}
                      fontSize={{ base: "14px", lg: "18px" }}
                      letterSpacing={"0.03em"}
                      color={"brand.lighterWhite"}
                      textAlign={"center"}
                      pt={1}
                    >
                      Total API calls
                    </Text>
                  </Box>

                  <Box
                    mt={{ base: "18px", lg: "0px" }}
                    w={"full"}
                    // maxW={"200px"}
                  >
                    <Text
                        as={"div"}
                      fontSize={{ base: "28px", lg: "40px" }}
                      lineHeight={{ base: "32px", lg: "40px" }}
                      fontWeight={"700"}
                      color={"brand.yellow"}
                      textAlign={"center"}
                    >
                      {data.result.total_callback_triggered ?? 0}{" "}
                      <Text as={"span"} fontSize={{ base: "14px", lg: "20px" }}>
                        &nbsp;
                      </Text>
                    </Text>
                    <Text
                        as={"div"}
                      fontSize={{ base: "14px", lg: "18px" }}
                      letterSpacing={"0.03em"}
                      color={"brand.lighterWhite"}
                      textAlign={"center"}
                      pt={1}
                    >
                      Callbacks Reported
                    </Text>
                  </Box>
                </Flex>
              </Box>
            </Box>
            <Box mt={12}>
              <Flex
                justifyContent={"space-between"}
                alignItems={"center"}
                px={1}
              >
                <Text
                    as={"div"}
                  fontSize={{ base: "16px", lg: "20px" }}
                  fontFamily={"heading"}
                  letterSpacing={"0.03em"}
                  color={"brand.lighterWhite"}
                >
                  Developer Tools
                </Text>
              </Flex>
              <Flex
                direction={{ base: "column", lg: "row" }}
                justifyContent={{ base: "center", lg: "space-between" }}
                alignItems={"center"}
              >
                <Box w={"full"} maxW={{ base: "100%", lg: "49%" }}>
                  <LinkDarkCards
                    title="Documentation"
                    link="https://docs.shyft.to/"
                  />
                </Box>
                <Box w={"full"} maxW={{ base: "100%", lg: "49%" }}>
                  <LinkDarkCards
                    title="SHYFT SDK"
                    link="https://www.npmjs.com/package/@shyft-to/js"
                  />
                </Box>
              </Flex>
              <Flex
                direction={{ base: "column", lg: "row" }}
                justifyContent={{ base: "center", lg: "space-between" }}
                alignItems={"center"}
              >
                <Box w={"full"} maxW={{ base: "100%", lg: "49%" }}>
                  <LinkDarkCards
                    title="Tryout APIs in Swagger UI"
                    link="https://api.shyft.to/sol/api/explore/"
                  />
                </Box>
                <Box w={"full"} maxW={{ base: "100%", lg: "49%" }}>
                  <LinkDarkCards
                    title="Postman Docs"
                    link="https://documenter.getpostman.com/view/18419720/UzQvt5Kf"
                  />
                </Box>
              </Flex>
            </Box>
          </Container>
        </Box>
      )}
    </>
  );
};

export default Overview;
