import MainStyles from "../../resources/css/css.module.css";
import TeamMember from "./TeamMember";

import ishkaran from '../../resources/images/teams/ishkaran.svg';
import vishesh from '../../resources/images/teams/vishesh.svg';
import girish from '../../resources/images/teams/girish.svg';
import rex from '../../resources/images/teams/rex.svg';
import abhirup from '../../resources/images/teams/abhirup.svg';
import vaish from '../../resources/images/teams/vaish.svg';

const Teams = () => {
    return ( 
        <div className={`${MainStyles.dark_backdrop_gradient}`}>
            <div className={`${MainStyles.team_page}`}>
                <div className="container-lg">
                    <h1 className={MainStyles.main_headings}>Meet Our Extraordinary Team</h1>
                </div>
                <div className="container-lg pt-5">
                    <div className="d-flex flex-wrap justify-content-center">
                        <TeamMember img={ishkaran} names="Ishkaran Singh" post="CEO" desc="Programmer and problem solver;" desc2="Pursues passion." twitter="#" linkedIn="#" github="#" />
                        <TeamMember img={vishesh} names="Vishesh Gupta" post="CTO" desc="Programmer and problem solver;" desc2="Pursues passion." twitter="#" linkedIn="#" github="#" />
                        <TeamMember img={girish} names="Girish Nayak" post="COO" desc="Marketing, Visual design and" desc2="Operations." twitter="#" linkedIn="#" />
                        <TeamMember img={rex} names="Rex" post="Backend" desc="Programmer and problem solver;" desc2="Pursues passion." twitter="#" linkedIn="#" github="#" />
                        <TeamMember img={abhirup} names="Abhirup" post="Frontend" desc="Programmer and problem solver;" desc2="Pursues passion." twitter="#" linkedIn="#" github="#" />
                        <TeamMember img={vaish} names="Vaishnavi" post="Design" desc="Creating meaningful" desc2="experiences through design." linkedIn="#" behance="#" />
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default Teams;