
import MainStyles from "./DashboardStyles/pricingStyles.module.css";
import success from "../../resources/images/dashboard/success.gif";
import failed from "../../resources/images/dashboard/failed.gif";

const SuccessNoti = (props) => {
    
    return ( 
        <div className="modal" style={{ display: "block", backdropFilter: "blur(4px)" }}>
            <div className="modal-dialog modal-sm modal-dialog-centered" >
                <div className="modal-content" style={{ backgroundColor: "#111", borderRadius: "16px", border: "1px solid #f8f8f8" }}>
                    <div className="modal-body">
                        {
                            (props.payMessage === true)?
                            <div className={MainStyles.success_popup}>
                                <div className={MainStyles.payment_success_modal}>
                                    <img className="img-fluid" src={success} alt="Success Popup" />
                                </div>
                                <div className={MainStyles.payment_message}>Success</div>
                            </div>:
                            <div className={MainStyles.success_popup}>
                                <div className={MainStyles.payment_success_modal}>
                                    <img className="img-fluid" src={failed} alt="Failed Popup" />
                                </div>
                                <div className={MainStyles.payment_message}>Failed</div>
                            </div>

                        }
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default SuccessNoti;