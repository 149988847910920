import MainStyles from "../../resources/css/css.module.css";
const ProjCard = (props) => {
    return ( 
        <div className={MainStyles.proj_card}>
            <a href={props.linkTo} className={MainStyles.no_decor} target="_blank" rel="noreferrer">
                <img className="w-100" src={props.img} alt="Project Card" />
                <div className="text-center pt-3">
                    <p className={MainStyles.p_para_1}>{props.name}</p>
                </div>
            </a>
        </div>
     );
}
 
export default ProjCard;