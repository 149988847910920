import { Link } from "react-router-dom";
import styles from "./DashboardStyles/nftClaimOffer.module.css";
import Cross from "../../resources/images/circle-close.svg";


const NftNoClaim = ({setApplicable}) => {
    // const navigate = useNavigate();
    // const navigateToPricing = () => {
    //     setApplicable(false);
    //     navigate(`/pricing#pricing_section`);
    // }
    return (
        <div className="modal" style={{ display: "block", backdropFilter: "blur(8px)" }}>
            <div className="modal-dialog modal-lg modal-dialog-scrollable">
                <div className="modal-content" style={{ backgroundColor: "#202020", borderRadius: "16px" }}>
                    <div className="modal-body">
                        <div className={styles.dark_modal_style}>
                            <div className={styles.cross_container}>
                                <button onClick={() => setApplicable(false)}>
                                    <img src={Cross} alt="close" />
                                </button>
                            </div>
                            <div className={`pt-2 ${styles.claim_offer_modal_body}`}>
                                <div className={styles.offer_popup_heading}>
                                    No eligible NFTs
                                </div>
                                <div className={`pt-3 ${styles.offer_popup_subheading}`}>
                                    We did not find any NFT with an active offer in your wallet.<br />
                                    To view available offers, please check our <Link to={`${window.location.origin}/dashboard/pricing`} className={styles.orange_links}>pricing section</Link>.
                                </div>
                                <div style={{height:"16px"}}>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
     );
}
 
export default NftNoClaim;