import { Box, Container, Heading, UnorderedList, ListItem, Text } from '@chakra-ui/react';
import { useEffect } from "react";
import ReactGA from "react-ga4";



const Cancel = () => {
    useEffect(() => {
        document.title = 'Cancellation Policy - SHYFT';
        ReactGA.send({ hitType: "pageview", page: "/cancellations" });
    }, []);
    return ( 
        <>
            <Box bg={'brand.black'} minH={"55vh"}>
                <Container maxW={'7xl'} color={'brand.white'} py={'12'} px={8}>
                    <Heading as="h1" fontSize={{ base: '2xl', lg: '5xl' }}>
                        SHYFT - Cancellations
                    </Heading>

                    <Box as="div" py={{ base: '24px', lg: '32px' }} textAlign={'justify'}>
                        You can cancel your subscriptions at any time by contacting us or from Dashboard after login.
                        Your cancellation will take effect immediately once it is processed from our end.<br /> <br />To cancel your
                        subscription or If you have any questions regarding this Cancellation Policy, please contact us
                        via email at team@shyft.to.
                    </Box>
                </Container>
            </Box>
        </>
    );
}
 
export default Cancel;
