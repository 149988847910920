import MainStyles from "../../resources/css/css.module.css";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import { PopupButton } from "react-calendly";

import communityIcon from "../../resources/images/community-icons/comm-icon.svg";
import discord from "../../resources/images/community-icons/discord.svg";
import telegram from "../../resources/images/community-icons/telegram.svg";
import google from "../../resources/images/HeaderIcons/get-in-touch.svg";

import locations from '../../resources/images/community-icons/location.svg';
import writeToUs from '../../resources/images/community-icons/writeus.svg';


const Support = () => {

    useEffect(() => {
        document.title = 'Support - SHYFT';
        ReactGA.send({ hitType: "pageview", page: "/support" });
    }, []);

    return (
        <div className={`${MainStyles.dark_backdrop_gradient}`}>
            <div className={`${MainStyles.support_page}`}>
                <div className="container-lg">
                    <h1 className={MainStyles.main_headings}>Get Support</h1>
                    <div className={`row gx-5 pt-3 ${MainStyles.community_boxes}`}>
                        <div className="col-sm-12 col-md-6 pt-4 pb-3">
                            <div className={`${MainStyles.community_box}`}>
                                <div className={`d-flex ${MainStyles.icon_boxx}`}>
                                    <div className={MainStyles.icon}>
                                        <img src={communityIcon} alt="Connect on Discord" />
                                    </div>
                                    <div className={MainStyles.data}>Connect with Us</div>
                                </div>
                                <p className={MainStyles.p_para_1}>
                                    Get in touch with our discord and telegram community and get help from our developers who are
                                    always here to help you take off.
                                </p>
                                <div className="d-flex flex-wrap justify-content-center justify-content-md-start  pt-2">

                                    <div className="pe-3">
                                        <a href="https://discord.gg/8JyZCjRPmr" target="_blank" rel="noreferrer">
                                            <img src={discord} alt="Join Discord" style={{ width: "40px" }} />
                                        </a>
                                    </div>
                                    <div className="pe-3">
                                        <a href="https://t.me/+elnozTFe3OY0NDA1" target="_blank" rel="noreferrer">
                                            <img src={telegram} alt="Join Telegram" style={{ width: "40px" }} />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="pt-5">
                                <div className={`${MainStyles.community_box}`}>
                                    <div className={`d-flex ${MainStyles.icon_boxx}`}>
                                        <div className={MainStyles.icon}>
                                            <img src={google} alt="Calendly" />
                                        </div>
                                        <div className={MainStyles.data}>Get in Touch</div>
                                    </div>
                                    <p className={`${MainStyles.p_para_1} mb-4`}>
                                        We are always happy to discuss how our APIs can help you accelerate. Book a demo call at your convenience.
                                    </p>
                                    <div
                                        className={`text-center text-md-start pt-3 ${MainStyles.book_btn_1}`}
                                    >
                                        <PopupButton
                                            url="https://calendly.com/shyft-to"
                                            rootElement={document.getElementById("root")}
                                            text="Book A Call"
                                            className={MainStyles.btn_solid_grad}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-sm-12 col-md-6 pt-4 pb-3">
                            <div className={`pt-2 pt-md-0 ${MainStyles.community_box}`}>
                                <div className={`d-flex ${MainStyles.icon_boxx}`}>
                                    <div className={MainStyles.icon}>
                                        <img src={locations} alt="Our Address" />
                                    </div>
                                    <div className={MainStyles.data}>Our Location</div>
                                </div>
                                <p className={MainStyles.p_para_1}>
                                    <b>SUPER SAIYAN LABS PRIVATE LIMITED</b><br />
                                    Flat No. D-23, Zonasha Vista Apartment, Haralur, Varthur Bangalore Bangalore KA 560103 IN<br /><br />
                                    Call Us: <a href="tel:+919742070092" className={MainStyles.blue_links}>+91 9742070092</a>
                                </p>
                                
                            </div>
                            <div className="pt-4 pt-md-5">
                                <div className={`${MainStyles.community_box}`}>
                                    <div className={`d-flex ${MainStyles.icon_boxx}`}>
                                        <div className={MainStyles.icon}>
                                            <img src={writeToUs} alt="Calendly" />
                                        </div>
                                        <div className={MainStyles.data}>Write to Us</div>
                                    </div>
                                    <p className={`${MainStyles.p_para_1} mb-4`}>
                                        We would love to hear from you. Feel free to drop your queries, requirements or feedback that would help us to improve
                                        our product for you at <a href="mailto:team@shyft.to" className={MainStyles.blue_links}>team@shyft.to</a>
                                    </p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Support;