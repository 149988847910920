import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { ReactSession } from "react-client-session";
import ReactGA from "react-ga4";

import MainStyles from "../Dashboard/DashboardStyles/showCardDetailsStyles.module.css";
import APIKey from "../../resources/images/get-api-key-2.png";
// import CheckEmail from "./CheckEmail";
import Loading from "../common/Loading";

const UserDetails = () => {
    const navigate = useNavigate();
    const [formSuccess, setFormSuccess] = useState(false);
    const [messg, setMessg] = useState("");
    const [loading, setLoading] = useState(false);

    const [name, setName] = useState("");
    const [errname, setErrName] = useState("");
    // const [emails,setEmails] = useState("");
    const [teamSize, setTeamSize] = useState("");
    const [projName, setProjName] = useState("");
    const [errproj, setErrProj] = useState("");
    const [buildingWhat, setBuildingWhat] = useState("");

    const [telegram, setTelegram] = useState("");
    const [linkedin, setLinkedIn] = useState("");
    const [linkedInErr,setLinkedInErr] = useState("");

    useEffect(() => {
        document.title = 'Your Details - SHYFT';
        ReactGA.send({ hitType: "pageview", page: "/user-details" });
        
        try {
            const dateNow = new Date().toISOString();
            ReactSession.set("detCheckTime", dateNow);
        }
        catch (err) {
            console.error(err);
        }
        const LoginToken = ReactSession.get("xTkn") ?? "";
        if(LoginToken === "")
        {
            navigate('/');
        }

    }, []);

    //email Validator
    const validateEmail = (email) => {
        // eslint-disable-next-line
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };
    function isValidUrl(string) {
        try {
            new URL(string);
            return true;
        } catch (err) {
            return false;
        }
    }
    function sanitizeString(str){
        str = str.replace(/[^a-z0-9áéíóúñü \.,_-]/gim,"");
        return str.trim();
    }

    const saveData = () => {
        let dataToSend = {};
        let social_links = {};
        let socials_shared = false;
        //let err_occ = false;
        let make_api_call = false;
        setMessg("");
        setErrName("");
        setErrProj("");
        setLinkedInErr("");
        if (name.length > 2 && name.length < 20) {
            dataToSend = {
                ...dataToSend,
                name: sanitizeString(name)
            }
            make_api_call = true;
        }
        if (projName.length > 2 && projName.length < 20) {
            dataToSend = {
                ...dataToSend,
                project_name: sanitizeString(projName)
            }
            make_api_call = true;
        }
        if (teamSize !== "") {
            dataToSend = {
                ...dataToSend,
                team_size: teamSize
            }
            make_api_call = true;
        }
        if (buildingWhat !== "") {
            dataToSend = {
                ...dataToSend,
                project_info: buildingWhat
            }
            make_api_call = true;
        }
        if (telegram !== "") {
            social_links = {
                ...social_links,
                telegram: telegram
            }
            socials_shared = true;
        }
        if (linkedin !== "") {
            if(isValidUrl(linkedin))
            {
                social_links = {
                    ...social_links,
                    linkedin: linkedin
                }
                socials_shared = true;
            }
            else
            {
                setLinkedInErr("Invalid URL")
            }
            
        }
        if (socials_shared === true) {
            dataToSend = {
                ...dataToSend,
                social_links: social_links
            }
            make_api_call = true;
        }

        // if(emails === "")
        // {
        //     err_occ = true;
        //     setMessg("Please Enter a Valid Email Address");
        // }
        // if(!validateEmail(emails))
        // {
        //     err_occ = true;
        //     setMessg("Please Enter a Valid Email Address");
        // }

        //ready for the API call
        if (make_api_call === true) {
            // dataToSend = {
            //     ...dataToSend,
            //     // email:  emails
            // }
            //console.log("Calling Function");
            const token = ReactSession.get("xTkn") ?? "";
            const endPoint = process.env.REACT_APP_URL_EP;
            let rqUrl = `${endPoint}auth/profile`;
            setLoading(true);
            axios({
                // Endpoint to get NFTs
                url: rqUrl,
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "ngrok-skip-browser-warning": "69420",
                    'Authorization': `Bearer ${token}`,
                },
                data: dataToSend
            })
                // Handle the response from backend here
                .then((res) => {
                    setLoading(false);
                    //console.log(res.data);
                    if (res.data.success === true) {
                        navigate('/dashboard/overview');
                        // ReactSession.set("UserAP",res.data.result.api_key);
                        // setFormSuccess(true);
                    }
                    else {
                        // setFormSuccess(false); 
                        setMessg("Please provide details");
                    }
                })
                // Catch errors if any
                .catch((err) => {
                    console.warn(err);
                    setMessg("Failed! Some error occured");
                    setLoading(false);
                });
        }
        else
        {
            setMessg("Cannot submit without any valid details!");
        }

    }
    const closer = () => {
        setFormSuccess(false);
        navigate('/dashboard/overview');
    }

    return (
        <div className={`${MainStyles.dark_backdrop_gradient}`}>
            {loading && <Loading />}
            {/* <Cardprofile /> */}
            {/* {formSuccess && <CheckEmail closer={closer}/>} */}
            <div className={`${MainStyles.get_api_page}`}>
                <div className="container-lg">
                    <div className="row g-5">
                        <div className="col-sm-12 col-md-6">
                            <div className={MainStyles.text_form_container}>
                                <h1 className={MainStyles.main_headings_details}>
                                    Your Details
                                </h1>
                                <div>
                                    <p className={MainStyles.p_para_1_details}>
                                        Help us assist you better by filling in these details.
                                    </p>
                                </div>
                                <div>
                                    <div className={`${MainStyles.img_container} ${MainStyles.show_small}`}>
                                        <img src={APIKey} alt="Get API key to use SHYFT APIs" className="img-fluid" />
                                    </div>

                                </div>

                                <div className={MainStyles.the_form}>
                                    <div className={`${MainStyles.dark_form_field} ${MainStyles.in_user_form}`}>
                                        <label>Name</label>
                                        <div className={MainStyles.dark_form_group}>
                                            <div>
                                                <input type="text" value={name} onChange={(e) => {
                                                    if (e.target.value.length < 3)
                                                        setErrName("Name must be empty or greater than 3 characters");
                                                    else
                                                        setErrName("");
                                                    setName(e.target.value)
                                                }
                                                }
                                                    className="form-control" placeholder="Enter name" maxLength={20} />
                                            </div>
                                        </div>
                                        <small className="text-warning" style={{ fontFamily: "Poppins" }}>{errname}</small>
                                    </div>

                                    <div className={`${MainStyles.dark_form_field}`}>
                                        <label>Team Size</label>
                                        <div className={MainStyles.dark_form_group}>
                                            <div className="d-flex">
                                                <select className="form-control" onClick={(e) => setTeamSize(e.target.value)}>
                                                    <option className="bg-dark" value="">Select</option>
                                                    <option className="bg-dark" value="1 - 10">1 - 10</option>
                                                    <option className="bg-dark" value="11 - 50">11 - 50</option>
                                                    <option className="bg-dark" value="Above 50">Above 50</option>
                                                </select>
                                                <div className={MainStyles.down_icon}>
                                                    <i className="fa fa-chevron-down" style={{ fontSize: "20px", color: "#fff" }}></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={`${MainStyles.dark_form_field} ${MainStyles.in_user_form}`}>
                                        <label>Organization/Projects</label>
                                        <div className={MainStyles.dark_form_group}>
                                            <div>
                                                <input type="text" className="form-control" value={projName}
                                                    onChange={(e) => {
                                                        if (e.target.value.length < 3)
                                                            setErrProj("Org/Project must be empty or greater than 3 characters");
                                                        else
                                                            setErrProj("");
                                                        setProjName(e.target.value)
                                                    }
                                                    }
                                                    placeholder="Enter company's name/project's name" maxLength={40} />
                                            </div>
                                        </div>
                                        <small className="text-danger" style={{ fontFamily: "Poppins" }}>{errproj}</small>
                                    </div>
                                    <div className={MainStyles.dark_form_field}>
                                        <label>What are you building with SHYFT?</label>
                                        <div className={MainStyles.dark_form_group}>
                                            <div className="d-flex">
                                                <select className="form-control" onChange={(e) => setBuildingWhat(e.target.value)}>
                                                    <option className="bg-dark">ART</option>
                                                    <option className="bg-dark">Marketplace</option>
                                                    <option className="bg-dark">Candy Machine</option>
                                                    <option className="bg-dark">NFT dApps</option>
                                                    <option className="bg-dark">Web3 Game</option>
                                                    <option className="bg-dark">Analytics Dashboard</option>
                                                    <option className="bg-dark">Profile Pages</option>
                                                    <option className="bg-dark">Utility Tokens</option>
                                                    <option className="bg-dark">Something else</option>
                                                </select>
                                                <div className={MainStyles.down_icon}>
                                                    <i className="fa fa-chevron-down" style={{ fontSize: "20px", color: "#fff" }}></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={MainStyles.dark_form_field}>
                                        <div className="row">
                                            <div className="col-6">
                                                <label>LinkedIn</label>
                                                <div className={MainStyles.dark_form_group}>
                                                    <div>
                                                        <input type="text" className="form-control" value={linkedin}
                                                            onChange={(e) => {
                                                                setLinkedInErr("")
                                                                setLinkedIn(e.target.value)
                                                                }
                                                            }
                                                            placeholder="LinkedIn URL" />
                                                    </div>
                                                </div>
                                                <small className="text-danger" style={{ fontFamily: "Poppins" }}>{linkedInErr}</small>
                                            </div>
                                            <div className="col-6">
                                                <label>Telegram</label>
                                                <div className={MainStyles.dark_form_group}>
                                                    <div>
                                                        <input type="text" className="form-control" value={telegram}
                                                            onChange={(e) => {
                                                                // if (e.target.value.length<3)
                                                                //     setErrProj("Org/Project must be empty or greater than 3 characters");
                                                                // else 
                                                                //     setErrProj("");
                                                                setTelegram(e.target.value)
                                                            }
                                                            }
                                                            placeholder="Username" />
                                                    </div>
                                                </div>
                                                <small className="text-danger" style={{ fontFamily: "Poppins" }}></small>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="d-flex pt-4">
                                        <div className="pe-4 flex-fill">
                                            <button className={`w-100 ${MainStyles.btn_solid_grad}`} onClick={saveData}>Submit</button>
                                        </div>
                                        <div className="flex-fill">
                                            <Link
                                                to="/dashboard/overview"
                                                className={`w-100 ${MainStyles.btn_outline_gradient_button}`}
                                            >
                                                <div className={MainStyles.inner_white_area}>
                                                    Skip
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`pt-3 text-danger ${MainStyles.p_para_2}`}>
                                {messg}
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className={` ${MainStyles.show_large}`}>
                                <div className={`${MainStyles.img_container}`}>
                                    <img src={APIKey} alt="Get API key to use SHYFT APIs" className="img-fluid" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default UserDetails;