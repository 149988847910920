import {
  Box,
  chakra,
  Container,
  SimpleGrid,
  Stack,
  Text,
  VisuallyHidden,
  Link,
  Image,
  Flex
} from '@chakra-ui/react'
import { FaGithub, FaTwitter, FaLinkedinIn, FaDiscord, FaTelegramPlane,FaYoutube } from 'react-icons/fa'
import { FaMedium } from 'react-icons/fa6'

const SocialButton = ({
  children,
  label,
  href,
}) => {
  return (
    <chakra.button
      bg={"brand.white"}
      color={"black"}
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}
      href={href}
      target='_blank'
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: "brand.grey",
      }}>
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  )
}

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={'400'} fontSize={'lg'} mb={2} fontFamily={"heading"}>
      {children}
    </Text>
  )
}

export default function Footer() {
  return (
    <Box
      bg={"black"}
      color={"brand.white"}>
      <Container as={Stack} maxW={'7xl'} py={16}>
        <SimpleGrid
          templateColumns={{ sm: '1fr 1fr', md: '4fr 1fr 1fr' }}
          spacing={8}>
          <Stack spacing={6}>
            <Box pt={2}>
              <Link href='/'>
                <Image src="/shyft_logo.svg" alt='Shyft Logo' maxW={"200px"}/>
              </Link>
            </Box>
            <Text color={"brand.grey"} fontSize={{base: "xs", md: "sm"}}>Get in touch with our discord community and keep up with the latest feature<br /> releases. Get help from our developers who are always here to help you take off.</Text>
            <Stack direction={'row'} spacing={3}>
            <SocialButton label={'Github'} href={'https://github.com/Shyft-to'}>
                <FaGithub size={30} fill='brand.black'/>
              </SocialButton>
              <SocialButton label={'Twitter'} href={'https://twitter.com/shyft_to'}>
                <FaTwitter size={20} fill='brand.black' />
              </SocialButton>
              <SocialButton label={'Linked in'} href={'https://www.linkedin.com/company/shyft-to/'}>
                <FaLinkedinIn size={20} fill='brand.black' />
              </SocialButton>
              <SocialButton label={'Discord'} href={'https://discord.gg/8JyZCjRPmr'}>
                <FaDiscord size={20} fill='brand.black' />
              </SocialButton>
              <SocialButton label={'Telegram'} href={'https://t.me/+elnozTFe3OY0NDA1'}>
                <FaTelegramPlane size={20} fill='brand.black' />
              </SocialButton>
              <SocialButton label={'Blogs'} href={'https://medium.com/shyft-to'}>
                <FaMedium size={20} fill='brand.black' />
              </SocialButton>
              <SocialButton label={'Blogs'} href={'https://www.youtube.com/@Shyft-to'}>
                <FaYoutube size={20} fill='brand.black' />
              </SocialButton>
            </Stack>
          </Stack>
          
          <Stack align={'flex-start'}>
            <ListHeader>Products</ListHeader>
            <Box as="a" href={'https://docs.shyft.to/start-hacking/nft'} target='_blank' _hover={{color:"brand.red"}}>
              NFT APIs
            </Box>
            <Box as="a" href={'https://docs.shyft.to/start-hacking/nft-marketplace'} target='_blank' _hover={{color:"brand.red"}}>
                Marketplace APIs
            </Box>
            <Box as="a" href={'https://docs.shyft.to/start-hacking/fungible-tokens'} target='_blank' _hover={{color:"brand.red"}}>
                Token APIs
            </Box>
            <Box as="a" href={'https://docs.shyft.to/start-hacking/wallet'} target='_blank' _hover={{color:"brand.red"}}>
                Wallet APIs
            </Box>
          </Stack>
          <Stack align={'flex-start'}>
            <ListHeader>For Developers</ListHeader>
            <Box as="a" href={'https://docs.shyft.to/'} target='_blank' _hover={{color:"brand.red"}}>
              Documentation
            </Box>
            <Box as="a" href={'https://discord.gg/8JyZCjRPmr'} target='_blank' _hover={{color:"brand.red"}}>
              Join Discord
            </Box>
            <Box as="a" href={'https://medium.com/shyft-to'} target='_blank' _hover={{color:"brand.red"}}>
              Blogs
            </Box>
            <Box as="a" href={'https://twitter.com/shyft_to'} target='_blank' _hover={{color:"brand.red"}}>
              Announcements
            </Box>
          </Stack>
        </SimpleGrid>
        <Flex justifyContent={"space-between"} pt={8}>
            <Box as="div">
                <Text as={"a"} href='mailto:genesis@shyft.to' _hover={{color:"brand.red"}}>Contact: genesis@shyft.to</Text>
            </Box>
            <Box as="div">
                <Text as={"a"} href='/terms' _hover={{color:"brand.red"}}>Terms & Conditions</Text> |  
                <Text as={"a"} href='/privacy-policy' _hover={{color:"brand.red"}}> Privacy Policy</Text>
            </Box>
        </Flex>
      </Container>
    </Box>
  )
}