import Typewriter from "typewriter-effect";
import MainStyles from "./DashboardStyles/eligibleLoading.module.css";
// import LoadingData from "../../resources/images/landing-small-icons/loading-data-2.gif";
import SyncLoader from "react-spinners/SyncLoader";

const EligibleLoading = () => {
  return (
    <div
      className="modal"
      style={{ display: "block", backdropFilter: "blur(4px)" }}
    >
      <div
        className="modal-dialog modal-sm modal-dialog-centered"
        style={{ zIndex: "110",width:"250px",margin: "0 auto" }}
      >
        <div
          className="modal-content"
          style={{
            backgroundColor: "#000",
            borderRadius: "16px",
            border: "1px solid #f8f8f8",
            height: "160px"
          }}
        >
          <div className="modal-body">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "32px",
                marginTop: "32px",
              }}
            >
              <SyncLoader color="#f4f4f4" />
            </div>
            <div className={MainStyles.type_text}>
              <Typewriter
                options={{
                  strings: [
                    "Verifying ownership",
                    "Making inter-galactic calls",
                    "Downloading info at lightspeed",
                  ],
                  autoStart: true,
                  loop: true,
                  pauseFor: 3500,
                  delay: 1,
                  deleteSpeed: 1,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EligibleLoading;
