import { useEffect } from "react";

import MainStyles from "../../resources/css/css.module.css";

const Credits = (props) => {
    const dataWallet = [
        {
            "endpoint": "/sol/v1/wallet/balance",
            "type": "GET",
            "credits": 4,
            "category": "wallet"
        },
        {
            "endpoint": "/sol/v1/wallet/send_sol",
            "type": "POST",
            "credits": 60,
            "category": "wallet"
        },
        {
            "endpoint": "/sol/v1/wallet/token_balance",
            "type": "GET",
            "credits": 6,
            "category": "wallet"
        },
        {
            "endpoint": "/sol/v1/wallet/get_portfolio",
            "type": "GET",
            "credits": 30,
            "category": "wallet"
        },
        {
            "endpoint": "/sol/v1/wallet/all_tokens",
            "type": "GET",
            "credits": 20,
            "category": "wallet"
        },
        {
            "endpoint": "/sol/v1/wallet/get_domains",
            "type": "GET",
            "credits": 20,
            "category": "wallet"
        },
        {
            "endpoint": "/sol/v1/wallet/resolve_address",
            "type": "GET",
            "credits": 30,
            "category": "wallet"
        },
        {
            "endpoint": "/sol/v1/wallet/transaction_history",
            "type": "GET",
            "credits": 50,
            "category": "wallet"
        },
        {
            "endpoint": "/sol/v1/wallet/transaction",
            "type": "GET",
            "credits": 10,
            "category": "wallet"
        },
        {
            "endpoint": "/sol/v1/wallet/create_semi_wallet",
            "type": "POST",
            "credits": 80,
            "category": "wallet"
        },
        {
            "endpoint": "/sol/v1/wallet/decrypt_semi_wallet",
            "type": "GET",
            "credits": 50,
            "category": "wallet"
        },
        {
            "endpoint": "/sol/v1/wallet/get_keypair",
            "type": "GET",
            "credits": 20,
            "category": "wallet"
        },
        {
            "endpoint": "/sol/v1/wallet/collections",
            "type": "GET",
            "credits": 40,
            "category": "wallet"
        },
        {
            "endpoint": "/sol/v1/wallet/send_sol_detach",
            "type": "POST",
            "credits": 60,
            "category": "wallet"
        },


    ];
    const dataSemi = [
        {
            "endpoint": "/sol/v1/semi_wallet/create",
            "type": "POST",
            "credits": 80,
            "category": "semiwallet"
        },
        {
            "endpoint": "/sol/v1/semi_wallet/decrypt",
            "type": "GET",
            "credits": 50,
            "category": "semiwallet"
        },
        {
            "endpoint": "/sol/v1/semi_wallet/get_keypair",
            "type": "GET",
            "credits": 20,
            "category": "semiwallet"
        },
        {
            "endpoint": "/sol/v1/semi_wallet/change_password",
            "type": "POST",
            "credits": 100,
            "category": "semiwallet"
        }
    ];
    const dataToken =
        [
            {
                "endpoint": "/sol/v1/token/create_detach",
                "type": "POST",
                "credits": 80,
                "category": "token"
            },
            {
                "endpoint": "/sol/v1/token/burn",
                "type": "DELETE",
                "credits": 80,
                "category": "token"
            },
            {
                "endpoint": "/sol/v1/token/burn_detach",
                "type": "DELETE",
                "credits": 80,
                "category": "token"
            },
            {
                "endpoint": "/sol/v1/token/mint",
                "type": "POST",
                "credits": 80,
                "category": "token"
            },
            {
                "endpoint": "/sol/v1/token/mint_detach",
                "type": "POST",
                "credits": 80,
                "category": "token"
            },
            {
                "endpoint": "/sol/v1/token/get_info",
                "type": "GET",
                "credits": 60,
                "category": "token"
            },
            {
                "endpoint": "/sol/v1/token/transfer",
                "type": "POST",
                "credits": 40,
                "category": "token"
            },
            {
                "endpoint": "/sol/v1/token/tansfer_detach",
                "type": "POST",
                "credits": 80,
                "category": "token"
            },
            {
                "endpoint": "/sol/v1/token/airdrop",
                "type": "POST",
                "credits": 400,
                "category": "token"
            },
            {
                "endpoint": "/sol/v1/token/get_owners",
                "type": "GET",
                "credits": 200,
                "category": "token"
            }
        ];
    const datanft =
        [
            {
                "endpoint": "/sol/v1/nft/create",
                "type": "POST",
                "credits": "80",
                "category": "nft"
            },
            {
                "endpoint": "/sol/v1/nft/create_detach",
                "type": "POST",
                "credits": "80",
                "category": "nft"
            },
            {
                "endpoint": "/sol/v2/nft/create",
                "type": "POST",
                "credits": "80",
                "category": "nft"
            },
            {
                "endpoint": "/sol/v1/nft/create_from_metadata",
                "type": "POST",
                "credits": "60",
                "category": "nft"
            },
            {
                "endpoint": "/sol/v1/nft/read_all",
                "type": "GET",
                "credits": "40",
                "category": "nft"
            },
            {
                "endpoint": "/sol/v1/nft/read",
                "type": "GET",
                "credits": "10",
                "category": "nft"
            },
            {
                "endpoint": "/sol/v1/nft/burn",
                "type": "DELETE",
                "credits": "80",
                "category": "nft"
            },
            {
                "endpoint": "/sol/v1/nft/burn_detach",
                "type": "DELETE",
                "credits": "80",
                "category": "nft"
            },
            {
                "endpoint": "/sol/v1/nft/update",
                "type": "POST",
                "credits": "100",
                "category": "nft"
            },
            {
                "endpoint": "/sol/v1/nft/update_detach",
                "type": "POST",
                "credits": "100",
                "category": "nft"
            },
            {
                "endpoint": "/sol/v2/nft/update (Gasless)",
                "type": "POST",
                "credits": "100",
                "category": "nft"
            },
            {
                "endpoint": "/sol/v1/nft/update_metadata_uri",
                "type": "POST",
                "credits": "100",
                "category": "nft"
            },
            {
                "endpoint": "/sol/v1/nft/search/attributes",
                "type": "GET",
                "credits": "20",
                "category": "nft"
            },
            {
                "endpoint": "/sol/v1/nft/search",
                "type": "GET",
                "credits": "20",
                "category": "nft"
            },
            {
                "endpoint": "/sol/v1/nft/transfer",
                "type": "POST",
                "credits": "80",
                "category": "nft"
            },
            {
                "endpoint": "/sol/v1/nft/transfer_detach",
                "type": "POST",
                "credits": "80",
                "category": "nft"
            },
            {
                "endpoint": "/sol/v1/nft/transfer_many",
                "type": "POST",
                "credits": "100",
                "category": "nft"
            },
            {
                "endpoint": "/sol/v1/nft/mint",
                "type": "POST",
                "credits": "80",
                "category": "nft"
            },
            {
                "endpoint": "/sol/v1/nft/mint_detach",
                "type": "POST",
                "credits": "80",
                "category": "nft"
            },
            {
                "endpoint": "/sol/v1/nft/get_owners",
                "type": "GET",
                "credits": "40",
                "category": "nft"
            }
        ];

    const datamp =
        [
            {
                "endpoint": "/sol/v1/marketplace/treasury_balance",
                "type": "GET",
                "credits": "40",
                "category": "marketplace"
            },
            {
                "endpoint": "/sol/v1/marketplace/my_markets",
                "type": "GET",
                "credits": "20",
                "category": "marketplace"
            },
            {
                "endpoint": "/sol/v1/marketplace/find",
                "type": "GET",
                "credits": "40",
                "category": "marketplace"
            },
            {
                "endpoint": "/sol/v1/marketplace/list_details",
                "type": "GET",
                "credits": "20",
                "category": "marketplace"
            },
            {
                "endpoint": "/sol/v1/marketplace/active_listings",
                "type": "GET",
                "credits": "60",
                "category": "marketplace"
            },
            {
                "endpoint": "/sol/v1/marketplace/active_sellers",
                "type": "GET",
                "credits": "20",
                "category": "marketplace"
            },
            {
                "endpoint": "/sol/v1/marketplace/seller_listings",
                "type": "GET",
                "credits": "20",
                "category": "marketplace"
            },
            {
                "endpoint": "/sol/v1/marketplace/buy_history",
                "type": "GET",
                "credits": "40",
                "category": "marketplace"
            },
            {
                "endpoint": "/sol/v1/marketplace/stats",
                "type": "GET",
                "credits": "20",
                "category": "marketplace"
            },
            {
                "endpoint": "/sol/v1/marketplace/create",
                "type": "POST",
                "credits": "80",
                "category": "marketplace"
            },
            {
                "endpoint": "/sol/v1/marketplace/update",
                "type": "POST",
                "credits": "100",
                "category": "marketplace"
            },
            {
                "endpoint": "/sol/v1/marketplace/list",
                "type": "GET",
                "credits": "80",
                "category": "marketplace"
            },
            {
                "endpoint": "/sol/v1/marketplace/list_gasless",
                "type": "GET",
                "credits": "90",
                "category": "marketplace"
            },
            {
                "endpoint": "/sol/v1/marketplace/buy",
                "type": "POST",
                "credits": "80",
                "category": "marketplace"
            },
            {
                "endpoint": "/sol/v1/marketplace/unlist",
                "type": "POST",
                "credits": "80",
                "category": "marketplace"
            },
            {
                "endpoint": "/sol/v1/marketplace/withdraw_fee",
                "type": "POST",
                "credits": "80",
                "category": "marketplace"
            }
        ];
    const datacandy =
        [
            {
                "endpoint": "/sol/v1/candy_machine/nft_addresses",
                "type": "GET",
                "credits": "20",
                "category": "candymachine"
            },
            {
                "endpoint": "/sol/v1/candy_machine/nfts",
                "type": "GET",
                "credits": "40",
                "category": "candymachine"
            },
        ];
    const datastorage =
        [
            {
                "endpoint": "/sol/v1/storage/upload",
                "type": "POST",
                "credits": "60",
                "category": "storage"
            },
        ];
    const datacollections = [
        {
            "endpoint": "/sol/v1/collections/get_nfts",
            "type": "GET",
            "credits": "60",
            "category": "collection"
        },
    ];
    useEffect(() => {
        props.setLoading(false);
    }, [props.isLoading]);

    return (
        <div>
            <div className="container-fluid">
                <div className={MainStyles.credit_used}>
                    <div className="container-lg">
                        <h2 className={MainStyles.dashboard_main_title}>API Credits</h2>
                        <div className={MainStyles.each_table}>
                            <h4 className={MainStyles.table_title}>
                                Wallet APIs
                            </h4>
                            <div className={MainStyles.table_head}>
                                <div className="row">
                                    <div className="col-5 col-md-6">
                                        APIs
                                    </div>
                                    <div className="col-4 col-md-3 text-center">
                                        Type
                                    </div>
                                    <div className="col-3 col-md-3 text-center">
                                        Credits
                                    </div>
                                </div>
                            </div>
                            {dataWallet.map((record) => (
                                <div className={MainStyles.table_body}>
                                    <div className="row">
                                        <div className="col-5 col-md-6">
                                            {record.endpoint}
                                        </div>
                                        <div className="col-4 col-md-3 text-center">
                                            {(record.type === 'GET') ? <span className={MainStyles.color_get}>{record.type}</span> : ""}
                                            {(record.type === 'POST') ? <span className={MainStyles.color_post}>{record.type}</span> : ""}
                                            {(record.type === 'DELETE') ? <span className={MainStyles.color_delete}>{record.type}</span> : ""}
                                        </div>
                                        <div className="col-3 col-md-3 text-center">
                                            {record.credits}
                                        </div>
                                    </div>
                                </div>
                            )
                            )
                            }
                        </div>
                        <div className={MainStyles.each_table}>
                            <h4 className={MainStyles.table_title}>
                                Semi-custodial Wallet APIs
                            </h4>
                            <div className={MainStyles.table_head}>
                                <div className="row">
                                    <div className="col-5 col-md-6">
                                        APIs
                                    </div>
                                    <div className="col-4 col-md-3 text-center">
                                        Type
                                    </div>
                                    <div className="col-3 col-md-3 text-center">
                                        Credits
                                    </div>
                                </div>
                            </div>
                            {dataSemi.map((record) => (
                                <div className={MainStyles.table_body}>
                                    <div className="row">
                                        <div className="col-5 col-md-6">
                                            {record.endpoint}
                                        </div>
                                        <div className="col-4 col-md-3 text-center">
                                            {(record.type === 'GET') ? <span className={MainStyles.color_get}>{record.type}</span> : ""}
                                            {(record.type === 'POST') ? <span className={MainStyles.color_post}>{record.type}</span> : ""}
                                            {(record.type === 'DELETE') ? <span className={MainStyles.color_delete}>{record.type}</span> : ""}
                                        </div>
                                        <div className="col-3 col-md-3 text-center">
                                            {record.credits}
                                        </div>
                                    </div>
                                </div>
                            )
                            )
                            }

                        </div>
                        <div className={MainStyles.each_table}>
                            <h4 className={MainStyles.table_title}>
                                Token APIs
                            </h4>
                            <div className={MainStyles.table_head}>
                                <div className="row">
                                    <div className="col-5 col-md-6">
                                        APIs
                                    </div>
                                    <div className="col-4 col-md-3 text-center">
                                        Type
                                    </div>
                                    <div className="col-3 col-md-3 text-center">
                                        Credits
                                    </div>
                                </div>
                            </div>
                            {dataToken.map((record) => (
                                <div className={MainStyles.table_body}>
                                    <div className="row">
                                        <div className="col-5 col-md-6">
                                            {record.endpoint}
                                        </div>
                                        <div className="col-4 col-md-3 text-center">
                                            {(record.type === 'GET') ? <span className={MainStyles.color_get}>{record.type}</span> : ""}
                                            {(record.type === 'POST') ? <span className={MainStyles.color_post}>{record.type}</span> : ""}
                                            {(record.type === 'DELETE') ? <span className={MainStyles.color_delete}>{record.type}</span> : ""}
                                        </div>
                                        <div className="col-3 col-md-3 text-center">
                                            {record.credits}
                                        </div>
                                    </div>
                                </div>
                            )
                            )
                            }
                        </div>
                        <div className={MainStyles.each_table}>
                            <h4 className={MainStyles.table_title}>
                                NFT APIs
                            </h4>
                            <div className={MainStyles.table_head}>
                                <div className="row">
                                    <div className="col-5 col-md-6">
                                        APIs
                                    </div>
                                    <div className="col-4 col-md-3 text-center">
                                        Type
                                    </div>
                                    <div className="col-3 col-md-3 text-center">
                                        Credits
                                    </div>
                                </div>
                            </div>
                            {datanft.map((record) => (
                                <div className={MainStyles.table_body}>
                                    <div className="row">
                                        <div className="col-5 col-md-6">
                                            {record.endpoint}
                                        </div>
                                        <div className="col-4 col-md-3 text-center">
                                            {(record.type === 'GET') ? <span className={MainStyles.color_get}>{record.type}</span> : ""}
                                            {(record.type === 'POST') ? <span className={MainStyles.color_post}>{record.type}</span> : ""}
                                            {(record.type === 'DELETE') ? <span className={MainStyles.color_delete}>{record.type}</span> : ""}
                                        </div>
                                        <div className="col-3 col-md-3 text-center">
                                            {record.credits}
                                        </div>
                                    </div>
                                </div>
                            )
                            )
                            }
                        </div>
                        <div className={MainStyles.each_table}>
                            <h4 className={MainStyles.table_title}>
                                Storage API
                            </h4>
                            <div className={MainStyles.table_head}>
                                <div className="row">
                                    <div className="col-5 col-md-6">
                                        APIs
                                    </div>
                                    <div className="col-4 col-md-3 text-center">
                                        Type
                                    </div>
                                    <div className="col-3 col-md-3 text-center">
                                        Credits
                                    </div>
                                </div>
                            </div>
                            {datastorage.map((record) => (
                                <div className={MainStyles.table_body}>
                                    <div className="row">
                                        <div className="col-5 col-md-6">
                                            {record.endpoint}
                                        </div>
                                        <div className="col-4 col-md-3 text-center">
                                            {(record.type === 'GET') ? <span className={MainStyles.color_get}>{record.type}</span> : ""}
                                            {(record.type === 'POST') ? <span className={MainStyles.color_post}>{record.type}</span> : ""}
                                            {(record.type === 'DELETE') ? <span className={MainStyles.color_delete}>{record.type}</span> : ""}
                                        </div>
                                        <div className="col-3 col-md-3 text-center">
                                            {record.credits}
                                        </div>
                                    </div>
                                </div>
                            )
                            )
                            }
                        </div>
                        <div className={MainStyles.each_table}>
                            <h4 className={MainStyles.table_title}>
                                Candy Machine APIs
                            </h4>
                            <div className={MainStyles.table_head}>
                                <div className="row">
                                    <div className="col-5 col-md-6">
                                        APIs
                                    </div>
                                    <div className="col-4 col-md-3 text-center">
                                        Type
                                    </div>
                                    <div className="col-3 col-md-3 text-center">
                                        Credits
                                    </div>
                                </div>
                            </div>
                            {datacandy.map((record) => (
                                <div className={MainStyles.table_body}>
                                    <div className="row">
                                        <div className="col-5 col-md-6">
                                            {record.endpoint}
                                        </div>
                                        <div className="col-4 col-md-3 text-center">
                                            {(record.type === 'GET') ? <span className={MainStyles.color_get}>{record.type}</span> : ""}
                                            {(record.type === 'POST') ? <span className={MainStyles.color_post}>{record.type}</span> : ""}
                                            {(record.type === 'DELETE') ? <span className={MainStyles.color_delete}>{record.type}</span> : ""}
                                        </div>
                                        <div className="col-3 col-md-3 text-center">
                                            {record.credits}
                                        </div>
                                    </div>
                                </div>
                            )
                            )
                            }
                        </div>
                        <div className={MainStyles.each_table}>
                            <h4 className={MainStyles.table_title}>
                                Marketplace APIs
                            </h4>
                            <div className={MainStyles.table_head}>
                                <div className="row">
                                    <div className="col-5 col-md-6">
                                        APIs
                                    </div>
                                    <div className="col-4 col-md-3 text-center">
                                        Type
                                    </div>
                                    <div className="col-3 col-md-3 text-center">
                                        Credits
                                    </div>
                                </div>
                            </div>
                            {datamp.map((record) => (
                                <div className={MainStyles.table_body}>
                                    <div className="row">
                                        <div className="col-5 col-md-6">
                                            {record.endpoint}
                                        </div>
                                        <div className="col-4 col-md-3 text-center">
                                            {(record.type === 'GET') ? <span className={MainStyles.color_get}>{record.type}</span> : ""}
                                            {(record.type === 'POST') ? <span className={MainStyles.color_post}>{record.type}</span> : ""}
                                            {(record.type === 'DELETE') ? <span className={MainStyles.color_delete}>{record.type}</span> : ""}
                                        </div>
                                        <div className="col-3 col-md-3 text-center">
                                            {record.credits}
                                        </div>
                                    </div>
                                </div>
                            )
                            )
                            }

                        </div>
                        <div className={MainStyles.each_table}>
                            <h4 className={MainStyles.table_title}>
                                Collection API
                            </h4>
                            <div className={MainStyles.table_head}>
                                <div className="row">
                                    <div className="col-5 col-md-6">
                                        APIs
                                    </div>
                                    <div className="col-4 col-md-3 text-center">
                                        Type
                                    </div>
                                    <div className="col-3 col-md-3 text-center">
                                        Credits
                                    </div>
                                </div>
                            </div>
                            {datacollections.map((record) => (
                                <div className={MainStyles.table_body}>
                                    <div className="row">
                                        <div className="col-5 col-md-6">
                                            {record.endpoint}
                                        </div>
                                        <div className="col-4 col-md-3 text-center">
                                            {(record.type === 'GET') ? <span className={MainStyles.color_get}>{record.type}</span> : ""}
                                            {(record.type === 'POST') ? <span className={MainStyles.color_post}>{record.type}</span> : ""}
                                            {(record.type === 'DELETE') ? <span className={MainStyles.color_delete}>{record.type}</span> : ""}
                                        </div>
                                        <div className="col-3 col-md-3 text-center">
                                            {record.credits}
                                        </div>
                                    </div>
                                </div>
                            )
                            )
                            }
                        </div>
                        <div className="pt-5 text-end">
                            <small className={MainStyles.p_para_2}>
                                These credit consumed/api call are subjected to change
                            </small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Credits;