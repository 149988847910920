import MainStyles from "../../resources/css/css.module.css";

import AccessToken from "../../resources/images/comm-proj-large/comm-access-token.png";
import FungToken from "../../resources/images/comm-proj-large/comm-fung.png";
import MarketToken from "../../resources/images/comm-proj-large/comm-NFT.png";
import ProjCard from "./ProjCard";


const ExperienceShyft = () => {
    return ( 
    <div className={`${MainStyles.dark_backdrop_gradient}`}>
        <div className={`${MainStyles.experience_page}`}>
            <div className="container-lg px-4">
                <h1 className={MainStyles.main_headings}>Experience SHYFT Sample Projects</h1>
                <div className="pt-3">
                    <p className={MainStyles.p_para_1}>
                        Shyft APIs can be used to create a wide range of NFT projects, ranging from small-scale applications such as performing CRUD operations on NFTs or FTs, to large-scale industry-driven applications such as a marketplace, or a storefront. Whatever may be your field, we have got you covered. Here are a few sample projects we have created for the community.
                    </p>
                </div>
            </div>
            <div className="container-lg pt-5">
                <div className="d-flex flex-wrap justify-content-center justify-content-lg-between">
                    <div className="px-md-3 py-3 py-md-5">
                        <ProjCard img={AccessToken} name="NFT as access token" linkTo="https://experience.shyft.to"/>
                    </div>
                    <div className="px-md-3 py-3 py-md-5">
                        <ProjCard img={FungToken} name="Fungible Tokens" linkTo="https://tokens.shyft.to"/>
                    </div>
                    <div className="px-md-3 py-3 py-md-5">
                        <ProjCard img={MarketToken} name="NFTs and Marketplace" linkTo="https://nfts.shyft.to"/>
                    </div>
                </div>
            </div>
        </div>
    </div> 
    );
}
 
export default ExperienceShyft;