import failed from "../../resources/images/dashboard/failed.gif";
const ErrorOccured = () => {
    return ( 
        <div className="modal" style={{ display: "block", backdropFilter: "blur(4px)" }}>
            <div className="modal-dialog modal-sm modal-dialog-centered" style={{zIndex: "110"}}>
                <div className="modal-content" style={{ backgroundColor: "#111", borderRadius: "16px", border: "2px solid #f8f8f8" }}>
                    <div className="modal-body">
                        <div className="offer_claimed_gif" style={{width:"220px",margin: "0 auto", display: "block"}}>
                            <img src={failed} alt="nft offer claimed" style={{display:"block",margin: "0 auto", width:"220px"}}/>
                        </div>
                        <center className="py-2" style={{font: "600 16px 'Poppins',sans-serif", color: "white"}}> Some Error Occured!</center>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default ErrorOccured;