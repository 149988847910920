// import googleIcon from "../../resources/images/login-auth/google_color.svg";
import { useState, useEffect } from "react";
import axios from "axios";
import Loading from "../common/Loading";
import { ReactSession } from "react-client-session";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Stack,
  Input,
  Heading,
  Checkbox,
  Image,
  Text,
  Button,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Flex,
  HStack,
  Container,
} from "@chakra-ui/react";
// import { FaGithub } from "react-icons/fa";
import { motion } from "framer-motion";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ReactGA from "react-ga4";

import { GoogleOAuthProvider } from "@react-oauth/google";

// import NavBarUser from "../common/Header/Header2";
import ReCAPTCHA from "react-google-recaptcha";
import NavBarUser from "../common/Header/Header2";
import GoogleLoginButton from "./GoogleLoginButton";
// import CheckEmailLink from "./CheckEmailLink";

const APIKeyForm = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const capCha = process.env.REACT_APP_CAPCHA_ID;
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [agree, setAgree] = useState(false);
  const [agreeErr, setAgreeErr] = useState("");
  const [showPop, setPop] = useState(false);

  const [disabled, setDisable] = useState(true);

  const [loading, setLoading] = useState(false);
  const [genMessg, setGenMessage] = useState("");

  const [thirdpartyLoginMessage, setPartyLoginMessage] = useState("");

  const [capchaToken,setCapchaToken] = useState("");

  useEffect(() => {
    const token = ReactSession.get("xTkn") ?? "";
    if (token !== "") {
      navigate("/dashboard/overview");
    }
  }, []);
  const onChange = (value) => {
    setCapchaToken(value);
    setDisable(false);
  };
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/get-api-key" });
    //ReactGA.pageview(window.location.pathname);
  }, []);

  //email Validator
  const validateEmail = (email) => {
    // eslint-disable-next-line
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const getTheKey = () => {
    let dataToSend = {};
    var err_occ = false;
    if (email === "") {
      err_occ = true;
      setEmailErr("Email Field cannot be empty");
    }
    if (!validateEmail(email)) {
      err_occ = true;
      setEmailErr("Please Enter a Valid Email Address");
    }
    if (agree === false) {
      err_occ = true;
      setAgreeErr("You must agree to the terms before proceeding");
    }

    if(capchaToken === ""){
      err_occ = true;
      setAgreeErr("Capcha verification failed");
    }
    if (err_occ === false) {
      dataToSend = {
        ...dataToSend,
        email: email,
        g_recaptcha_response: capchaToken
      };
      const endPoint = process.env.REACT_APP_AUTH_EP;
      let rqUrl = `${endPoint}auth/login`;
      setLoading(true);
      axios({
        // Endpoint to get NFTs
        url: rqUrl,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "ngrok-skip-browser-warning": "69420",
        },
        data: dataToSend,
      })
        // Handle the response from backend here
        .then((res) => {
          setLoading(false);
          console.log(res.data.success);
          if (res.data.success === true) {
            setGenMessage("Success");
            onOpen();
            setDisable(true);
          } else {
            setGenMessage("Failed");
          }
        })
        // Catch errors if any
        .catch((err) => {
          console.warn(err);
          setGenMessage("Some Error Occured! Please Try Again After Sometime");
          setLoading(false);
        });
    }
  };
  return (
    <>
      <Modal
        blockScrollOnMount={true}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent bg={"#111"}>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text
              as="div"
              fontSize={{ base: "lg", md: "xl" }}
              color={"brand.white"}
              textAlign={"center"}
            >
              Please check your email for the{" "}
              <Text as="span" color={"brand.red"} fontWeight={600}>
                login link
              </Text>
            </Text>
          </ModalBody>
          <ModalFooter textAlign={"center"}>
            <Button
              m={"0 auto"}
              onClick={onClose}
              color={"brand.dark"}
              bg={"brand.white"}
              _hover={{
                bg: "brand.black",
                color: "brand.white",
              }}
              px={"20px"}
              fontFamily={"heading"}
              fontSize={"12px"}
              lineHeight={"12px"}
              size={"sm"}
              borderRadius={"full"}
            >
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <NavBarUser />
      {loading && <Loading />}
      <Flex
        alignItems={{ base: "flex-start", md: "center" }}
        minHeight={{ base: "60vh", md: "80vh" }}
      >
        <Box w="full">
          <Stack
            as={"div"}
            width={"100%"}
            maxW={{ base: "lg", xl: "xl" }}
            margin={"0 auto"}
            px={{ base: 2, md: 0 }}
            pt={{ base: 16, md: 20 }}
            spacing={{ base: "8", lg: "16" }}
            mx={4}
          >
            <Heading
              as="h1"
              textAlign={"center"}
              fontSize={{ base: "3xl", md: "40px" }}
              color={"brand.white"}
              mb={0}
            >
              Get your Super Powers
            </Heading>
            <Stack as="form" spacing={{ base: "8", lg: "8" }}>
              <Stack>
                <Text
                  as={"span"}
                  fontSize={{ base: "13px", md: "15px" }}
                  color="brand.white"
                >
                  E-mail Address
                </Text>
                <Box>
                  <Input
                    type="email"
                    borderRadius={"full"}
                    borderWidth={2}
                    borderColor={"brand.red"}
                    _focus={{ borderColor: "brand.white" }}
                    py={{ base: "18px", md: "22px" }}
                    px={6}
                    color={"brand.white"}
                    fontSize={{ base: "12px", md: "15px" }}
                    lineHeight={{ base: "12px", md: "15px" }}
                    placeholder="Enter email address"
                    value={email}
                    onChange={(e) => {
                      if (e.target.value === "")
                        setEmailErr("This field cannot be empty");
                      else setEmailErr("");
                      setEmail(e.target.value);
                    }}
                  />
                </Box>
              </Stack>
              <Stack>
                <Box>
                  <Checkbox
                    color={"brand.white"}
                    onChange={(e) => {
                      setAgreeErr("");
                      setAgree(!agree);
                    }}
                  >
                    <Text as="span" fontSize={{ base: "13px", md: "15px" }}>
                      I agree to the{" "}
                      <Link
                        color={"brand.yellow"}
                        _hover={{ color: "brand.red" }}
                      >
                        Terms
                      </Link>{" "}
                      and{" "}
                      <Link
                        color={"brand.yellow"}
                        _hover={{ color: "brand.red" }}
                      >
                        Privacy Policy
                      </Link>
                    </Text>
                  </Checkbox>
                </Box>
              </Stack>
              <Box
                as="div"
                pt={2}
                transform={"scale(0.8)"}
                transformOrigin={"0% 0%"}
              >
                <ReCAPTCHA sitekey={capCha} onChange={onChange} />
              </Box>
              {disabled ? (
                <Button
                  borderRadius={"full"}
                  fontFamily={"heading"}
                  fontSize={{ base: "14px", md: "16px" }}
                  bg={"brand.red"}
                  color={"brand.white"}
                  py={{base:2, md: 6}}
                  _hover={{ bg: "brand.white", color: "brand.red" }}
                  opacity={"0.7"}
                >
                  Continue
                </Button>
              ) : (
                <Button
                  borderRadius={"full"}
                  fontFamily={"heading"}
                  fontSize={{ base: "14px", md: "16px" }}
                  bg={"brand.red"}
                  color={"brand.white"}
                  py={{base:2, md: 6}}
                  _hover={{ bg: "brand.white", color: "brand.red" }}
                  onClick={getTheKey}
                >
                  Continue
                </Button>
              )}
              <Box pt={{base: 2}}>
                <Text textAlign={"center"} fontFamily={"heading"} color={"brand.white"} fontWeight={700}> - OR - </Text>
              </Box>
              <GoogleLoginButton
                setPartyLoginMessage={setPartyLoginMessage}
                navigate={navigate}
                setLoading={setLoading}
              />
            </Stack>
          </Stack>
          <Stack as="div" pt={20}>
            {genMessg === "Success" && (
              <Text
                fontSize={{ base: "md", md: "lg" }}
                color={"brand.white"}
                textAlign={"center"}
              >
                Please check your email for the{" "}
                <Text as="span" color={"brand.red"} fontWeight={600}>
                  login link
                </Text>
              </Text>
            )}
            {genMessg === "Failed" && (
              <Text
                fontSize={{ base: "md", md: "sm" }}
                color={"brand.white"}
                textAlign={"center"}
              >
                Some error occured! Please try again later
              </Text>
            )}
            <Text
              fontSize={{ base: "md", md: "sm" }}
              color={"brand.white"}
              textAlign={"center"}
            >
              {thirdpartyLoginMessage}
            </Text>
          </Stack>
        </Box>
      </Flex>
    </>
  );
};

const CaraSlider = () => {
  const MotionBox = motion(Box);

  return (
    <>
      <OwlCarousel
        className="owl-theme"
        margin={40}
        nav={false}
        dotClass="inside_dot"
        dotsClass="inside_controls"
        responsive={{
          0: {
            items: 1,
          },
          768: {
            items: 1,
          },
          1100: {
            items: 1,
          },
        }}
        dots
        loop
        autoplay
      >
        <Box
          w={"100%"}
          h={{ base: "60vh", md: "80vh" }}
          backgroundImage={"/assets/CommentBlobs/blob-type-1.svg"}
          backgroundSize={"contain"}
          backgroundRepeat={"no-repeat"}
          backgroundPosition={"center"}
        >
          <Flex
            justifyContent={"center"}
            align={"center"}
            h={{ base: "60vh", md: "80vh" }}
            w={"100%"}
          >
            <Box w={"100%"} ps={"80px"} pe={"60px"}>
              <MotionBox
                initial={{ opacity: 0, y: 10 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: 0.2 }}
                viewport={{ once: true, amount: 0.5 }}
              >
                <Heading
                  fontSize={{ md: "22px", xl: "28px" }}
                  color={"brand.white"}
                  letterSpacing={"1px"}
                  mb={0}
                >
                  Ok, if you&apos;re building a #web3 app on #Solana defs check
                  these guys out @Shyft_to, just had a call that saved me a ton
                  of dev costs, their APIs will help us pull in wallet data
                  easily and fast.
                </Heading>
              </MotionBox>

              <MotionBox
                pt={"80px"}
                initial={{ opacity: 0, y: 10 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: 0.4 }}
              >
                <HStack position={"relative"}>
                  <Box position={"relative"} zIndex={1}>
                    <Image
                      src="/assets/CommentImages/Vibes.png"
                      alt="Vibes Twitter"
                      border={"4px solid"}
                      borderColor={"brand.white"}
                      borderRadius={"full"}
                      w={"50px"}
                    />
                  </Box>
                  <Box>
                    <HStack>
                      <Heading
                        fontSize={{ base: "16px", md: "18px" }}
                        color={"brand.white"}
                        fontWeight={"500"}
                        mb="0"
                      >
                        Vibes
                      </Heading>
                      <Image src="/assets/verified-icon.svg" alt="verfied" />
                    </HStack>
                    <Text
                      as={"div"}
                      fontSize={{ base: "10px", md: "12px" }}
                      color={"brand.white"}
                    >
                      @vibes8760
                    </Text>
                  </Box>
                </HStack>
              </MotionBox>
            </Box>
          </Flex>
        </Box>
        <Box
          w={"100%"}
          h={{ base: "60vh", md: "80vh" }}
          backgroundImage={"/assets/CommentBlobs/blob-type-2.svg"}
          backgroundSize={"contain"}
          backgroundRepeat={"no-repeat"}
          backgroundPosition={"center"}
        >
          <Flex
            justifyContent={"center"}
            align={"center"}
            h={{ base: "60vh", md: "80vh" }}
            w={"100%"}
          >
            <Box w={"100%"} ps={"80px"} pe={"60px"}>
              <MotionBox
                initial={{ opacity: 0, y: 10 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: 0.2 }}
              >
                <Heading
                  fontSize={{ md: "22px", xl: "28px" }}
                  color={"brand.white"}
                  letterSpacing={"1px"}
                  mb={0}
                >
                  I would add @Shyft_to that provides great tools and APIs to
                  simplify @solana Web3 development especially for guys with
                  Web2 past I vouch for them.
                </Heading>
              </MotionBox>
              <MotionBox
                pt={"80px"}
                w="full"
                initial={{ opacity: 0, y: 10 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: 0.4 }}
              >
                <HStack>
                  <Box width={"54px"}>
                    <Image
                      src="/assets/CommentImages/SerjKorj.png"
                      alt="Vibes Twitter"
                      border={"4px solid"}
                      borderColor={"brand.white"}
                      borderRadius={"full"}
                    />
                  </Box>
                  <Box>
                    <Flex>
                      <Box pe={2}>
                        <Heading
                          as="span"
                          fontSize={{ base: "16px", md: "18px" }}
                          color={"brand.white"}
                          fontWeight={"500"}
                          mb={0}
                        >
                          Serj Korj
                        </Heading>
                      </Box>
                      <Box width={"20px"} pt={"2px"}>
                        <Image src="/assets/verified-icon.svg" alt="verfied" />
                      </Box>
                    </Flex>
                    <Text
                      as={"span"}
                      fontSize={{ base: "10px", md: "12px" }}
                      color={"brand.white"}
                    >
                      @SerjKorj
                    </Text>
                  </Box>
                </HStack>
              </MotionBox>
            </Box>
          </Flex>
        </Box>
        <Box
          w={"100%"}
          h={{ base: "60vh", md: "80vh" }}
          backgroundImage={"/assets/CommentBlobs/blob-type-3.svg"}
          backgroundSize={"contain"}
          backgroundRepeat={"no-repeat"}
          backgroundPosition={"center"}
        >
          <Flex
            justifyContent={"center"}
            align={"center"}
            h={{ base: "60vh", md: "80vh" }}
            w={"100%"}
          >
            <Box w={"100%"} ps={"80px"} pe={"60px"}>
              <MotionBox
                initial={{ opacity: 0, y: 10 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: 0.2 }}
              >
                <Heading
                  fontSize={{ md: "22px", xl: "28px" }}
                  color={"brand.white"}
                  letterSpacing={"1px"}
                  mb={0}
                >
                  We&apos;re stoked to announce the Roundie Town Market v2!
                  It&apos;s an on-chain Metaplex Auction House market, with our
                  SPL token $ROUNDIE as the currency. We built this in a week
                  with the incredible APIs written by the @shyft_to team, who
                  saved us weeks of work, w/ top-notch support!
                </Heading>
              </MotionBox>
              <MotionBox
                pt={"80px"}
                w="full"
                initial={{ opacity: 0, y: 10 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: 0.4 }}
              >
                <HStack>
                  <Box width={"54px"}>
                    <Image
                      src="/assets/CommentImages/TheRoundies.png"
                      alt="Vibes Twitter"
                      border={"4px solid"}
                      borderColor={"brand.white"}
                      borderRadius={"full"}
                    />
                  </Box>
                  <Box>
                    <Flex>
                      <Box pe={2}>
                        <Heading
                          as="span"
                          fontSize={{ base: "16px", md: "18px" }}
                          color={"brand.white"}
                          fontWeight={"500"}
                          mb={0}
                        >
                          The R◎undies
                        </Heading>
                      </Box>
                      <Box width={"20px"} pt={"2px"}>
                        <Image src="/assets/verified-icon.svg" alt="verfied" />
                      </Box>
                    </Flex>
                    <Text
                      as={"span"}
                      fontSize={{ base: "10px", md: "12px" }}
                      color={"brand.white"}
                    >
                      @TheRoundies
                    </Text>
                  </Box>
                </HStack>
              </MotionBox>
            </Box>
          </Flex>
        </Box>
        <Box
          w={"100%"}
          h={{ base: "60vh", md: "80vh" }}
          backgroundImage={"/assets/CommentBlobs/blob-type-4.svg"}
          backgroundSize={"contain"}
          backgroundRepeat={"no-repeat"}
          backgroundPosition={"center"}
        >
          <Flex
            justifyContent={"center"}
            align={"center"}
            h={{ base: "60vh", md: "80vh" }}
            w={"100%"}
          >
            <Box w={"100%"} ps={"80px"} pe={"60px"}>
              <MotionBox
                initial={{ opacity: 0, y: 10 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: 0.2 }}
              >
                <Heading
                  fontSize={{ md: "22px", xl: "28px" }}
                  color={"brand.white"}
                  letterSpacing={"1px"}
                  mb={0}
                >
                  Ok, if you&apos;re building a #web3 app on #Solana defs check
                  these guys out @Shyft_to, just had a call that saved me a ton
                  of dev costs, their APIs will help us pull in wallet data
                  easily and fast.
                </Heading>
              </MotionBox>
              <MotionBox
                pt={"80px"}
                w="full"
                initial={{ opacity: 0, y: 10 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: 0.4 }}
              >
                <HStack>
                  <Box width={"54px"}>
                    <Image
                      src="/assets/CommentImages/LeonNew.png"
                      alt="Vibes Twitter"
                      border={"4px solid"}
                      borderColor={"brand.white"}
                      borderRadius={"full"}
                    />
                  </Box>
                  <Box>
                    <Flex>
                      <Box pe={2}>
                        <Heading
                          as="span"
                          fontSize={{ base: "16px", md: "18px" }}
                          color={"brand.white"}
                          fontWeight={"500"}
                          mb={0}
                        >
                          LE◎N
                        </Heading>
                      </Box>
                      <Box width={"20px"} pt={"2px"}>
                        <Image src="/assets/verified-icon.svg" alt="verfied" />
                      </Box>
                    </Flex>
                    <Text
                      as={"span"}
                      fontSize={{ base: "10px", md: "12px" }}
                      color={"brand.white"}
                    >
                      @leongaban
                    </Text>
                  </Box>
                </HStack>
              </MotionBox>
            </Box>
          </Flex>
        </Box>
        <Box
          w={"100%"}
          h={{ base: "60vh", md: "80vh" }}
          backgroundImage={"/assets/CommentBlobs/blob-type-5.svg"}
          backgroundSize={"contain"}
          backgroundRepeat={"no-repeat"}
          backgroundPosition={"center"}
        >
          <Flex
            justifyContent={"center"}
            align={"center"}
            h={{ base: "60vh", md: "80vh" }}
            w={"100%"}
          >
            <Box w={"100%"} ps={"80px"} pe={"60px"}>
              <MotionBox
                initial={{ opacity: 0, y: 10 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: 0.2 }}
              >
                <Heading
                  fontSize={{ md: "22px", xl: "28px" }}
                  color={"brand.white"}
                  letterSpacing={"1px"}
                  mb={0}
                >
                  Gm Creators, Glad to announce our partnership with @Shyft_to.
                  <br />
                  What&apos;s exciting :<br />
                  <b>1.</b> NFTs can be minted on @solana.
                  <br />
                  <b>2.</b> Gasless minting.
                  <br />
                  <b>3.</b> Creator&apos;s own SPL20 Tokens.
                  <br />
                  <b>4.</b> Royalties on secondary sales. LFG.
                  <br />
                </Heading>
              </MotionBox>
              <MotionBox
                pt={"80px"}
                w="full"
                initial={{ opacity: 0, y: 10 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: 0.4 }}
              >
                <HStack>
                  <Box width={"54px"}>
                    <Image
                      src="/assets/CommentImages/mintflick.png"
                      alt="Vibes Twitter"
                      border={"4px solid"}
                      borderColor={"brand.white"}
                      borderRadius={"full"}
                    />
                  </Box>
                  <Box>
                    <Flex>
                      <Box pe={2}>
                        <Heading
                          as="span"
                          fontSize={{ base: "16px", md: "18px" }}
                          color={"brand.white"}
                          fontWeight={"500"}
                          mb={0}
                        >
                          MintFlick
                        </Heading>
                      </Box>
                      <Box width={"20px"} pt={"2px"}>
                        <Image src="/assets/verified-icon.svg" alt="verfied" />
                      </Box>
                    </Flex>
                    <Text
                      as={"span"}
                      fontSize={{ base: "10px", md: "12px" }}
                      color={"brand.white"}
                    >
                      @mintflickApp
                    </Text>
                  </Box>
                </HStack>
              </MotionBox>
            </Box>
          </Flex>
        </Box>
        <Box
          w={"100%"}
          h={{ base: "60vh", md: "80vh" }}
          backgroundImage={"/assets/CommentBlobs/blob-type-6.svg"}
          backgroundSize={"contain"}
          backgroundRepeat={"no-repeat"}
          backgroundPosition={"center"}
        >
          <Flex
            justifyContent={"center"}
            align={"center"}
            h={{ base: "60vh", md: "80vh" }}
            w={"100%"}
          >
            <Box w={"100%"} ps={"80px"} pe={"60px"}>
              <MotionBox
                initial={{ opacity: 0, y: 10 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: 0.2 }}
              >
                <Heading
                  fontSize={{ md: "22px", xl: "28px" }}
                  color={"brand.white"}
                  letterSpacing={"1px"}
                  mb={0}
                >
                  We’re working with Shyft for faster metadata loads, NFT image
                  caching, and all that is needed for a perfect NFT trading
                  experience ⚡️
                  <br />
                  They’re a strong technical team pushing the standards of
                  dev-tooling on Solana higher. 🙌🏻
                </Heading>
              </MotionBox>
              <MotionBox
                pt={"80px"}
                w="full"
                initial={{ opacity: 0, y: 10 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: 0.4 }}
              >
                <HStack>
                  <Box width={"54px"}>
                    <Image
                      src="/assets/CommentImages/Solvent.png"
                      alt="Vibes Twitter"
                      border={"4px solid"}
                      borderColor={"brand.white"}
                      borderRadius={"full"}
                    />
                  </Box>
                  <Box>
                    <Flex>
                      <Box pe={2}>
                        <Heading
                          as="span"
                          fontSize={{ base: "16px", md: "18px" }}
                          color={"brand.white"}
                          fontWeight={"500"}
                          mb={0}
                        >
                          Solvent
                        </Heading>
                      </Box>
                      <Box width={"20px"} pt={"2px"}>
                        <Image src="/assets/verified-icon.svg" alt="verfied" />
                      </Box>
                    </Flex>
                    <Text
                      as={"span"}
                      fontSize={{ base: "10px", md: "12px" }}
                      color={"brand.white"}
                    >
                      @solventprotocol
                    </Text>
                  </Box>
                </HStack>
              </MotionBox>
            </Box>
          </Flex>
        </Box>
      </OwlCarousel>
    </>
  );
};

const GetApiKey = () => {
  return (
    <>
      <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ""}
      >
        <Stack as="div" bg={"#111"}>
          <Container maxW={"7xl"}>
            <Stack
              as="div"
              direction={{ base: "column", md: "row" }}
              minHeight={"90vh"}
            >
              <Stack
                textAlign={"start"}
                width={{ base: "100%", md: "100%", lg: "50%", "2xl": "52%" }}
              >
                <APIKeyForm />
              </Stack>
              <Stack
                width={{ base: "100%", md: "100%", lg: "50%", "2xl": "48%" }}
              >
                <Box overflowX={"hidden"}>
                  <CaraSlider />
                </Box>
              </Stack>
            </Stack>
          </Container>
        </Stack>
      </GoogleOAuthProvider>
    </>
  );
};
export default GetApiKey;
