import ParentComponent from "./ParentComponent";
import { ReactSession } from "react-client-session";
import ReactGA from "react-ga4";
import TagManager from 'react-gtm-module';

function App() {
  ReactSession.setStoreType("localStorage");

  const TRACKING_ID = "G-SYNVG7YSHX";
  const tagManagerArgs = {
    gtmId: 'G-SYNVG7YSHX'
  }
  
  TagManager.initialize(tagManagerArgs);
  //console.log("GA ID: ",TRACKING_ID);
  ReactGA.initialize(TRACKING_ID);
  
  return (
    <div className="App">
      <ParentComponent />
    </div>
  );
}

export default App;
