import { useState } from "react";
import { ReactSession } from "react-client-session";
import axios from "axios";

import MainStyles from "../Dashboard/DashboardStyles/showCardDetailsStyles.module.css";
import LoadingStyles from "../../resources/css/loadingAnime.module.css";
import Cross from "../../resources/images/circle-close.svg";



const Cardprofile = (props) => {
    const [messg,setMessg] = useState("");
    const [loading,setLoading] = useState(false);
    
    const [name,setName] = useState("");
    const [errname,setErrName] = useState("");
    // const [emails,setEmails] = useState("");
    const [teamSize,setTeamSize] = useState("");
    const [projName,setProjName] = useState("");
    const [errproj,setErrProj] = useState("");
    const [buildingWhat,setBuildingWhat] = useState("");

    const [telegram,setTelegram] = useState("");
    const [linkedin,setLinkedIn] = useState("");
    const [linkedInErr,setLinkedInErr] = useState("");
    
    function isValidUrl(string) {
        try {
            new URL(string);
            return true;
        } catch (err) {
            return false;
        }
    }
    function sanitizeString(str){
        str = str.replace(/[^a-z0-9áéíóúñü \.,_-]/gim,"");
        return str.trim();
    }

    const saveData = () => 
    {
        const token = ReactSession.get("xTkn") ?? "";
        let dataToSend = {};
        let social_links = {};
        let socials_shared = false;
        let err_occ = false;
        let make_api_call = false;
        setMessg("");
        setErrName("");
        setErrProj("");
        if((name.length>2 && name.length<20))
        {
            dataToSend = {
                ...dataToSend,
                name: sanitizeString(name)
            }
            make_api_call = true;
        }
        if(projName.length>2 && projName.length<20)
        {
            dataToSend = {
                ...dataToSend,
                project_name: sanitizeString(projName)
            }
            make_api_call = true;
        }
        if(teamSize !== "")
        {
            dataToSend = {
                ...dataToSend,
                team_size: teamSize
            }
            make_api_call = true;
        }
        if(buildingWhat !== "")
        {
            dataToSend = {
                ...dataToSend,
                project_info: buildingWhat
            }
            make_api_call = true;
        }
        if( telegram !== "")
        {
            social_links = {
                ...social_links,
                telegram: telegram
            }
            socials_shared = true;
        }
        if (linkedin !== "") {
            if(isValidUrl(linkedin))
            {
                social_links = {
                    ...social_links,
                    linkedin: linkedin
                }
                socials_shared = true;
            }
            else
            {
                setLinkedInErr("Invalid URL")
            }
            
        }
        if(socials_shared === true)
        {
            dataToSend = {
                ...dataToSend,
                social_links: social_links
            }
            make_api_call = true;
        }
        
        // if(emails === "")
        // {
        //     err_occ = true;
        //     setMessg("Please Enter a Valid Email Address");
        // }
        // if(!validateEmail(emails))
        // {
        //     err_occ = true;
        //     setMessg("Please Enter a Valid Email Address");
        // }

        //ready for the API call
        if(make_api_call === true)
        {
            // dataToSend = {
            //     ...dataToSend,
            //     // email:  emails
            // }
            //console.log("Calling Function");
            const endPoint = process.env.REACT_APP_AUTH_EP;
            let rqUrl = `${endPoint}auth/profile`;
            //setLoading(true);
            document.getElementById("saveUser").disabled = true;
            document.getElementById("saveUser").innerHTML = `<div class=${LoadingStyles.lds_ripple}><div></div><div></div></div>`;
            axios({
                // Endpoint to get NFTs
                url: rqUrl,
                method: "PUT",
                headers: {
                "Content-Type": "application/json",
                "ngrok-skip-browser-warning": "69420",
                'Authorization': `Bearer ${token}`,
                },
                data: dataToSend
            })
            // Handle the response from backend here
            .then((res) => {
            // setLoading(false);
            //console.log(res.data);
            document.getElementById("saveUser").innerHTML = `Submit`;
            document.getElementById("saveUser").disabled = false;
            if(res.data.success === true)
            {
                //navigate('dashboard/overview');
                // ReactSession.set("UserAP",res.data.result.api_key);
                // setFormSuccess(true);
                
                props.setShowDetailsCard(false);
            }
            else
            {
                // setFormSuccess(false);
                
                setMessg("Some error occured"); 
            }
            })
            // Catch errors if any
            .catch((err) => {
                console.warn(err);
                setMessg("Failed! Could not make the API call");
                document.getElementById("saveUser").innerHTML = `Submit`;
                document.getElementById("saveUser").disabled = false;
            }); 
        }
        else
        {
            setMessg("Cannot submit without any valid details"); 
        }
        
    }
    const closer = () => {
        const dateNow = new Date().toISOString();
        ReactSession.set("detCheckTime",dateNow);
        props.setShowDetailsCard(false);
    }
    return (
        <div>
            <div className="modal" style={{ display: "block", backdropFilter: "blur(4px)" }}>
                <div className="modal-dialog modal-dialog-scrollable" >
                    <div className="modal-content" style={{ backgroundColor: "#111", borderRadius: "16px" }}>
                        <div className="modal-body">
                            <div className={MainStyles.dark_modal_style}>
                                <div className={MainStyles.cross_container}>
                                    <button onClick={closer}>
                                        <img src={Cross} alt="close" />
                                    </button>
                                </div>
                                <div className={`pt-3 ${MainStyles.dashboard_main_title}`}>
                                    <div className="text-center">
                                        <h3>Enter Your Details</h3>

                                    </div>
                                    <div className={MainStyles.the_form}>
                                        <div className={`${MainStyles.dark_form_field} ${MainStyles.in_user_form_popup}`}>
                                            <label>Name</label>
                                            <div className={MainStyles.dark_form_group}>
                                                <div>
                                                    <input type="text" value={name} onChange={(e) => {
                                                        if (e.target.value.length < 3)
                                                            setErrName("Name must be empty or greater than 3 characters");
                                                        else
                                                            setErrName("");
                                                        setName(e.target.value)
                                                    }
                                                    }
                                                        className="form-control" placeholder="Enter name" maxLength={30} />
                                                </div>
                                            </div>
                                            <small className={`text-danger ${MainStyles.p_para_2}`} style={{ fontFamily: "Jost" }}>{errname}</small>
                                        </div>

                                        <div className={`${MainStyles.dark_form_field}`}>
                                            <label>Team Size</label>
                                            <div className={MainStyles.dark_form_group}>
                                                <div className="d-flex">
                                                    <select className="form-control" onClick={(e) => setTeamSize(e.target.value)}>
                                                        <option className="bg-dark" value="">Select</option>
                                                        <option className="bg-dark" value="1 - 10">1 - 10</option>
                                                        <option className="bg-dark" value="11 - 50">11 - 50</option>
                                                        <option className="bg-dark" value="Above 50">Above 50</option>
                                                    </select>
                                                    <div className={MainStyles.down_icon}>
                                                        <i className="fa fa-chevron-down" style={{ fontSize: "20px", color: "#fff" }}></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={`${MainStyles.dark_form_field} ${MainStyles.in_user_form}`}>
                                            <label>Organization/Projects</label>
                                            <div className={MainStyles.dark_form_group}>
                                                <div>
                                                    <input type="text" className="form-control" value={projName}
                                                        onChange={(e) => {
                                                            if (e.target.value.length < 3)
                                                                setErrProj("Org/Project must be empty or greater than 3 characters");
                                                            else
                                                                setErrProj("");
                                                            setProjName(e.target.value)
                                                        }
                                                        }
                                                        placeholder="Enter company's name/project's name" maxLength={40} />
                                                </div>
                                            </div>
                                            <small className={`text-danger ${MainStyles.p_para_2}`} style={{ fontFamily: "Jost" }}>{errproj}</small>
                                        </div>
                                        <div className={MainStyles.dark_form_field}>
                                            <label>What are you building with SHYFT?</label>
                                            <div className={MainStyles.dark_form_group}>
                                                <div className="d-flex">
                                                    <select className="form-control" onChange={(e) => setBuildingWhat(e.target.value)}>
                                                        <option className="bg-dark">ART</option>
                                                        <option className="bg-dark">Marketplace</option>
                                                        <option className="bg-dark">Candy Machine</option>
                                                        <option className="bg-dark">NFT dApps</option>
                                                        <option className="bg-dark">Web3 Game</option>
                                                        <option className="bg-dark">Analytics Dashboard</option>
                                                        <option className="bg-dark">Profile Pages</option>
                                                        <option className="bg-dark">Utility Tokens</option>
                                                        <option className="bg-dark">Something else</option>
                                                    </select>
                                                    <div className={MainStyles.down_icon}>
                                                        <i className="fa fa-chevron-down" style={{ fontSize: "20px", color: "#fff" }}></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={MainStyles.dark_form_field}>
                                            <div className="row">
                                                <div className="col-6">
                                                    <label>LinkedIn</label>
                                                    <div className={MainStyles.dark_form_group}>
                                                        <div>
                                                            <input type="text" className="form-control" value={linkedin}
                                                                onChange={(e) => {
                                                                    // if (e.target.value.length<3)
                                                                    //     setErrProj("Org/Project must be empty or greater than 3 characters");
                                                                    // else 
                                                                    //     setErrProj("");
                                                                    setLinkedInErr("")
                                                                    setLinkedIn(e.target.value)
                                                                }
                                                                }
                                                                placeholder="LinkedIn URL" />
                                                        </div>
                                                    </div>
                                                    <small className={`text-danger ${MainStyles.p_para_2}`} style={{ fontFamily: "Jost" }}>{linkedInErr}</small>
                                                </div>
                                                <div className="col-6">
                                                    <label>Telegram</label>
                                                    <div className={MainStyles.dark_form_group}>
                                                        <div>
                                                            <input type="text" className="form-control" value={telegram}
                                                                onChange={(e) => {
                                                                    // if (e.target.value.length<3)
                                                                    //     setErrProj("Org/Project must be empty or greater than 3 characters");
                                                                    // else 
                                                                    //     setErrProj("");
                                                                    setTelegram(e.target.value)
                                                                }
                                                                }
                                                                placeholder="Username" />
                                                        </div>
                                                    </div>
                                                    <small className={`text-danger ${MainStyles.p_para_2}`} style={{ fontFamily: "Jost" }}>{}</small>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="d-flex pt-4">
                                            <div className="pe-4 flex-fill">
                                                <button id="saveUser" className={`w-100 ${MainStyles.btn_solid_grad}`} onClick={saveData}>Submit</button>
                                            </div>
                                            <div className="flex-fill">
                                                <button
                                                    className={`w-100 text-light ${MainStyles.btn_outline_gradient_button}`}
                                                    onClick={closer}
                                                >
                                                    <div className={MainStyles.inner_white_area}>
                                                        Skip
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                        <div className={`text-center text-danger pt-1 ${MainStyles.p_para_2}`}>
                                            {messg}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Cardprofile;