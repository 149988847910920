import HomepagePricing from "../common/HomePagePricing";
import MainStyles from "../../resources/css/css.module.css";

const PricingDetails = () => {
    return ( 
        <div className={MainStyles.pricing_details_page}>
            <HomepagePricing />
        </div>
     );
}
 
export default PricingDetails;