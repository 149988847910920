import { useState,useEffect } from "react";
import { ReactSession } from "react-client-session";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import FourCss from '../404/four04.module.css';
import SpaceError from '../../resources/images/error-page.gif';
import Loading from "../common/Loading";
import SyncLoader from "react-spinners/SyncLoader";


const Validator = () => {
    const navigate = useNavigate();
    const {token} = useParams();
    
    const [ paraToken,setParaToken ] = useState('');
    const [ loading,setLoading ] = useState(true);
    const [ msg,setMsg ] = useState('');
    
    useEffect(() => {
      if(token)
      {
        setParaToken(token);
      }
      else
      {
        navigate('/error');
      }
    }, []);

    useEffect(() => {
        if(paraToken !== '')
        {
            const endPoint = process.env.REACT_APP_AUTH_EP;
            let rqUrl = `${endPoint}auth/authorize`;
            
            axios({
                // Endpoint to get NFTs
                url: rqUrl,
                method: "POST",
                headers: {
                    'Authorization': `Bearer ${paraToken}`,
                    "ngrok-skip-browser-warning": "69420"
                }
            })
            // Handle the response from backend here
            .then((res) => {
                setLoading(false);
                //console.log(res.data);
                if(res.data.success === true)
                {
                    setMsg('Please Wait');
                    // header set : res.data.result.access_token
                    ReactSession.set("xTkn", res.data.result.access_token);
                    navigate('/dashboard/overview')
                    // if(res.data.result.is_new_user === true)
                    //     navigate('/user-details')
                    // else
                    //     navigate('/dashboard/overview');
                }
                else
                {
                    setMsg('Error! Unauthorized Token');
                    //remove Token
                }
            })
            // Catch errors if any
            .catch((err) => {
                console.warn(err);
                setMsg('Error! Some Error Occured');
                setLoading(false);
            });
        }
    }, [paraToken])
    
    
    return ( 
        <div className={FourCss.main_container}>
            {loading && <Loading />}
            <div className="container-lg">
                {(msg === "Please Wait") && <div className="text-center pt-5">
                    <SyncLoader color="#f4f4f4" size={20} />
                </div>}
                {(msg === "Error! Some Error Occured") && <div className="text-center pt-5">
                    <img src={SpaceError} alt="Not Found" className="img-fluid mx-auto" />
                </div>}
                <div className="text-center">
                    <h1 className={FourCss.text}>{msg}</h1>
                    <a href="https://shyft.to/" className={FourCss.btn_solid_grad}>Genesis Block</a>
                </div>
            </div>
        </div>
     );
}
 
export default Validator;